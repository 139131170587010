import { useMountFetch } from 'hooks';
import ListOffers from 'components/Offers/ListOffers';

const LenderView = () => {
  return (
    <>
      <div>
        <ListOffers />
      </div>
    </>
  );
};

export default LenderView;
