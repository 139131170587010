import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Row, Col, Skeleton, Tooltip, Button, Tag, Table, Checkbox } from 'antd';
import Widget from 'components/Widget/index';
import IntlMessages from 'util/IntlMessages';
import {
  useMountFetch,
  useFetch,
  useLatestDocumentVersion,
  useLocale,
  useIsReferringClient,
  useIsLender,
  useIsBorrower,
} from 'hooks';
import { AutoModal, RemoveModal, fixDate } from 'components/ui';
import { Entity } from 'core/interfaces';
import ProposalSummary, { ReferringClientExtraInfo } from './ProposalSummary';
import { EditOfferButton, OfferPublishSwitch } from 'components/Offers/EditOffer';
import { smallGutter } from 'config';
import { splitNumber } from 'core/utils/general';
import api from 'utils/api';

const { Column } = Table;

const DEFAULT_CURRENCY = 'SEK';

function getStateUrl(id) {
  if (!id) return '';
  return `/versions/${id}/state`;
}

function getProjectUrl(id, isBorrower) {
  if (!isBorrower || !id) return '';
  return `/projects/${id}?fields=name`;
}

export const DocumentSummaryButton = ({
  doc,
  template,
  type = 'primary',
  titleId = 'app.general.Summary',
  block = false,
  contractState,
  reportSeen,
  isLender,
  isBorrower,
  hasRequestedAccess,
}) => {
  const closeModalFnRef = useRef();

  const getCloseFunction = (fn) => (closeModalFnRef.current = fn);

  if (!template) {
    return (
      <Button type={type} className="mb-1" block={block} disabled>
        <IntlMessages id={titleId} />
      </Button>
    );
  }

  return (
    <AutoModal
      trigger={
        <Button type={type} className="mb-1" block={block} onClick={!isBorrower ? reportSeen : undefined}>
          <IntlMessages id={titleId} />
        </Button>
      }
      getCloseFunction={getCloseFunction}
      title={<IntlMessages id="general.financeRequest" cap />}
      className="modal-md"
    >
      <ProposalSummary
        doc={doc}
        template={template}
        contractState={contractState}
        isLender={isLender}
        isBorrower={isBorrower}
        closeModalFnRef={closeModalFnRef}
        hasRequestedAccess={hasRequestedAccess}
      />
    </AutoModal>
  );
};

function getUserDataUrl(documentAccessRequests) {
  if (!Array.isArray(documentAccessRequests)) return '';
  const uids = documentAccessRequests.map((ar) => ar.userId);
  const uriUids = encodeURI(JSON.stringify(uids));
  return `/users/entitiesAndClients?ids=${uriUids}`;
}

const InterestedLenders = ({ doc, documentAccessRequests }) => {
  const [data] = useMountFetch(getUserDataUrl(documentAccessRequests));

  if (!documentAccessRequests || documentAccessRequests.length === 0 || (data && data.length === 0)) {
    return <div className="p-3 border">No interested lenders</div>;
  }

  if (!data) {
    return 'Loading  . . .';
  }

  return (
    <Table rowKey="id" className="table-responsive documents-table" dataSource={data}>
      <Column
        title={<IntlMessages id="general.company" cap />}
        dataIndex="name"
        key="name"
        render={(text, record) => {
          return record.Client.name;
        }}
      />
      <Column
        title={<IntlMessages id="general.person" cap />}
        dataIndex="name"
        key="name"
        render={(text, record) => {
          return `${record.Entity.firstName} ${record.Entity.lastName}`;
        }}
      />
      <Column
        title={<IntlMessages id="app.general.Email" cap />}
        dataIndex="person"
        key="person"
        render={(text, record) => {
          return Entity.firstEmail(record.Entity);
        }}
      />
    </Table>
  );
};

const ListInterests = ({ doc, documentAccessRequests }) => {
  return (
    <AutoModal
      trigger={<span className="link">Show all</span>}
      title={<IntlMessages id="general.interestedLenders" cap />}
      className="modal-full"
    >
      <InterestedLenders doc={doc} documentAccessRequests={documentAccessRequests} />
    </AutoModal>
  );
};

function _getArrayFromStorage(store_key) {
  const stringedFavourites = localStorage.getItem(store_key);
  if (!stringedFavourites) return [];
  try {
    const f = JSON.parse(stringedFavourites);
    return f;
  } catch (err) {
    console.log('No value');
  }
  return [];
}
function _setArrayToStorage(store_key, arr) {
  const str = JSON.stringify(arr);
  localStorage.setItem(store_key, str);
}

function _storeExists(store_key, id) {
  const store = _getArrayFromStorage(store_key);
  return store.includes(id);
}

function getStoreIsFavourite(id) {
  return _storeExists('bb5_favourites', id);
}
function getStoreIsSeen(id) {
  return _storeExists('bb5_seen_documents', id);
}

function _addToStore(store_key, id) {
  const beforeStore = _getArrayFromStorage(store_key);
  if (beforeStore.includes(id)) return;
  const newStore = [...beforeStore, id];
  _setArrayToStorage(store_key, newStore);
}
function _removeFromStore(store_key, id) {
  const beforeStore = _getArrayFromStorage(store_key);
  if (!beforeStore.includes(id)) return;
  const newStore = beforeStore.filter((x) => x !== id);
  _setArrayToStorage(store_key, newStore);
}

function setStoreHasSeen(id, value) {
  if (value) _addToStore('bb5_seen_documents', id);
  else _removeFromStore('bb5_seen_documents', id);
}
function setStoreIsFavourite(id, value) {
  if (value) _addToStore('bb5_favourites', id);
  else _removeFromStore('bb5_favourites', id);
}

function calcTotalAmount(input) {
  if (!input) return null;
  const amount1 = input.debt['debt/construction_facility'] || 0;
  const amount2 = input.debt['debt/land_facility'] || 0;
  const amount3 = input.debt['debt/term_loan_facility'] || 0;
  const amount4 = input.debt['debt/junior_facility'] || 0;
  const amount5 = input.debt['debt/overdraft_facility'] || 0;
  return amount1 + amount2 + amount3 + amount4 + amount5;
}

function getCurrency(input) {
  if (!input) return '';
  return input.general?.['general/currency'] || DEFAULT_CURRENCY;
}

const DocumentWidget = ({ doc, removeDocument, template, pendingAccessTasks }) => {
  const isLender = useIsLender();
  const isReferringClient = useIsReferringClient();
  const isBorrower = useIsBorrower();

  const summaryBtnType = isBorrower ? 'secondary' : 'primary';
  const summaryBtnTitleId = isLender ? 'desc.Open' : undefined;

  const locale = useLocale();

  const contract = useLatestDocumentVersion(doc.id);
  const [contractState] = useFetch(getStateUrl(contract?.id));
  const [project] = useFetch(getProjectUrl(contract?.projectId, isBorrower));

  const [isFavourite, setIsFavourite] = useState(isBorrower ? null : getStoreIsFavourite(doc.id));
  const [isSeen, setIsSeen] = useState(isBorrower ? null : getStoreIsSeen(doc.id));

  const reportSeen = useCallback(() => {
    setIsSeen(true);
    setStoreHasSeen(doc.id, true);
  }, [doc.id]);

  const toggleFavourite = () => {
    setIsFavourite(!isFavourite);
    setStoreIsFavourite(doc.id, !isFavourite);
  };

  const removeProject = async () => {
    const response = await api.delete('/projects/' + doc.projectId);
    if (response?.data?.status === 'OK') {
      removeDocument(doc.id);
    }
  };

  const currency = getCurrency(contractState?.input);
  const totalAmount = calcTotalAmount(contractState?.input);
  const totalAmountContent =
    totalAmount === null ? <Skeleton.Input active /> : splitNumber(totalAmount, locale);

  const documentAccessRequests = useMemo(() => {
    return pendingAccessTasks && pendingAccessTasks.filter((t) => t.resourceId === doc.projectId);
  }, [doc.projectId, pendingAccessTasks]);
  const hasRequestedAccess = documentAccessRequests?.length;

  return (
    <Col sm={24} md={12} lg={8}>
      <Widget styleName="mb-4 bg-pale">
        <div className="text-center">
          {!isBorrower && (
            <div className="favourite-holder">
              <Tooltip title={<IntlMessages id={`general.favouriteDesc${isFavourite ? 'Yes' : 'No'}`} />}>
                <span className="clickable" onClick={toggleFavourite}>
                  <i className={`mdi mdi-star${isFavourite ? ' text-warning' : '-outline'}`} />
                </span>
              </Tooltip>
            </div>
          )}
          {isBorrower && (
            <div className="favourite-holder">
              <RemoveModal
                onConfirm={removeProject}
                confirmText={
                  <>
                    <IntlMessages id="app.general.confirmRemoval" /> <IntlMessages id={'general.project'} />?
                  </>
                }
              >
                <i className="mdi mdi-close clickable" />
              </RemoveModal>
            </div>
          )}
          {isBorrower && (
            <h5>
              {!project?.name ? (
                <Skeleton.Input active style={{ width: '150px', minWidth: 'unset' }} />
              ) : (
                project.name
              )}
            </h5>
          )}
          <h4 className="mb-3 mb-sm-4">
            {totalAmount === null ? (
              <Skeleton.Input active style={{ width: '50px', minWidth: 'unset' }} />
            ) : (
              <>
                {currency} {totalAmountContent}
              </>
            )}
            <IntlMessages id="general.financeRequest" className="ml-2" />
          </h4>
          <h5 className="mb-3 mb-sm-4 fs-xs">{doc && fixDate(doc.createdAt)}</h5>

          <ul className="list-inline mb-3 mb-lg-3 tags d-flex justify-content-center align-items-center">
            {hasRequestedAccess && isLender ? (
              <>
                {isSeen && (
                  <li>
                    <Tooltip title={<IntlMessages id="general.hasSeenOfferDesc" />}>
                      <Tag>
                        <IntlMessages id="general.hasSeenOffer" />
                      </Tag>
                    </Tooltip>
                  </li>
                )}
                <li>
                  <Tooltip title={<IntlMessages id="general.interestSubmittedDesc" />}>
                    <Tag color="black">
                      <IntlMessages id="general.interestSubmitted" />
                    </Tag>
                  </Tooltip>
                </li>
              </>
            ) : null}
          </ul>
          {isReferringClient && (
            <div className="mb-3 mb-lg-3">
              {documentAccessRequests?.length ? (
                <span>
                  {documentAccessRequests?.length} <IntlMessages id="general.interestsSubmitted" />{' '}
                  <ListInterests doc={doc} documentAccessRequests={documentAccessRequests} />
                </span>
              ) : (
                <span className="text-muted">
                  <IntlMessages id="general.noInterests" />
                </span>
              )}
            </div>
          )}
          {isReferringClient && (
            <div>
              <ReferringClientExtraInfo doc={doc} />
            </div>
          )}
          {isBorrower && (
            <div className="mb-3">
              <OfferPublishSwitch doc={doc} />
            </div>
          )}
          <Button.Group className="mt-3">
            {isBorrower && <EditOfferButton doc={doc} />}
            <DocumentSummaryButton
              doc={doc}
              template={template}
              type={summaryBtnType}
              block
              titleId={summaryBtnTitleId}
              isLender={isLender}
              isBorrower={isBorrower}
              contractState={contractState}
              reportSeen={reportSeen}
              hasRequestedAccess={hasRequestedAccess && isLender}
            />
          </Button.Group>
        </div>
      </Widget>
    </Col>
  );
};

export default DocumentWidget;
