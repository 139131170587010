import { Col, Tag } from 'antd';
import { ucfirst, splitNumber, getByPath } from 'core/utils/general';
import IntlMessages from 'util/IntlMessages';

function getInputField(template, id) {
  return template?.data?.ui?.inputs?.[id] || null;
}

const getValue = (data, key) => getByPath(data, key);
const isNum = (template, id) => {
  const input = getInputField(template, id);
  if (input) return false;
  return input.type === 'numeric' || input.type === 'numeric-steps';
};

const getPrefix = (data, input) => {
  if (!input.prefix) return '';
  if (typeof input.prefix === 'string') return input.prefix;
  if (typeof input.prefix === 'object' && input.prefix.path) {
    return getByPath(data, input.prefix.path) || '';
  }
  return '';
};
const getSuffix = (data, input) => {
  if (!input.suffix) return '';
  if (typeof input.suffix === 'string') return input.suffix;
  if (typeof input.suffix === 'object' && input.suffix.path) {
    return getByPath(data, input.suffix.path) || '';
  }
  return '';
};

const format = (data, input, value, language) => {
  let text = value;
  if (!input) {
    return 'Error';
  }
  if (input.format === 'amount') text = splitNumber(value, language);
  else if (typeof value === 'string') text = ucfirst(value);
  if (input.suffix) return text + ' ' + getSuffix(data, input);
  return text;
};

function getTitle(template, id, language) {
  const input = getInputField(template, id);
  if (!input) {
    console.log('No input for ', id);
    return <em>Unknown</em>;
  }
  const title = input.label?.[language];
  if (!title) {
    return <em>Unknown</em>;
  }

  return title;
}
function getValueDesc(template, id, value, language) {
  const input = getInputField(template, id);
  if (!input) return <em>Unknown</em>;
  if (Array.isArray(value) && input.content) {
    return value.map((v) => {
      const contentItem = input.content.find((c) => c.id === v);
      if (!contentItem) return 'XXX';
      return contentItem.label[language];
    });
  } else if (input.type === 'select' || input.type === 'radio') {
    const contentItem = input.content.find((c) => c.id === value);
    if (!contentItem) return 'XXX';
    return contentItem.label[language];
  }

  return value;
}

function getSizeCols(size) {
  switch (size) {
    case 'sm':
      return {
        xs: 24,
        sm: 12,
        md: 8,
      };
    case 'md':
      return {
        xs: 24,
        sm: 24,
        md: 12,
      };
    case 'lg':
      return {
        xs: 24,
      };
    default:
      return {};
  }
}

function getItemData(card, id, data, template, language) {
  const path = `${card}.${id}`;
  const value = getValue(data, path);
  const inputField = getInputField(template, id);
  const title = getTitle(template, id, language);
  const valueDesc = getValueDesc(template, id, value, language);

  return { inputField, title, valueDesc, value };
}

function getFormattedContent(data, inputField, valueDesc, language, color, forceTag) {
  const type = typeof valueDesc;
  if (['string', 'number'].includes(type)) {
    const value = format(data, inputField, valueDesc, language);
    if (forceTag) return <Tag color={color}>{value}</Tag>;
    return value;
  } else if (Array.isArray(valueDesc)) {
    return valueDesc.map((id) => (
      <Tag color={color} key={id}>
        {id}
      </Tag>
    ));
  } else if (type === 'boolean') {
    const icon = valueDesc === false ? 'mdi-close' : 'mdi-check';
    const className = valueDesc === false ? 'text-danger' : 'text-success';
    return <i className={`fs-xl mdi ${icon} ${className}`} />;
  } else {
    return null;
  }
}

export const SummaryItem = ({
  card,
  id,
  subId,
  data,
  template,
  language = 'en',
  size = 'md',
  color = undefined,
  forceTag = false,
  ignoreLabel = false,
  hideIfEmpty = false,
}) => {
  const { inputField, title, valueDesc, value } = getItemData(card, id, data, template, language);

  let content = getFormattedContent(data, inputField, valueDesc, language, color, forceTag);
  if (subId && value) {
    const { inputField: subInputField, valueDesc: subValueDesc } = getItemData(
      card,
      subId,
      data,
      template,
      language
    );
    const tmpContent = getFormattedContent(data, subInputField, subValueDesc, language, color, forceTag);
    content = (
      <>
        {content}
        <span className="ml-2">{tmpContent}</span>
      </>
    );
  }

  if (hideIfEmpty) {
    if (content === null && !inputField) return null;
  }

  return (
    <Col {...getSizeCols(size)}>
      <PrintItem title={title} text={content} ignoreLabel={ignoreLabel} />
    </Col>
  );
};

const HiddenItem = ({ text = 'Hidden' }) => {
  return <div className="hidden-item">{text}</div>;
};

const PrintItem = ({ title, children, text, hidden, textSuffix, ignoreLabel }) => {
  let textContent;
  if (text === null || !text) {
    textContent = (
      <em className="no-info">
        <IntlMessages id="general.noInfoGiven" />
      </em>
    );
  } else if (typeof text === 'object') {
    textContent = text;
  } else if (children) {
    textContent = children;
  } else if (text) {
    textContent = (
      <span>
        {text}
        {textSuffix ? <span className="ml-1">{textSuffix}</span> : null}
      </span>
    );
  } else {
    textContent = (
      <em className="no-info">
        <IntlMessages id="general.noInfoGiven" />
      </em>
    );
  }

  let content;
  if (hidden) content = <HiddenItem />;
  else if (textContent) content = textContent;
  else content = 'Naaah.';

  return (
    <div className="offer-item-container">
      {ignoreLabel ? null : <div>{title}</div>}
      <div className="d-flex align-items-flex-start">{content}</div>
    </div>
  );
};

export const Header = ({ type = 'small', text }) => {
  if (type === 'small') return <h5>{text}</h5>;
  return <h4 className="border-bottom">{text}</h4>;
};

/* Specific renderers */

export const PropertyTotalFloorArea = ({ data, language = 'en' }) => {
  const com = getValue(data, 'property.property/commercial_floor_area');
  const res = getValue(data, 'property.property/residential_floor_area');
  const total = com + res;
  const totalStr = splitNumber(total, language);
  return (
    <Col md={8} sm={12} xs={24}>
      <PrintItem
        title={<IntlMessages id="general.property.totalFloorArea" />}
        text={totalStr}
        textSuffix="m²"
      />
    </Col>
  );
};

export const EnvironmentalCertificate = ({ data, template, language = 'en' }) => {
  const value = getValue(data, 'property.property/environment_cert');
  const info = getValue(data, 'property.property/environment_cert_info');
  const title = getTitle(template, 'property/environment_cert', language);
  let icon = null;

  if (value === 'yes') {
    icon = <i className="mdi mdi-check text-success mr-2" />;
  }
  const content = (
    <>
      {icon}
      <IntlMessages id={`bb5.summary.envCert.${value}`} />
      {info ? (
        <span className="ml-3">
          {getValueDesc(template, 'property/environment_cert_info', info, language)}
        </span>
      ) : (
        <em className="no-info ml-3">
          <IntlMessages id="general.noFurtherInfoGiven" />
        </em>
      )}
    </>
  );

  return (
    <Col md={24} sm={24} xs={24}>
      <PrintItem title={title} text={content} />
    </Col>
  );
};

export const DebtInterest = ({ data, language = 'en' }) => {
  const indicateInterest = getValue(data, 'debt.debt/indicate_interest');
  const interestRate = getValue(data, 'debt.debt/interest');

  let content;

  if (!indicateInterest) {
    content = <IntlMessages id="general.notIndicated" cap />;
  } else {
    content = `${interestRate}`;
  }

  return (
    <Col md={8} sm={12} xs={24}>
      <PrintItem title={<IntlMessages id="general.debt.interest" cap />} text={content} textSuffix="%" />
    </Col>
  );
};
