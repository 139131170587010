import React from 'react';
import TweenOne from 'rc-tween-one';
import SvgDrawPlugin from 'rc-tween-one/lib/plugin/SvgDrawPlugin';

TweenOne.plugins.push(SvgDrawPlugin);

export default function BannerImage() {
  return (
    <svg
      width="510px"
      height="460px"
      viewBox="0 0 510 460"
      style={{
        maxWidth: 'calc(100vw - 100px)',
      }}
    >
      <defs>
        <linearGradient
          x1="22.5255245%"
          y1="93.2958148%"
          x2="80.4524476%"
          y2="10.3638558%"
          id="linearGradient-1"
        >
          <stop stopColor="#FFBF00" offset="0%" />
          <stop stopColor="#FFE859" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="62.0164384%"
          y1="98.1821918%"
          x2="37.8746575%"
          y2="1.35479452%"
          id="linearGradient-2"
        >
          <stop stopColor="#C4D3F3" offset="0%" />
          <stop stopColor="#FFFFFF" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-0.137647059%"
          y1="49.64875%"
          x2="100.094118%"
          y2="49.64875%"
          id="linearGradient-3"
        >
          <stop stopColor="#9999C8" offset="0%" />
          <stop stopColor="#BABAD5" offset="41.51%" />
          <stop stopColor="#E6E6E6" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-0.000757849152%"
          y1="49.958557%"
          x2="100.002743%"
          y2="49.958557%"
          id="linearGradient-4"
        >
          <stop stopColor="#999BBF" offset="0%" />
          <stop stopColor="#BABCD0" offset="41.51%" />
          <stop stopColor="#E6E6E6" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-473.613725%"
          y1="46.3643579%"
          x2="572.952941%"
          y2="53.5947422%"
          id="linearGradient-5"
        >
          <stop stopColor="#9EA6C7" offset="0%" />
          <stop stopColor="#BABFD3" offset="37.69%" />
          <stop stopColor="#E6E6E6" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-0.00824272185%"
          y1="50.02286%"
          x2="100.005963%"
          y2="50.02286%"
          id="linearGradient-6"
        >
          <stop stopColor="#4E4E80" offset="0.1322115%" />
          <stop stopColor="#282154" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="34.6973451%"
          y1="90.6770642%"
          x2="65.2292035%"
          y2="8.21834862%"
          id="linearGradient-7"
        >
          <stop stopColor="#9999C8" offset="0%" />
          <stop stopColor="#C3C3D8" offset="51.54%" />
          <stop stopColor="#E6E6E6" offset="100%" />
        </linearGradient>
        <linearGradient x1="0%" y1="0%" x2="99.9521741%" y2="99.9521741%" id="linearGradient-8">
          <stop stopColor="#F9FBFF" offset="0%" />
          <stop stopColor="#DEE7FF" offset="100%" />
        </linearGradient>
        <linearGradient x1="-11.7875%" y1="44.9375%" x2="99.4625%" y2="43.4875%" id="linearGradient-9">
          <stop stopColor="#CCCCCC" offset="0%" />
          <stop stopColor="#CCCCCD" offset="0.7831617%" />
          <stop stopColor="#D0DBF1" offset="55.73%" />
          <stop stopColor="#D2E0FF" offset="84.93%" />
        </linearGradient>
        <linearGradient
          x1="0.0516579407%"
          y1="50.0020468%"
          x2="100.047818%"
          y2="50.0020468%"
          id="linearGradient-10"
        >
          <stop stopColor="#FFFFFF" offset="0%" />
          <stop stopColor="#F7F9FF" offset="14.22%" />
          <stop stopColor="#DCE7FF" offset="68.18%" />
          <stop stopColor="#D2E0FF" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="0.285717069%"
          y1="33.7243183%"
          x2="99.6539476%"
          y2="65.7354398%"
          id="linearGradient-11"
        >
          <stop stopColor="#FF7D00" offset="0%" />
          <stop stopColor="#FFD405" offset="97.75%" />
          <stop stopColor="#FFD605" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-0.852272727%"
          y1="49.6233333%"
          x2="99.9045455%"
          y2="49.6233333%"
          id="linearGradient-12"
        >
          <stop stopColor="#FFBF00" offset="0%" />
          <stop stopColor="#FFC100" offset="5.060112%" />
          <stop stopColor="#FFD104" offset="58.06%" />
          <stop stopColor="#FFD605" offset="100%" />
        </linearGradient>
        <linearGradient x1="-4.4%" y1="52.6416667%" x2="100.78125%" y2="52.6416667%" id="linearGradient-13">
          <stop stopColor="#61B6D6" offset="0%" />
          <stop stopColor="#7FBFE7" offset="39.07%" />
          <stop stopColor="#ABCDFF" offset="100%" />
        </linearGradient>
        <linearGradient x1="-1.425%" y1="51.1833333%" x2="103.75625%" y2="51.1833333%" id="linearGradient-14">
          <stop stopColor="#61B6D6" offset="0%" />
          <stop stopColor="#7FBFE7" offset="39.07%" />
          <stop stopColor="#ABCDFF" offset="100%" />
        </linearGradient>
        <linearGradient x1="-4.9625%" y1="53.8333333%" x2="100.2125%" y2="53.8333333%" id="linearGradient-15">
          <stop stopColor="#FF7D00" offset="0%" />
          <stop stopColor="#FFD405" offset="97.75%" />
          <stop stopColor="#FFD605" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="2.55614328%"
          y1="65.2827267%"
          x2="95.753642%"
          y2="43.8783949%"
          id="linearGradient-16"
        >
          <stop stopColor="#3797D3" offset="0%" />
          <stop stopColor="#3890D7" offset="24.94%" />
          <stop stopColor="#3A7CE0" offset="61.96%" />
          <stop stopColor="#3D60EE" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="1.53614528%"
          y1="50.2684678%"
          x2="100.199116%"
          y2="50.2684678%"
          id="linearGradient-17"
        >
          <stop stopColor="#3797D3" offset="0%" />
          <stop stopColor="#3890D7" offset="24.94%" />
          <stop stopColor="#3A7CE0" offset="61.96%" />
          <stop stopColor="#3D60EE" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="0.220560748%"
          y1="49.8263636%"
          x2="99.964486%"
          y2="49.8263636%"
          id="linearGradient-18"
        >
          <stop stopColor="#CCCCCC" offset="0%" />
          <stop stopColor="#CCCCCD" offset="0.7831617%" />
          <stop stopColor="#D0DBF1" offset="55.73%" />
          <stop stopColor="#D2E0FF" offset="84.93%" />
        </linearGradient>
        <linearGradient
          x1="0.29562397%"
          y1="48.331452%"
          x2="99.8329818%"
          y2="48.331452%"
          id="linearGradient-19"
        >
          <stop stopColor="#3797D3" offset="0%" />
          <stop stopColor="#3890D7" offset="24.94%" />
          <stop stopColor="#3A7CE0" offset="61.96%" />
          <stop stopColor="#3D60EE" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="0.144989773%"
          y1="46.4815852%"
          x2="97.6432331%"
          y2="75.7900371%"
          id="linearGradient-20"
        >
          <stop stopColor="#FFFFFF" offset="0%" />
          <stop stopColor="#B2C2E8" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="3.56662918%"
          y1="46.7046363%"
          x2="101.028414%"
          y2="52.6454518%"
          id="linearGradient-21"
        >
          <stop stopColor="#C6309A" offset="0%" />
          <stop stopColor="#E14986" offset="38.66%" />
          <stop stopColor="#F45A78" offset="74.74%" />
          <stop stopColor="#FB6073" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-1.7841629%"
          y1="52.6628267%"
          x2="100.969295%"
          y2="46.1231658%"
          id="linearGradient-22"
        >
          <stop stopColor="#C6309A" offset="0.2323718%" />
          <stop stopColor="#E14986" offset="38.66%" />
          <stop stopColor="#F45A78" offset="74.74%" />
          <stop stopColor="#FB6073" offset="100%" />
        </linearGradient>
        <linearGradient x1="12.5011055%" y1="96.0141951%" x2="100%" y2="27.3630012%" id="linearGradient-23">
          <stop stopColor="#FFD65E" offset="0%" />
          <stop stopColor="#FFFFFF" offset="100%" />
        </linearGradient>
        <linearGradient x1="121.812143%" y1="55.0459016%" x2="16.39%" y2="46.3114754%" id="linearGradient-24">
          <stop stopColor="#C6309A" offset="0.2323718%" />
          <stop stopColor="#E14986" offset="38.66%" />
          <stop stopColor="#F45A78" offset="74.74%" />
          <stop stopColor="#FB6073" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="37.3210869%"
          y1="105.346241%"
          x2="60.5882738%"
          y2="13.0195882%"
          id="linearGradient-25"
        >
          <stop stopColor="#C4D3F3" offset="0%" />
          <stop stopColor="#FFFFFF" offset="100%" />
        </linearGradient>
      </defs>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <TweenOne
          id="Group-61"
          transform="translate(19.000000, 265.000000)"
          fillRule="nonzero"
          component="g"
          animation={{ opacity: 0, type: 'from' }}
        >
          <polygon
            id="Shape"
            fill="#EBEBEB"
            points="2.35505937 82.1170327 164.05527 0.718160857 443.616966 59.8426882 443.616966 100.388154 291.054101 194.207283 2.25239257 121.636031"
          />
          <polygon
            id="Shape"
            fill="#D8D8D8"
            points="443.367566 100.601578 290.804701 194.420706 290.804701 159.726105 443.367566 74.837244"
          />
          <path
            d="M440.268795,66.0736508 C441.295463,66.2789443 442.116797,66.5868845 442.835465,66.8948248 C443.451466,67.202765 443.9648,67.613352 444.170133,68.023939 C444.375467,68.434526 444.478134,68.845113 444.2728,69.2557 C444.067467,69.666287 443.656799,70.076874 443.040799,70.487461 L303.41395,156.197496 C302.695283,156.608083 301.771282,157.01867 300.744614,157.326611 C299.717946,157.634551 298.588611,157.839844 297.356609,157.942491 C296.124608,158.045138 294.995273,158.147785 293.763271,158.045138 C292.53127,157.942491 291.401935,157.839844 290.375267,157.531904 L3.01089319,86.9109408 C2.08689199,86.7056473 1.36822439,86.3977071 0.854890389,86.0897668 C0.341556388,85.7818266 0.0335559876,85.3712396 0.0335559876,84.9606526 C-0.0691108126,84.5500656 0.136222788,84.1394786 0.444223188,83.7288916 C0.752223588,83.3183046 1.36822439,82.9077176 2.08689199,82.4971306 L150.440418,7.7702974 C151.159086,7.46235715 151.98042,7.1544169 152.904421,6.94912341 C153.828423,6.74382991 154.855091,6.53853641 155.881759,6.43588966 C156.908427,6.33324291 157.935095,6.33324291 158.961763,6.33324291 C159.988431,6.33324291 160.912432,6.43588966 161.836433,6.64118316 L440.268795,66.0736508 Z"
            id="Shape"
            fill="#58B893"
          />
          <path
            d="M290.834288,167.154191 L290.834288,156.944868 C97.718575,109.884289 1.04987362,86.3009829 0.828183716,86.1949507 C0.495648861,86.0359023 0.00684931507,85.0470384 0.00684931507,84.9443917 C0.554405582,91.9524028 0.828183716,95.4564083 0.828183716,95.4564083 C0.896628249,95.5932706 97.5653296,119.492531 290.834288,167.154191 Z"
            id="Shape"
            fill="#4DAC8C"
          />
          <path
            d="M290.804701,162.021719 L290.804701,167.154056 C296.364925,166.923603 300.676931,166.205076 303.740718,164.998474 C305.064602,164.994055 351.743774,136.355612 443.778233,79.0831456 C443.983566,78.8778521 444.086233,78.8778521 444.086233,78.7752054 C444.291567,78.5699119 444.394234,78.4672651 444.394234,78.4672651 C444.599567,78.2619716 444.4969,78.0369613 444.599567,78.0369613 C444.702234,77.8316678 444.702234,67.9972967 444.599567,68.0999435 C444.4969,68.2025902 444.394234,68.4078837 444.1889,68.5105305 C443.983566,68.6131772 443.778233,68.8184707 443.572899,68.9211175 L303.535384,154.117919 C303.33005,154.220566 296.900403,158.29715 290.804701,156.889381 L290.804701,162.021719 Z"
            id="Shape"
            fill="#529E88"
          />
        </TweenOne>
        <TweenOne
          id="Group-41"
          component="g"
          style={{ transformOrigin: '50% 100%' }}
          animation={{
            opacity: 0,
            scale: 0,
            type: 'from',
            delay: 200,
            ease: 'easeOutBack',
          }}
        >
          <g transform="translate(41, 290)">
            <polygon
              id="Shape"
              fill="#4DA788"
              fillRule="nonzero"
              points="41.252515 56.8688923 0 46.5425403 0 34.6896841 22.868242 40.2569347"
            />
            <polygon
              id="Shape"
              fill="#A7EAC4"
              fillRule="nonzero"
              points="22.8379768 40.2773537 0.0834001354 34.7005675 26.1187991 22.5465318 46.6310236 27.6590315"
            />
            <polygon
              id="Shape"
              fill="#61D0A6"
              fillRule="nonzero"
              points="64.8828288 44.325125 41.2074724 56.8065418 22.9128788 40.1945842 46.5882352 27.7131675"
            />
            <ellipse
              id="Oval-5"
              fill="#8CD6AC"
              cx="22.6968766"
              cy="30.6773688"
              rx="9.746119"
              ry="2.86775183"
            />
            <path
              d="M35.610424,18.2289515 C35.610424,25.3227063 29.8709437,31.0695457 22.7862726,31.0695457 C15.7016016,31.0695457 9.96212121,25.3227063 9.96212121,18.2289515 C9.96212121,8.80054313 15.7016016,0.000695335239 22.7862726,0.000695335239 C29.1535086,-0.08909903 35.610424,8.53116003 35.610424,18.2289515 Z"
              id="Shape"
              fill="url(#linearGradient-1)"
              fillRule="nonzero"
            />
          </g>
        </TweenOne>
        <TweenOne
          id="Group-47"
          fillRule="nonzero"
          component="g"
          style={{ transformOrigin: '50% 100%' }}
          animation={{
            opacity: 0,
            scale: 0,
            type: 'from',
            delay: 600,
            ease: 'easeOutBack',
          }}
        >
          <g transform="translate(143.000000, 219.000000)">
            <ellipse
              id="Oval"
              fill="#4BAA8A"
              cx="14.4945179"
              cy="73.3931036"
              rx="13.860018"
              ry="5.33763094"
            />
            <path
              d="M21.5113875,45.9852607 C23.7700571,59.8425718 24.2833911,66.4119638 22.2300551,70.415187 C19.9713855,74.6237037 12.8873763,76.1634049 8.57537068,73.6998829 C1.38869467,69.594013 4.36603188,64.4616755 6.21403428,44.7534998 C8.06203668,25.455911 6.11136748,-3.28517873 14.2220447,1.12863147 C18.9447175,3.69480019 18.3287167,26.3797317 21.5113875,45.9852607 Z"
              id="Shape"
              fill="#46817E"
            />
            <path
              d="M15.0930743,47.1063456 C11.4997363,60.1424827 15.1957411,65.1721734 13.2450719,68.9701032 C12.0130703,71.3309784 10.0624011,73.5892069 7.08506389,72.0495056 C0.719722285,68.7648097 3.59439269,61.8874775 4.82639429,45.2587042 C5.54506189,35.91785 6.36639629,24.2161206 7.18773069,15.6964405 C8.31706549,4.71323834 10.3704015,-0.521745854 12.7317379,2.35236311 C12.7317379,2.35236311 15.8117419,5.63705908 13.5530723,18.7758429 C11.9104035,29.5537516 17.2490771,39.4078395 15.0930743,47.1063456 Z"
              id="Shape"
              fill="#69C6B3"
            />
          </g>
        </TweenOne>
        <TweenOne
          id="Group-46"
          fillRule="nonzero"
          component="g"
          style={{ transformOrigin: '50% 100%' }}
          animation={{
            opacity: 0,
            scale: 0,
            type: 'from',
            delay: 500,
            ease: 'easeOutBack',
          }}
        >
          <g transform="translate(127.000000, 227.000000)">
            <ellipse
              id="Oval"
              fill="#4BAA8A"
              cx="14.8783819"
              cy="70.8028922"
              rx="13.9626848"
              ry="5.13233744"
            />
            <path
              d="M21.7925847,45.8463756 C24.0512543,59.7036867 24.5645883,66.2730786 22.5112523,70.2763019 C20.2525827,74.4848186 13.1685735,76.0245198 8.8565679,73.5609978 C1.6698919,69.4551279 4.6472291,64.3227904 6.4952315,44.6146146 C8.3432339,25.3170258 6.3925647,-3.42406385 14.5032419,0.989746349 C19.2259147,3.55591507 18.6099139,26.2408466 21.7925847,45.8463756 Z"
              id="Shape"
              fill="#46817E"
            />
            <path
              d="M15.1521848,46.9674605 C11.5588468,60.0035976 15.2548516,66.0597558 13.3041824,69.8576855 C12.0721808,72.2185607 10.1215116,73.4503217 7.14417442,71.9106205 C0.778832812,68.6259245 3.65350322,61.7485924 4.88550482,45.119819 C5.60417242,35.7789649 6.42550682,24.0772355 7.24684122,15.5575554 C8.37617602,4.57435322 10.429512,-0.660630976 12.7908484,2.21347799 C12.7908484,2.21347799 15.8708524,5.49817396 13.6121828,18.6369578 C11.969514,29.4148665 17.3081876,39.2689543 15.1521848,46.9674605 Z"
              id="Shape"
              fill="#69C6B3"
            />
          </g>
        </TweenOne>
        <TweenOne
          id="Group-45"
          fillRule="nonzero"
          component="g"
          style={{ transformOrigin: '50% 100%' }}
          animation={{
            opacity: 0,
            scale: 0,
            type: 'from',
            delay: 400,
            ease: 'easeOutBack',
          }}
        >
          <g transform="translate(113.000000, 234.000000)">
            <ellipse
              id="Oval"
              fill="#4BAA8A"
              cx="14.2823508"
              cy="71.6640071"
              rx="13.9626848"
              ry="5.13233744"
            />
            <path
              d="M18.9510103,45.5844741 C21.2096799,59.4417852 21.7230139,66.0111771 19.6696779,70.0144003 C17.4110083,74.222917 10.3269991,75.7626183 6.0149935,73.2990963 C-1.17168251,69.1932263 1.8056547,64.0608889 3.6536571,44.3527131 C5.5016595,25.0551243 3.5509903,-3.68596539 11.6616675,0.727844816 C16.2816735,3.29401354 15.6656727,25.978945 18.9510103,45.5844741 Z"
              id="Shape"
              fill="#46817E"
            />
            <path
              d="M12.3106104,46.705559 C8.51193882,60.3575766 13.1319448,64.3607998 11.489276,67.542849 C10.2572744,69.9037242 7.27993722,73.1884202 4.30260002,71.648719 C-2.06274159,68.364023 0.811928812,61.4866908 2.04393041,44.8579175 C2.76259801,35.5170634 3.58393242,23.815334 4.40526682,15.2956538 C5.53460162,4.31245169 7.58793762,-0.922532508 9.94927402,1.95157646 C9.94927402,1.95157646 13.029278,5.23627243 10.7706084,18.3750563 C9.02527282,29.1529649 14.4666132,39.0070528 12.3106104,46.705559 Z"
              id="Shape"
              fill="#69C6B3"
            />
          </g>
        </TweenOne>
        <TweenOne
          id="Group-44"
          fillRule="nonzero"
          component="g"
          style={{ transformOrigin: '50% 100%' }}
          animation={{
            opacity: 0,
            scale: 0,
            type: 'from',
            delay: 300,
            ease: 'easeOutBack',
          }}
        >
          <g transform="translate(95.000000, 241.000000)">
            <ellipse
              id="Oval"
              fill="#4BAA8A"
              cx="17.7824233"
              cy="71.7039479"
              rx="12.9360168"
              ry="4.31116345"
            />
            <path
              d="M18.9866643,45.3225726 C21.2453339,59.1798837 21.7586679,65.7492756 19.7053319,69.7524988 C17.4466623,73.9610155 10.3626531,75.5007167 6.05064747,73.0371948 C-1.13602854,68.9313248 1.84130866,63.7989874 3.68931107,44.0908116 C5.53731347,24.7932228 3.58664427,-3.94786692 11.6973215,0.465943284 C16.3173275,3.03211201 15.7013267,25.7170435 18.9866643,45.3225726 Z"
              id="Shape"
              fill="#46817E"
            />
            <path
              d="M12.5683511,46.6664782 C8.97501309,59.7026153 12.6710179,65.7587735 10.7203487,69.5567032 C9.48834709,71.9175784 7.53767788,73.1493394 4.56034068,71.6096381 C-1.80500093,68.3249422 1.06966948,61.44761 2.30167108,44.8188367 C3.02033868,35.4779825 3.84167308,23.7762532 4.66300748,15.256573 C5.79234228,4.27337087 7.84567828,-0.961613324 10.2070147,1.91249564 C10.2070147,1.91249564 13.2870187,5.19719161 11.0283491,18.3359755 C9.28301349,29.1138841 14.7243539,38.967972 12.5683511,46.6664782 Z"
              id="Shape"
              fill="#69C6B3"
            />
          </g>
        </TweenOne>
        <TweenOne
          id="Oval"
          fill="url(#linearGradient-2)"
          fillRule="nonzero"
          cx="67.4452055"
          cy="254.755382"
          rx="9.00684932"
          ry="9.00195695"
          component="ellipse"
          style={{ transformOrigin: '50% 50%' }}
          animation={[
            {
              opacity: 0,
              scale: 0,
              type: 'from',
              delay: 900,
              ease: 'easeOutBack',
            },
            {
              y: -15,
              yoyo: true,
              repeat: -1,
              duration: 3400,
              ease: 'easeInOutQuad',
            },
          ]}
        />
        <TweenOne
          id="Oval-Copy"
          fill="url(#linearGradient-2)"
          fillRule="nonzero"
          cx="493.006849"
          cy="72.0019569"
          rx="9.00684932"
          ry="9.00195695"
          component="ellipse"
          style={{ transformOrigin: '50% 50%' }}
          animation={[
            {
              opacity: 0,
              scale: 0,
              type: 'from',
              delay: 1000,
              ease: 'easeOutBack',
            },
            {
              y: 15,
              yoyo: true,
              repeat: -1,
              duration: 3700,
              ease: 'easeInOutQuad',
            },
          ]}
        />
        <TweenOne
          id="Oval-Copy-2"
          fill="url(#linearGradient-2)"
          fillRule="nonzero"
          cx="9.00684932"
          cy="161.035225"
          rx="9.00684932"
          ry="9.00195695"
          component="ellipse"
          style={{ transformOrigin: '50% 50%' }}
          animation={[
            {
              opacity: 0,
              scale: 0,
              type: 'from',
              delay: 800,
              ease: 'easeOutBack',
            },
            {
              y: 15,
              yoyo: true,
              repeat: -1,
              duration: 3300,
              ease: 'easeInOutQuad',
            },
          ]}
        />
        <TweenOne id="Group-56" fillRule="nonzero" animation={{ opacity: 0, type: 'from' }} component="g">
          <g transform="translate(173.000000, 53.000000)">
            <g id="Group-55">
              <path
                d="M292.815383,271.839639 C292.815383,271.839639 292.815383,271.839639 292.815383,271.942286 C292.815383,272.45552 292.815383,272.968754 292.712716,273.379341 C292.302049,275.740216 290.226873,277.184905 288.37887,277.800786 L294.150051,276.356096 C295.998054,275.740216 297.695153,274.045671 298.10582,271.684796 C298.208487,271.171562 298.317454,270.607878 298.317454,269.991998 L297.846056,269.991998 C297.846056,269.991998 296.169165,270.607878 292.815383,271.839639 Z"
                id="Shape"
                fill="url(#linearGradient-3)"
              />
              <path
                d="M5.24606486,2.26712772 L10.2767381,0.419486235 C10.3794049,0.419486235 10.4820717,0.316839486 10.5847385,0.316839486 L5.55406526,2.16448097 C5.45139846,2.16448097 5.34873166,2.16448097 5.24606486,2.26712772"
                id="Shape"
                fill="#8A8A8A"
              />
              <path
                d="M4.52739726,2.16448097 L9.55807047,0.316839486 C9.86607087,0.214192737 10.1740713,0.214192737 10.5847385,0.111545988 L5.55406526,1.95918747 C5.24606486,1.95918747 4.83539766,2.06183422 4.52739726,2.16448097 Z M5.55406526,1.95918747 L10.5847385,0.111545988 C10.8927389,0.111545988 11.2007393,0.111545988 11.6114065,0.111545988 L6.58073326,1.95918747 C6.17006606,1.95918747 5.86206566,1.95918747 5.55406526,1.95918747 Z M6.58073326,1.95918747 L11.6114065,0.111545988 C11.7140733,0.111545988 11.9194069,0.111545988 12.0220737,0.214192737 L6.99140046,2.06183422 C6.88873366,2.06183422 6.68340006,2.06183422 6.58073326,1.95918747 Z"
                id="Combined-Shape"
                fill="#9B9DC0"
              />
              <polygon
                id="Shape"
                fill="url(#linearGradient-4)"
                points="292.103208 59.0107336 287.144994 61.6455215 7.6859636 2.21305387 12.7166368 0.468059135"
              />
              <polygon
                id="Shape"
                fill="url(#linearGradient-5)"
                points="292.869957 70.717055 298.091947 68.9720602 298.194614 270.159688 293.16394 272.00733"
              />
              <path
                d="M286.876372,61.1599954 C290.46971,61.8785226 293.344381,66.0870393 293.344381,70.3982028 L293.652381,271.585831 C293.652381,275.896994 290.777711,278.87375 287.184373,278.052576 L7.72534264,218.620108 C4.13200464,217.901581 1.25733423,213.693064 1.25733423,209.381901 L0.949333834,8.19427295 C0.949333834,3.8831095 3.82400424,0.906353778 7.41734224,1.72752777 L286.876372,61.1599954 Z"
                id="Shape"
                fill="url(#linearGradient-6)"
              />
              <path
                d="M297.632009,66.9740057 C297.529342,66.5634187 297.529342,66.1528317 297.426675,65.7422447 C297.324008,65.3316577 297.221341,64.818424 297.016008,64.407837 C296.810674,63.7919565 296.502674,63.2787227 296.194673,62.765489 C295.476006,61.533728 294.449338,60.5072605 293.320003,59.7887333 C292.909336,59.480793 292.396002,59.2754995 291.882668,59.070206 C291.677334,58.9675593 291.472001,58.9675593 291.164,58.8649125 L286.133327,60.712554 C286.338661,60.712554 286.543994,60.8152007 286.851995,60.9178475 C287.365329,61.123141 287.775996,61.3284345 288.28933,61.6363747 C289.418665,62.354902 290.445333,63.3813695 291.164,64.6131305 C291.472001,65.1263642 291.780001,65.6395979 291.985335,66.2554784 C292.190668,66.6660654 292.293335,67.0766524 292.396002,67.5898862 C292.498669,68.0004732 292.601335,68.4110602 292.601335,68.8216472 C292.601335,69.2322342 292.704002,69.6428212 292.704002,70.0534081 L297.734675,68.2057667 C297.632009,67.7951797 297.632009,67.3845927 297.632009,66.9740057 Z"
                id="Shape"
                fill="url(#linearGradient-7)"
              />
              <polygon
                id="Shape"
                fill="url(#linearGradient-8)"
                points="281.998527 258.400029 9.93150685 199.480795 9.93150685 22.9283874 281.998527 81.8476212"
              />
              <g id="Group" transform="translate(144.664102, 37.527601)">
                <ellipse
                  id="Oval"
                  fillOpacity="0.5"
                  fill="#FFFFFF"
                  transform="translate(4.631366, 5.226833) rotate(-5.842860) translate(-4.631366, -5.226833) "
                  cx="4.63136559"
                  cy="5.22683282"
                  rx="3.25602189"
                  ry="4.15514053"
                />
                <ellipse
                  id="Oval"
                  fill="#2F295C"
                  transform="translate(5.862866, 5.452087) rotate(-5.842860) translate(-5.862866, -5.452087) "
                  cx="5.86286643"
                  cy="5.45208745"
                  rx="1.57187264"
                  ry="2.02141972"
                />
              </g>
            </g>
            <g id="Group-54" transform="translate(46.000000, 53.000000)">
              <path
                d="M192.045886,170.908689 L3.24164014,131.287044 C1.90697174,130.979104 0.982970539,129.84999 0.982970539,128.412935 L0.982970539,8.11094534 L195.536557,49.0669982 L195.536557,168.137227 C195.536557,169.882222 193.893888,171.319276 192.045886,170.908689 Z"
                id="Shape"
                fillOpacity="0.64"
                fill="#FFFFFF"
              />
              <polygon
                id="Shape"
                fill="#76C0F5"
                points="153.679912 74.1253767 80.6997561 58.6466838 80.6997561 51.9085854 153.632715 67.3837844"
              />
              <polygon
                id="Shape-Copy-3"
                fill="#76C0F5"
                points="128.992543 82.3655173 80.6997561 72.1228808 80.6997561 65.3847823 129.026318 75.6389004"
              />
              <g id="Group-39" transform="translate(25.683946, 32.817306)">
                <path
                  d="M42.1394349,98.0558333 L6.34780344,90.0729709 C4.86677041,89.7437807 3.79713544,88.4270199 3.79713544,86.8633664 L3.79713544,4.89500554 C3.79713544,2.2614839 6.26552382,0.286342677 8.81619182,0.862425535 L42.9622311,8.51609778 C44.8546622,8.92758554 46.171136,10.5735366 46.171136,12.5486778 L46.171136,94.8462289 C46.171136,96.9859652 44.1964253,98.5496187 42.1394349,98.0558333 Z"
                  id="Shape"
                  fill="#B3C0E4"
                />
                <path
                  d="M38.1077339,99.4548917 L3.96169467,91.8012195 C2.06926358,91.3897317 0.752789777,89.7437807 0.752789777,87.7686395 L0.752789777,5.47108839 C0.752789777,3.33135207 2.72750048,1.7676986 4.7844908,2.2614839 L39.9178854,10.0797513 C41.8103165,10.491239 43.1267903,12.13719 43.1267903,14.1123313 L43.1267903,95.4223117 C43.0445107,98.0558333 40.6584019,100.030975 38.1077339,99.4548917 Z"
                  id="Shape"
                  fill="#2F2A5C"
                />
                <polygon
                  id="Shape"
                  fill="#FFFFFF"
                  points="40.4938427 86.945664 3.13889854 79.2096942 3.13889854 12.877868 40.4938427 21.4368133"
                />
                <path
                  d="M33.8291941,28.9258904 L7.82883646,22.5066815 C7.17059956,22.3420864 6.7592015,21.7660035 6.7592015,21.1899206 C6.7592015,20.2846476 7.58199763,19.6262672 8.48707337,19.8731598 L34.487431,26.2923688 C35.1456679,26.4569639 35.5570659,27.0330468 35.5570659,27.6091296 C35.5570659,28.5144027 34.7342698,29.1727831 33.8291941,28.9258904 Z"
                  id="Shape"
                  fill="#76C0F5"
                />
                <path
                  d="M33.8291941,35.8388847 L7.82883646,29.4196758 C7.17059956,29.2550807 6.7592015,28.6789978 6.7592015,28.1029149 L6.7592015,28.1029149 C6.7592015,27.1976419 7.58199763,26.5392615 8.48707337,26.7861541 L34.487431,33.2053631 C35.1456679,33.3699582 35.5570659,33.9460411 35.5570659,34.5221239 L35.5570659,34.5221239 C35.5570659,35.3450994 34.7342698,36.0034798 33.8291941,35.8388847 Z"
                  id="Shape"
                  fill="#76C0F5"
                />
                <path
                  d="M17.7846696,39.2713829 L7.82883646,36.8024564 C7.17059956,36.6378613 6.7592015,36.0617784 6.7592015,35.4856956 L6.7592015,35.4856956 C6.7592015,34.5804225 7.58199763,33.9220421 8.48707337,34.1689348 L18.4429065,36.6378613 C19.1011434,36.8024564 19.5125415,37.3785393 19.5125415,37.9546221 L19.5125415,37.9546221 C19.5125415,38.7775976 18.6897453,39.435978 17.7846696,39.2713829 Z"
                  id="Shape"
                  fill="#76C0F5"
                />
                <path
                  d="M22.803726,40.4235487 L22.803726,40.4235487 C22.2277687,40.2589536 21.8163706,39.7651682 21.8163706,39.1890854 L21.8163706,38.9421927 C21.8163706,38.1192172 22.5568871,37.5431344 23.3796833,37.7077295 L23.3796833,37.7077295 C23.9556405,37.8723246 24.3670386,38.3661099 24.3670386,38.9421927 L24.3670386,39.1890854 C24.3670386,40.0120609 23.5442425,40.6704413 22.803726,40.4235487 Z"
                  id="Shape"
                  fill="#76C0F5"
                />
                <path
                  d="M23.7910813,88.1801272 C23.215124,85.8757958 21.3226929,84.3944399 19.5948211,84.8059276 C17.8669492,85.2174154 16.9618735,87.4394493 17.5378308,89.7437807 C18.113788,92.0481121 20.0062191,93.529468 21.734091,93.1179803 C23.4619629,92.7064925 24.3670386,90.4844586 23.7910813,88.1801272 Z M21.5695318,92.5418974 C20.0884987,92.9533852 18.5251861,91.6366244 18.0315084,89.7437807 C17.5378308,87.850937 18.2783473,85.9580933 19.7593803,85.5466056 C21.2404133,85.1351178 22.803726,86.4518786 23.2974036,88.3447223 C23.7910813,90.237566 22.9682852,92.1304097 21.5695318,92.5418974 Z"
                  id="Shape"
                  fillOpacity="0.5"
                  fill="#E6E6E6"
                />
                <polygon
                  id="Shape"
                  fillOpacity="0.5"
                  fill="#FE766E"
                  opacity="0.8"
                  points="10.3795045 71.2268317 9.22758988 70.8153439 15.8099589 55.8371896 16.9618735 56.330975"
                />
                <polygon
                  id="Shape"
                  fillOpacity="0.5"
                  fill="#FE766E"
                  opacity="0.8"
                  points="23.7088017 67.688037 22.4746075 67.2765492 30.7025688 52.298395 31.936763 52.7921803"
                />
                <polygon
                  id="Shape"
                  fillOpacity="0.5"
                  fill="#FE766E"
                  opacity="0.8"
                  points="17.9492288 55.0142141 16.7973142 55.4257019 21.9809298 66.7827639 23.1328444 66.3712762"
                />
                <path
                  d="M12.1073763,71.1445341 C12.0250967,72.7081876 10.8731821,73.8603533 9.55670833,73.7780558 C8.24023453,73.6957582 7.25287918,72.3789974 7.4174384,70.8976415 C7.58199763,69.4162856 8.65163259,68.1818223 9.96810639,68.2641199 C11.2023006,68.2641199 12.1896559,69.5808807 12.1073763,71.1445341 Z"
                  id="Shape"
                  fill="#FF968F"
                />
                <path
                  d="M25.2721143,66.2889786 C25.6835124,67.7703346 25.0252755,69.2516905 23.7910813,69.5808807 C22.5568871,69.9100709 21.1581337,69.0047978 20.7467356,67.5234419 C20.3353376,66.042086 20.9935745,64.5607301 22.2277687,64.2315399 C23.5442425,63.9023497 24.8607163,64.8076227 25.2721143,66.2889786 Z"
                  id="Shape"
                  fill="#FF968F"
                />
                <ellipse
                  id="Oval"
                  fill="#FF968F"
                  cx="31.5253649"
                  cy="51.4754194"
                  rx="2.79750683"
                  ry="3.29190204"
                />
                <path
                  d="M20.1707784,55.0142141 C20.3353376,57.236248 18.9365842,59.2113892 17.1264327,59.2936868 C15.2340016,59.4582819 13.5884093,57.7300333 13.3415705,55.5079994 C13.1770113,53.2859656 14.5757647,51.3108243 16.3859162,51.2285268 C18.3606269,51.0639317 20.0062191,52.7921803 20.1707784,55.0142141 Z"
                  id="Shape"
                  fill="#FF968F"
                />
                <path
                  d="M25.4366736,13.9477362 L17.70239,12.13719 C17.2909919,12.0548925 17.0441531,11.7257023 17.0441531,11.3142145 L17.0441531,11.3142145 C17.0441531,10.7381317 17.5378308,10.3266439 18.113788,10.491239 L25.8480716,12.3017851 C26.2594697,12.3840827 26.5063085,12.7132729 26.5063085,13.1247606 L26.5063085,13.1247606 C26.5063085,13.618546 25.9303512,14.0300337 25.4366736,13.9477362 Z"
                  id="Shape"
                  fillOpacity="0.5"
                  fill="#E6E6E6"
                />
                <path
                  d="M15.8099589,10.4089415 C15.9745181,10.9027268 15.7276793,11.3965121 15.3162812,11.4788096 C14.9048832,11.6434047 14.4112055,11.3965121 14.2466463,10.9027268 C14.082087,10.4089415 14.3289259,9.91515615 14.7403239,9.8328586 C15.2340016,9.6682635 15.6453997,9.91515615 15.8099589,10.4089415 Z"
                  id="Shape"
                  fillOpacity="0.5"
                  fill="#E6E6E6"
                />
              </g>
              <path
                d="M192.073042,170.559624 L161.683669,164.503466 L161.539313,53.0316018 L195.522024,60.2168742 L195.563713,167.685515 C195.563713,169.533156 193.921044,170.867564 192.073042,170.559624 Z"
                id="Shape"
                fill="#8AA4DE"
                opacity="0.7"
              />
              <path
                d="M195.536557,60.2200146 L0.982970539,18.9758213 L0.982970539,3.168222 C0.982970539,1.32058052 2.72830614,-0.0138272155 4.47364174,0.294113031 L193.277887,40.0184049 C194.612556,40.3263451 195.536557,41.4554593 195.536557,42.8925138 L195.536557,60.2200146 Z"
                id="Shape"
                fill="#CBD7F7"
              />
              <polygon
                id="Shape"
                fill="#FFFFFF"
                points="184.682941 74.296756 171.644258 71.6279405 171.644258 68.7538315 184.682941 71.422647"
              />
              <polygon
                id="Shape"
                fill="#FFFFFF"
                points="184.682941 82.2605176 171.644258 79.4890554 171.644258 76.6149464 184.682941 79.2837619"
              />
              <polygon
                id="Shape"
                fill="#FFFFFF"
                points="184.682941 90.1216325 171.644258 87.3501703 171.644258 84.4760613 184.682941 87.1448768"
              />
            </g>
          </g>
        </TweenOne>
        <TweenOne
          id="Group-50"
          fillRule="nonzero"
          component="g"
          style={{ transformOrigin: '50% 100%' }}
          animation={{
            opacity: 0,
            scale: 0,
            type: 'from',
            delay: 400,
            ease: 'easeOutBack',
          }}
        >
          <g transform="translate(370.000000, 285.000000)">
            <path
              d="M23.130048,38.4543211 C22.8220476,41.1231366 17.5860408,42.8681313 11.323366,42.2522508 C5.06069116,41.6363703 0.235351553,38.9675548 0.440685154,36.2987394 C0.646018754,33.6299239 5.98469236,31.8849292 12.2473672,32.5008096 C18.6127088,33.1166901 23.4380484,35.7855056 23.130048,38.4543211 Z"
              id="Shape"
              fill="#529E88"
            />
            <ellipse
              id="Oval"
              fill="#3E6C5D"
              transform="translate(12.084368, 39.074602) rotate(-84.550874) translate(-12.084368, -39.074602) "
              cx="12.0843679"
              cy="39.0746021"
              rx="3.28540061"
              ry="9.13573584"
            />
            <g id="Group" transform="translate(2.679912, 0.932456)">
              <path
                d="M10.4720136,40.0322321 L10.0613464,40.0322321 C5.03067321,40.0322321 0.924001201,35.9263621 0.924001201,30.8966714 L0.924001201,9.75144115 C0.924001201,4.6191037 5.13334001,0.410586996 10.26668,0.410586996 L10.26668,0.410586996 C15.40002,0.410586996 19.6093588,4.6191037 19.6093588,9.75144115 L19.6093588,30.8966714 C19.6093588,35.9263621 15.5026868,40.0322321 10.4720136,40.0322321 Z"
                id="Shape"
                fill="#D75149"
              />
              <path
                d="M9.75334601,39.5189983 L9.03467841,39.5189983 C4.62000601,39.5189983 1.026668,35.9263621 1.026668,31.5125519 L1.026668,10.0593814 C1.026668,5.54292444 4.72267281,1.84764148 9.24001201,1.84764148 L9.54801241,1.84764148 C14.0653516,1.84764148 17.7613564,5.54292444 17.7613564,10.0593814 L17.7613564,31.5125519 C17.7613564,35.9263621 14.1680184,39.5189983 9.75334601,39.5189983 Z"
                id="Shape"
                fill="#FE766E"
              />
              <ellipse
                id="Oval"
                fill="#FFFFFF"
                transform="translate(8.253883, 13.671170) rotate(-3.569641) translate(-8.253883, -13.671170) "
                cx="8.25388272"
                cy="13.6711697"
                rx="5.13354646"
                ry="6.26170351"
              />
              <ellipse
                id="Oval"
                fill="#D75149"
                transform="translate(8.253985, 13.673941) rotate(-3.569641) translate(-8.253985, -13.673941) "
                cx="8.25398539"
                cy="13.6739412"
                rx="4.72286275"
                ry="5.8511"
              />
              <ellipse
                id="Oval"
                fill="#8C1A13"
                transform="translate(8.357765, 13.568999) rotate(-3.569641) translate(-8.357765, -13.568999) "
                cx="8.35776467"
                cy="13.5689988"
                rx="3.59348252"
                ry="4.31133684"
              />
              <path
                d="M11.3960148,13.2414306 C11.4986816,15.0890721 10.3693468,16.6287733 8.82934481,16.7314201 C7.28934281,16.8340668 5.95467441,15.3970123 5.85200761,13.5493709 C5.74934081,11.7017294 6.87867561,10.1620281 8.41867761,10.0593814 C9.95867961,9.95673464 11.293348,11.3937891 11.3960148,13.2414306 Z"
                id="Shape"
                fill="#FFFFFF"
              />
              <ellipse
                id="Oval"
                fill="#FFFFFF"
                transform="translate(8.250650, 28.247747) rotate(-3.569641) translate(-8.250650, -28.247747) "
                cx="8.25065026"
                cy="28.2477471"
                rx="5.13354646"
                ry="6.26170351"
              />
              <ellipse
                id="Oval"
                fill="#D75149"
                transform="translate(8.250753, 28.250416) rotate(-3.569641) translate(-8.250753, -28.250416) "
                cx="8.25075292"
                cy="28.2504159"
                rx="4.72286275"
                ry="5.8511"
              />
              <ellipse
                id="Oval"
                fill="#8C1A13"
                transform="translate(8.354532, 28.145576) rotate(-3.569641) translate(-8.354532, -28.145576) "
                cx="8.3545322"
                cy="28.1455762"
                rx="3.59348252"
                ry="4.31133684"
              />
              <path
                d="M11.3960148,27.9199157 C11.4986816,29.7675572 10.3693468,31.3072584 8.82934481,31.4099052 C7.28934281,31.5125519 5.95467441,30.0754974 5.85200761,28.2278559 C5.74934081,26.3802145 6.87867561,24.8405132 8.41867761,24.7378665 C9.95867961,24.6352197 11.293348,26.0722742 11.3960148,27.9199157 Z"
                id="Shape"
                fill="#FFFFFF"
              />
            </g>
          </g>
        </TweenOne>
        <TweenOne
          id="Group-49"
          fillRule="nonzero"
          component="g"
          style={{ transformOrigin: '50% 100%' }}
          animation={{
            opacity: 0,
            scale: 0,
            type: 'from',
            delay: 300,
            ease: 'easeOutBack',
          }}
        >
          <g transform="translate(404.000000, 298.000000)">
            <path
              d="M26.8108854,30.0094929 C25.989551,30.0094929 25.2708834,30.0094929 24.449549,30.1121396 C22.2935462,28.3671449 17.9815406,27.2380307 13.0535342,27.2380307 C5.86685816,27.2380307 0.117517358,29.7015526 0.117517358,32.6783083 C0.117517358,35.6550641 5.86685816,38.118586 13.0535342,38.118586 C13.8748686,38.118586 14.5935362,38.118586 15.4148706,38.0159393 C17.5708734,39.760934 21.882879,40.8900483 26.8108854,40.8900483 C33.9975614,40.8900483 39.7469022,38.4265263 39.7469022,35.4497706 C39.7469022,32.4730148 33.9975614,30.0094929 26.8108854,30.0094929 Z"
              id="Shape"
              fill="#4BAA8A"
            />
            <path
              d="M21.6791539,22.9710929 C21.5764871,30.5669523 16.7511475,33.7490015 10.7964731,33.6463548 C4.84179865,33.6463548 0.0164590461,30.3616588 0.119125846,22.7657994 C0.221792646,15.16994 5.14979905,0.18351463 11.1044735,0.286161379 C17.0591479,0.388808128 21.7818207,15.4778802 21.6791539,22.9710929 Z"
              id="Shape"
              fill="#46817E"
            />
            <path
              d="M11.5152491,20.8471624 C10.4885811,24.6450921 14.2872527,25.3636193 13.6712519,27.3139075 C13.2605847,28.4430218 12.3365835,29.982723 9.4619131,30.0853698 C4.5339067,30.1880165 0.0165674895,29.161549 0.11923429,22.9000973 C0.52990149,10.787781 6.3819091,-1.11924191 11.1045819,0.420459323 C13.3632515,1.13898657 11.4125823,5.86073701 11.8232495,10.377194 C12.1312499,14.3804172 12.6445839,17.1518794 11.5152491,20.8471624 Z"
              id="Shape"
              fill="#69C6B3"
            />
            <path
              d="M39.6434999,25.2171257 C39.5408331,32.8129851 34.7154935,35.9950343 28.7608191,35.8923876 C22.8061447,35.8923876 17.9808051,32.6076916 18.0834719,25.0118322 C18.1861387,17.4159728 23.1141451,2.42954745 29.0688195,2.5321942 C35.0234939,2.63484095 39.6434999,17.723913 39.6434999,25.2171257 Z"
              id="Shape"
              fill="#46817E"
            />
            <path
              d="M29.8902623,24.2223094 C28.8635943,28.0202391 32.2515987,27.6096521 31.6355979,29.5599404 C31.2249307,30.6890546 30.3009295,32.2287558 27.4262591,32.3314026 C22.4982527,32.4340493 17.9809135,31.4075818 18.0835803,25.1461302 C18.4942475,13.0338138 24.3462551,1.12679091 29.0689279,2.66649215 C31.3275975,3.38501939 28.9662611,9.23588408 29.3769283,13.752341 C29.7875955,17.7555642 31.0195971,20.4243797 29.8902623,24.2223094 Z"
              id="Shape"
              fill="#69C6B3"
            />
          </g>
        </TweenOne>
        <ellipse
          id="Oval"
          fill="url(#linearGradient-9)"
          fillRule="nonzero"
          cx="449.43905"
          cy="370.569274"
          rx="1"
          ry="1"
        />
        {/* <TweenOne
          id="Group"
          fillRule="nonzero"
          component="g"
          style={{ transformOrigin: '50% 100%' }}
          animation={[{
            opacity: 0, y: '-=10', type: 'from', delay: 1000,
          },
          {
            y: -15, yoyo: true, repeat: -1, duration: 1000, ease: 'easeInOutQuad',
          }]}
        >
          <g transform="translate(445.660068, 209.567340)">
            <polygon
              id="Shape"
              fill="#F39A18"
              points="35.7280464 67.9521478 40.8613865 64.1542181 40.553386 86.7365028 35.5227128 90.5344325"
            />
            <polygon
              id="Shape"
              fill="url(#linearGradient-10)"
              points="64.2694169 70.9289035 5.44134041 55.1213042 5.44134041 0.718527242 64.2694169 16.5261266"
            />
            <polygon
              id="Shape"
              fill="#FFBF00"
              points="59.2387437 20.3240563 0.410667201 4.51645695 0.410667201 58.9192339 24.126698 65.2833323 35.5227128 90.6370793 35.7280464 68.3627348 59.2387437 74.7268332"
            />
            <polygon
              id="Shape"
              fill="#F39A18"
              points="59.2387437 20.3240563 64.2694169 16.5261266 64.2694169 70.9289035 59.2387437 74.7268332"
            />
            <polygon
              id="Shape"
              fill="#FFDE79"
              points="59.2387437 20.3240563 64.2694169 16.5261266 5.44134041 0.718527242 0.410667201 4.51645695"
            />
            <polygon
              id="Shape"
              fill="#F39A18"
              points="26.0773672 49.88632 22.7920296 48.8598525 22.7920296 27.406682 26.0773672 28.4331494"
            />
            <polygon
              id="Shape"
              fill="#FFFFFF"
              points="24.8453656 50.5022005 21.560028 49.475733 21.560028 28.0225624 24.8453656 29.0490299"
            />
            <polygon
              id="Shape"
              fill="#F39A18"
              points="36.7547144 35.7210686 36.7547144 31.5125519 31.6213744 29.9728507 28.9520376 29.1516767 28.9520376 50.707494 28.9520376 50.707494 28.9520376 50.8101407 36.7547144 53.0683692 36.7547144 48.8598525 32.1347084 47.5254447 32.1347084 34.3866609"
            />
            <g transform="translate(27.720036, 28.741090)" id="Shape" fill="#FFFFFF">
              <polygon
                points="3.2853376 23.5061055 0.1026668 22.479638 0.1026668 0.92382074 3.2853376 1.95028823"
              />
              <polygon
                points="7.80267681 7.49321267 0.1026668 5.23498419 0.1026668 1.02646749 7.80267681 3.28469596"
              />
              <polygon
                points="7.80267681 24.8405132 0.1026668 22.5822848 0.1026668 18.3737681 7.80267681 20.6319965"
              />
            </g>
            <polygon
              id="Shape"
              fill="#F39A18"
              points="49.1773973 39.0057646 49.1773973 34.8998946 42.2987217 32.9496064 42.2987217 32.9496064 39.013384 31.9231389 39.013384 53.4789562 39.1160508 53.4789562 39.1160508 53.4789562 49.1773973 56.5583586 49.1773973 52.3498419 42.2987217 50.296907 42.2987217 46.0883903 49.1773973 47.9360317 49.1773973 43.727515 42.2987217 41.8798736 42.2987217 37.1581231"
            />
            <g transform="translate(37.986716, 32.846960)" id="Shape" fill="#FFFFFF">
              <polygon
                points="8.82934481 6.87733218 2.053336 4.92704395 2.053336 0.718527242 8.82934481 2.66881547"
              />
              <polygon
                points="8.82934481 15.7049526 2.053336 13.7546644 2.053336 9.6487944 8.82934481 11.4964359"
              />
              <polygon
                points="8.82934481 24.3272795 0.2053336 21.658464 0.2053336 17.4499473 8.82934481 20.2214095"
              />
              <polygon
                points="3.3880044 22.5822848 0.2053336 21.5558173 0.2053336 0.102646749 3.3880044 1.12911424"
              />
            </g>
            <polygon
              id="Shape"
              fill="#F39A18"
              points="16.1186876 25.5590405 16.1186876 38.6978243 11.1906812 24.2246327 7.90534361 23.1981653 7.90534361 44.7539825 11.1906812 45.78045 11.1906812 32.6416662 16.1186876 47.1148577 19.4040252 48.1413252 19.4040252 26.585508"
            />
            <g transform="translate(6.160008, 23.608752)" id="Shape" fill="#FFFFFF">
              <polygon
                points="3.9013384 22.6849315 0.718667601 21.658464 0.718667601 0.102646749 3.9013384 1.12911424"
              />
              <polygon
                points="12.1146824 25.0458067 8.93201161 24.0193392 8.93201161 2.46352197 12.1146824 3.48998946"
              />
              <polygon
                points="12.1146824 25.0458067 8.93201161 24.0193392 0.718667601 0.102646749 3.9013384 1.12911424"
              />
            </g>
          </g>
        </TweenOne> */}
        <TweenOne
          id="Group-52"
          fillRule="nonzero"
          component="g"
          style={{ transformOrigin: '50% 100%' }}
          animation={{
            opacity: 0,
            scale: 0,
            type: 'from',
            delay: 800,
            ease: 'easeOutBack',
          }}
        >
          <g transform="translate(231.000000, 281.000000)">
            <path
              d="M97.6155582,76.5646424 L38.3768145,115.057173 C38.0688141,115.262467 37.7608137,115.262467 37.4528133,115.15982 L1.10876608,105.305732 C0.0820980826,104.997792 -0.123235518,103.663384 0.800765684,103.15015 L62.9141798,64.4523261 L97.3075578,74.4090607 C98.3342258,74.6143542 98.5395594,75.9487619 97.6155582,76.5646424 Z"
              id="Shape"
              fill="#4BAA8A"
            />
            <g id="Group-51">
              <polygon
                id="Shape"
                fill="#FF9E00"
                points="62.7347681 59.975505 0.518687208 98.5706826 0.210686808 37.08528 59.7574309 0.440390614 62.7347681 1.4668581"
              />
              <polygon
                id="Shape"
                fill="#FFBF00"
                points="62.8177454 60.0720539 3.57900169 96.81959 3.57900169 38.3109431 62.8177454 1.56340703"
              />
              <polygon
                id="Shape"
                fill="#FF9E00"
                points="38.0414001 111.059032 0.210686808 101.204944 0.210686808 82.4205886 31.0107268 102.539351"
              />
              <polygon
                id="Shape"
                fill="#FFDE79"
                points="31.5043713 102.939775 3.57900169 84.8739476 62.9204122 46.4840635 90.8457818 64.5498913"
              />
              <polygon
                id="Shape"
                fill="#F3A218"
                points="47.5813359 85.4715346 28.2799775 72.5380442 62.8786891 49.8531127 81.9747139 62.6839563"
              />
              <polygon
                id="Shape"
                fill="#F3A218"
                points="28.2888846 96.8399161 9.19285982 84.0090725 25.9275482 73.4364573 45.0235731 86.267301"
              />
              <polygon
                id="Shape"
                fill="#FFBF00"
                points="95.9177092 73.074653 35.999822 111.154782 30.9691487 102.532455 90.7843692 64.4523261"
              />
            </g>
            <g id="Group-38" transform="translate(8.000000, 8.000000)">
              <polygon
                id="Shape"
                fill="#FFFAEB"
                points="52.6354899 34.7908131 0.0700881967 67.6377728 0.0700881967 33.1484651 52.6354899 0.301505493"
              />
              <polygon
                id="Shape"
                fill="#FE766E"
                opacity="0.6"
                points="25.7170987 35.0916392 3.43840308 48.8463036 3.43840308 34.2704652 25.7170987 20.5158009"
              />
              <polygon
                id="Shape"
                fill="#FFBF00"
                opacity="0.6"
                points="32.8554885 42.6935965 29.4674841 44.7465315 29.2621505 21.4457195 32.6501549 19.3927845"
              />
              <polygon
                id="Shape"
                fill="#FFBF00"
                opacity="0.6"
                points="38.4693466 39.9587212 35.0813422 42.0116562 34.8760086 14.707621 38.264013 12.654686"
              />
              <polygon
                id="Shape"
                fill="#FFBF00"
                opacity="0.6"
                points="44.0832048 35.5865795 40.6952004 37.7421612 40.4898668 26.0404319 43.8778712 23.8848501"
              />
              <polygon
                id="Shape"
                fill="#FFBF00"
                opacity="0.6"
                points="48.5742913 33.3588402 45.1862869 35.4117751 44.9809533 20.3227031 48.3689577 18.2697681"
              />
              <polygon
                id="Shape"
                fill="#81C8FB"
                opacity="0.6"
                points="25.7170987 41.6661127 3.43840308 55.420777 3.43840308 52.2387278 25.7170987 38.4840635"
              />
              <polygon
                id="Shape"
                fill="#81C8FB"
                opacity="0.8"
                points="25.7170987 46.1581783 3.43840308 59.9128427 3.43840308 56.7307935 25.7170987 42.9761291"
              />
              <path
                d="M10.1750328,40.4478776 L10.1750328,35.4843456 L9.05226121,36.1361502 L9.05226121,34.4938022 L12.234932,32.6461607 L12.234932,34.2885087 L11.612368,34.6499268 L11.612368,39.6598155 L12.3125348,39.275928 L12.3125348,40.8156292 L9.12986403,42.6632707 L9.12986403,41.0209227 L10.1750328,40.4478776 Z M22.8277462,28.984754 L22.8277462,26.9236725 L24.5730818,25.9104497 L24.5730818,24.2681018 L21.390411,26.1157432 L21.390411,27.0415625 L21.390411,34.5258245 L21.390411,35.6562334 L24.5730818,33.8254897 L24.5730818,32.2842688 L22.8277462,33.2974916 L22.8277462,30.627102 L24.5730818,29.6138793 L24.5730818,27.9715313 L22.8277462,28.984754 Z M5.90603303,38.290794 L5.90603303,44.5524173 L7.34336823,43.7312433 L7.34336823,37.4064527 L8.56517991,36.6547155 L8.56517991,35.1150142 L4.5611747,37.4758895 L4.5611747,39.1182374 L5.90603303,38.290794 Z M18.0344342,28.1201696 L17.9448938,28.1777201 L16.4626088,33.217656 L14.9806829,30.6229486 L14.2620153,31.0335356 L13.5433477,31.4441226 L13.5433477,40.1297968 L14.9806829,39.3086228 L14.9806829,33.4799498 L15.9825944,34.8990472 L15.9825944,38.701617 L17.0208926,38.1068469 L17.0208926,34.747112 L18.0344342,31.5308472 L18.0344342,37.5518254 L19.4717694,36.7306515 L19.4717694,27.1944795 L18.0344342,28.1183002 L18.0344342,28.1201696 Z"
                id="Combined-Shape"
                fill="#FFFFFF"
              />
            </g>

            <g id="格子" transform="translate(29.000000, 50.000000)">
              <polygon
                id="Shape"
                fill="#FFFFFF"
                points="18.5747727 35.36279 0.402749109 23.2504736 33.8721259 0.97612912 51.9414828 13.0884455"
              />
              <polygon
                id="Shape"
                fill="#DCE4EA"
                points="24.0859641 31.3646483 6.01660724 19.2523319 6.42727444 18.9443917 24.5992981 31.0567081"
              />
              <polygon
                id="Shape"
                fill="#DCE4EA"
                points="29.6998222 26.9752294 11.6304654 14.8629131 12.0411326 14.4523261 30.1104894 26.6672892"
              />
              <polygon
                id="Shape"
                fill="#DCE4EA"
                points="36.436452 23.5035334 18.3670951 11.3912171 18.7777623 11.0832768 36.8471192 23.1955932"
              />
              <polygon
                id="Shape"
                fill="#DCE4EA"
                points="42.0503101 20.1344842 23.9809533 8.02216783 24.3916205 7.71422759 42.5636441 19.826544"
              />
              <polygon
                id="Shape"
                fill="#DCE4EA"
                points="47.6641682 15.6424186 29.5948114 3.53010219 30.0054786 3.22216194 48.0748354 15.3344783"
              />
              <polygon
                id="Shape"
                fill="#DCE4EA"
                points="4.28439799 24.784077 3.77106399 24.4761368 37.137774 2.09914553 37.7537748 2.50973253"
              />
              <polygon
                id="Shape"
                fill="#DCE4EA"
                points="7.65271287 27.0301099 7.13937887 26.6195229 40.5060889 4.34517835 41.1220897 4.75576535"
              />
              <polygon
                id="Shape"
                fill="#DCE4EA"
                points="11.0210278 30.3991591 10.5076938 29.9885721 43.8744038 7.71422759 44.4904046 8.12481458"
              />
              <polygon
                id="Shape"
                fill="#DCE4EA"
                points="15.6147811 32.6451919 14.9987803 32.2346049 48.4681571 9.96026041 49.0841579 10.3708474"
              />
            </g>
            <g id="Group-38" transform="translate(31.5, 58)" fill="#FF9100">
              <TweenOne
                component="polygon"
                animation={{
                  opacity: 0,
                  type: 'from',
                  repeat: -1,
                  yoyo: true,
                  delay: 400,
                  duration: 800,
                }}
                id="Rectangle-43"
                points="27.7239081 3.89641362 33.3157796 0.0527744007 36.8472338 2.41925167 31.190919 6.2381809"
              />
              <TweenOne
                component="polygon"
                animation={{
                  opacity: 0,
                  type: 'from',
                  repeat: -1,
                  yoyo: true,
                  delay: 1100,
                  duration: 800,
                }}
                id="Rectangle-45"
                points="18.6936631 21 23.1998453 17.941457 25.8887254 20.483261 21.9111647 23.1321668"
              />
              <TweenOne
                component="polygon"
                animation={{
                  opacity: 0,
                  type: 'from',
                  repeat: -1,
                  yoyo: true,
                  delay: 1000,
                  duration: 800,
                }}
                id="Rectangle-41"
                points="10.5948338 15.6371791 16.2197739 11.7722831 19.5187137 14.0170696 13.8671193 17.8364597"
              />
              <TweenOne
                component="polygon"
                animation={{
                  opacity: 0,
                  type: 'from',
                  repeat: -1,
                  yoyo: true,
                  delay: 500,
                  duration: 800,
                }}
                id="Rectangle-44"
                points="0.608657837 17.3905029 6.4495697 13.4798279 9.58482361 15.5756531 3.82894897 19.5377502"
              />
              <TweenOne
                component="polygon"
                animation={{
                  opacity: 0,
                  type: 'from',
                  repeat: -1,
                  yoyo: true,
                  delay: 1200,
                  duration: 800,
                }}
                id="Rectangle-41-Copy"
                points="18.8792877 5.13990784 23.8127438 1.83683957 26.7573137 3.84766463 21.9604622 7.28170546"
              />
              <TweenOne
                component="polygon"
                animation={{
                  opacity: 0,
                  type: 'from',
                  repeat: -1,
                  yoyo: true,
                  delay: 300,
                  duration: 800,
                }}
                id="Rectangle-41-Copy-2"
                points="26.4002395 10.1861682 31.3061675 6.91095734 34.7951938 9.22241425 29.9359283 12.578476"
              />
            </g>
            <g id="饼图" transform="translate(10.000000, 74.000000)">
              <polygon
                id="Shape"
                fill="#FFFFFF"
                points="18.3849883 22.8338183 0.315631451 10.6188551 16.2289855 0.559473756 34.2983423 12.6717901"
              />
              <path
                d="M16.6745359,5.35947965 C14.7238667,4.94889265 12.7731975,4.94889265 11.2331955,5.35947965 C9.07719274,5.87271339 7.53719074,7.20712113 7.12652353,8.84946911 C6.51052273,11.8262248 9.79586034,15.008274 14.4158663,16.0347415 C19.0358723,17.061209 23.347878,15.418861 23.9638788,12.4421053 C24.5798796,9.56799635 21.294542,6.38594714 16.6745359,5.35947965 Z M15.2372007,12.9553391 C12.7731975,12.4421053 11.0278619,10.6971106 11.4385291,9.15740936 C11.7465295,7.61770812 14.0051991,6.79653413 16.4692023,7.30976788 C18.9332055,7.82300162 20.6785412,9.56799635 20.267874,11.1076976 C19.9598736,12.6473988 17.7012039,13.5712196 15.2372007,12.9553391 Z"
                id="Shape"
                fill="#DCE4EA"
              />
              <g id="Group" transform="translate(7.052261, 5.051539)">
                <path
                  d="M10.0810359,0.628076147 C8.13036673,0.217489151 6.17969753,0.217489151 4.63969553,0.628076147 C2.48369273,1.14130989 0.943690724,2.47571763 0.533023524,4.11806561 C-0.0829772768,7.09482133 3.20236033,10.2768705 7.82236633,11.303338 C12.4423723,12.3298055 16.7543779,10.6874575 17.3703787,7.71070182 C17.9863795,4.83659285 14.7010419,1.65454364 10.0810359,0.628076147 Z M8.64370073,8.22393557 C6.17969753,7.71070182 4.43436193,5.96570709 4.84502913,4.42600586 C5.15302953,2.88630462 7.41169913,2.06513063 9.87570234,2.57836438 C12.3397055,3.09159812 14.0850411,4.83659285 13.6743739,6.37629409 C13.3663735,7.91599532 11.1077039,8.83981606 8.64370073,8.22393557 Z"
                  id="Shape"
                  fill="url(#linearGradient-11)"
                />
                <g transform="translate(8.982173, 1.123016)" id="Shape" fill="#FFFFFF">
                  <polygon points="6.95087053 0.839467471 8.28553893 2.27652196 0.380195319 4.53475043" />
                  <polygon points="8.38820573 3.81622319 8.90153973 4.63739718 0.380195319 4.53475043" />
                  <polygon points="8.28553893 8.12738664 8.18287213 8.94856063 0.380195319 4.02151669" />
                </g>
              </g>
              <polygon
                id="Shape"
                fill="url(#linearGradient-12)"
                points="20.5517734 20.3753778 18.703771 21.6071388 16.0344342 19.7594973 17.8824366 18.5277363"
              />
              <polygon
                id="Shape"
                fill="#DCE4EA"
                points="32.3322028 13.3232413 21.1415215 20.5085137 20.5255207 19.99528 31.7162019 12.9126543"
              />
              <polygon
                id="Shape"
                fill="#DCE4EA"
                points="31.2094311 12.2002249 20.0187499 19.3854973 19.4027491 18.9749103 30.5934303 11.7896379"
              />
              <ellipse
                id="Oval"
                fill="url(#linearGradient-13)"
                cx="3.38250911"
                cy="11.282502"
                rx="1"
                ry="1"
              />
              <ellipse
                id="Oval"
                fill="url(#linearGradient-14)"
                cx="5.62805236"
                cy="10.1594855"
                rx="1"
                ry="1"
              />
              <ellipse
                id="Oval"
                fill="url(#linearGradient-15)"
                cx="16.8557686"
                cy="3.42138707"
                rx="1"
                ry="1"
              />
            </g>
          </g>
        </TweenOne>
        <TweenOne
          id="Group-53"
          fillRule="nonzero"
          component="g"
          style={{ transformOrigin: '50% 100%' }}
          animation={{
            opacity: 0,
            scale: 0,
            type: 'from',
            delay: 800,
            ease: 'easeOutBack',
          }}
        >
          <g transform="translate(278.000000, 324.000000)">
            <path
              d="M71.3341929,66.448191 C71.8475269,74.2493439 64.0448501,80.6134424 48.6448301,81.6399099 C33.2448101,82.7690241 17.7421233,76.1996322 17.2287893,68.501126 C16.7154552,60.8026198 26.0581341,54.1305812 41.4581541,53.0014669 C56.9608409,51.9749994 70.8208589,58.6470381 71.3341929,66.448191 Z"
              id="Shape"
              fill="#4BAA8A"
            />
            <g id="Group" transform="translate(0.367095, 0.115014)">
              <g>
                <ellipse
                  id="Oval"
                  fill="#1F154C"
                  transform="translate(24.025407, 9.415191) rotate(-7.562082) translate(-24.025407, -9.415191) "
                  cx="24.025407"
                  cy="9.41519093"
                  rx="7.69998787"
                  ry="8.10906985"
                />
                <path
                  d="M6.98134241,28.9463832 C6.98134241,28.9463832 4.92800641,25.1484535 3.2853376,24.532573 C1.7453356,23.9166925 0.410667201,24.6352197 0.3080004,25.1484535 C0.2053336,25.6616872 1.9506692,25.6616872 1.9506692,25.6616872 C1.9506692,25.6616872 0.821334401,27.406682 1.540002,28.5357962 C2.2586696,29.6649104 5.23600681,31.1019649 5.23600681,31.1019649 L6.98134241,28.9463832 Z"
                  id="Shape"
                  fill="#FFD3C2"
                />
                <path
                  d="M13.4493508,31.7178454 C13.4493508,31.7178454 14.7840192,30.4860844 16.0160208,27.817269 C17.3506892,25.1484535 18.5826908,21.5558173 20.8413604,19.4002355 C25.7693668,14.7811318 29.9787056,14.0626046 33.880044,14.9864253 C39.013384,16.2181863 51.2307333,38.1845906 46.7133941,44.8566293 C42.1960549,51.5286679 31.6213744,49.88632 29.6707052,45.1645695 C29.0547044,39.3137048 22.3813624,30.7940247 22.3813624,30.7940247 C22.3813624,30.7940247 16.8373552,39.2110581 12.5253496,37.4660633 C8.21334401,35.7210686 4.82533961,31.2046117 4.82533961,31.2046117 L6.77600881,27.9199157 L13.4493508,31.7178454 Z"
                  id="Shape"
                  fill="url(#linearGradient-16)"
                />
              </g>
              <path
                d="M34.393378,44.3433955 C34.393378,44.3433955 17.453356,39.9295853 15.5026868,46.9095642 C13.860018,52.6577822 18.8906912,61.3827558 18.8906912,61.3827558 L25.8720336,57.1742391 L34.393378,44.3433955 Z"
                id="Shape"
                fill="#1F154C"
              />
              <path
                d="M63.8587497,34.3866609 C56.7747405,24.7378665 46.0973933,24.94316 39.9373852,27.406682 C32.9560428,30.1781442 27.0013684,36.9528296 27.6173692,46.0883903 C26.7960348,46.2936838 12.0120156,43.5222215 14.4760188,58.8165871 C17.453356,76.8824149 49.1773973,83.5544536 63.6534161,71.3394905 C77.2054337,59.9457014 67.6574213,39.5189983 63.8587497,34.3866609 Z"
                id="Shape"
                fill="#CFED7D"
              />
            </g>
          </g>
        </TweenOne>
        <TweenOne
          id="Group-60"
          fillRule="nonzero"
          component="g"
          style={{ transformOrigin: '50% 100%' }}
          animation={{
            opacity: 0,
            scale: 0,
            type: 'from',
            delay: 900,
            ease: 'easeOutBack',
          }}
        >
          <g transform="translate(449.000000, 308.000000)">
            <path
              d="M44.3588616,109.00215 C44.6124618,112.298308 39.7940596,115.087364 30.2840553,115.721241 C20.9008511,116.355117 11.5176469,113.819611 11.2640468,110.650229 C11.0104467,107.354071 16.8432493,104.43824 26.2264535,103.804363 C35.7364578,103.170487 44.1052615,105.832768 44.3588616,109.00215 Z"
              id="Shape"
              fill="#D8D8D8"
              opacity="0.6"
            />
            <g id="Group" transform="translate(0.028382, 0.392784)">
              <path
                d="M32.7507092,97.1038245 L33.1613764,102.646749 C33.1613764,103.159983 32.9560428,103.673216 32.5453756,103.87851 C31.313374,104.597037 28.746704,106.239385 27.720036,106.855266 C26.5907012,107.67644 37.2680484,106.957912 37.2680484,106.957912 L36.7547144,97.2064712 L32.7507092,97.1038245 Z"
                id="Shape"
                fill="#C69675"
              />
              <path
                d="M36.7547144,108.702907 C37.1653816,108.60026 37.473382,108.189673 37.473382,107.779086 L37.1653816,105.007624 L34.5987116,105.315564 C33.9827108,105.418211 33.36671,105.212918 32.9560428,104.699684 L32.340042,104.083803 C32.1347084,103.775863 31.7240412,103.775863 31.4160408,104.083803 C31.1080404,104.49439 30.3893728,104.904977 28.8493708,105.110271 C26.5907012,105.110271 25.7693668,106.239385 25.5640332,107.060559 C25.3586996,107.779086 25.7693668,108.394967 26.4880344,108.60026 C28.1307032,109.010847 31.5187076,109.626728 36.7547144,108.702907 Z"
                id="Shape"
                fill="url(#linearGradient-17)"
              />
              <path
                d="M32.9560428,21.658464 C32.9560428,21.658464 33.9827108,17.4499473 37.6787156,18.784355 C41.3747205,20.1187628 45.6867261,34.1813674 45.1733921,37.3634166 C44.6600581,40.5454658 36.0360468,51.8366082 33.9827108,51.2207277 C31.9293748,50.707494 32.1347084,46.9095642 32.1347084,46.9095642 C32.1347084,46.9095642 38.8080504,40.5454658 39.7320516,36.2343024 C39.6293848,33.0522531 32.9560428,21.658464 32.9560428,21.658464 Z"
                id="Shape"
                fill="#C69675"
              />
              <path
                d="M36.8573812,30.1781442 C36.8573812,30.1781442 29.8760388,20.9399368 34.5987116,18.3737681 C38.6027168,16.2181863 41.7853877,22.1716978 42.9147225,25.8669807 C40.6560528,28.7410897 36.8573812,30.1781442 36.8573812,30.1781442 Z"
                id="Shape"
                fill="#F39A18"
              />
              <path
                d="M24.4346984,100.491167 L25.0506992,105.931445 C25.0506992,105.931445 20.8413604,108.805554 19.506692,109.729375 C18.3773572,110.550549 29.4653716,109.729375 29.4653716,109.729375 L28.4387036,100.183227 L24.4346984,100.491167 Z"
                id="Shape"
                fill="#C69675"
              />
              <path
                d="M19.4040252,52.9657224 C19.4040252,52.9657224 22.073362,87.6603236 23.5106972,101.107048 C26.7960348,103.057336 29.260038,100.799107 29.260038,100.799107 L28.746704,67.2336205 L29.5680384,66.7203868 L32.340042,98.3355854 C32.340042,98.3355854 35.0093788,100.08058 37.473382,97.8223517 C37.3707152,86.8391496 35.5227128,58.9192339 35.420046,51.8366082 C35.420046,49.9889667 30.4920396,53.8895432 27.6173692,54.1974834 C21.4573612,54.8133639 19.4040252,52.9657224 19.4040252,52.9657224 Z"
                id="Shape"
                fill="#1F154C"
              />
              <path
                d="M20.53336,31.6151987 C20.53336,31.6151987 21.560028,17.9631811 34.0853776,17.0393603 C44.1467241,16.3208331 34.906712,30.0754974 35.3173792,51.9392549 C30.1840392,58.0980599 19.3013584,53.9921899 19.3013584,53.9921899 C19.3013584,53.9921899 17.8640232,42.495754 20.53336,31.6151987 Z"
                id="Shape"
                fill="#FFBF00"
              />
              <path
                d="M40.1427188,9.95673464 C40.1427188,15.1917188 35.2147124,19.4002355 29.9787056,19.4002355 C24.7426988,19.4002355 21.046694,15.1917188 21.046694,9.95673464 C21.046694,4.72175045 25.3586996,0.410586996 30.5947064,0.410586996 C35.8307132,0.410586996 40.1427188,4.72175045 40.1427188,9.95673464 Z"
                id="Shape"
                fill="#C69675"
              />
              <path
                d="M24.3320316,24.532573 C24.3320316,24.532573 27.8227028,23.8140457 27.6173692,29.7675572 C27.3093688,35.7210686 24.126698,43.8301618 20.7386936,45.1645695 C16.5293548,46.8069175 7.49467641,37.2607699 7.49467641,37.2607699 C7.49467641,37.2607699 7.28934281,34.2840141 10.1640132,34.8998946 C12.2173492,37.7740036 18.8906912,38.9031178 18.8906912,38.9031178 L20.53336,32.1284324 C20.3280264,32.0257857 20.6360268,25.8669807 24.3320316,24.532573 Z"
                id="Shape"
                fill="#C69675"
              />
              <path
                d="M20.1226928,32.7443129 C20.1226928,32.7443129 20.6360268,21.8637575 25.8720336,23.1981653 C30.286706,24.3272795 28.5413704,30.7940247 26.8987016,34.2840141 C24.0240312,32.7443129 20.1226928,32.7443129 20.1226928,32.7443129 Z"
                id="Shape"
                fill="#F39A18"
              />
              <polygon
                id="Shape"
                fill="url(#linearGradient-18)"
                points="11.1906812 32.5390194 6.36534161 34.7972479 0.2053336 26.2775677 0.3080004 25.764334 5.03067321 23.5061055 11.1906812 32.0257857"
              />
              <polygon
                id="Shape"
                fill="#FFFFFF"
                points="11.1906812 32.0257857 6.36534161 34.2840141 0.3080004 25.764334 5.03067321 23.5061055"
              />
              <polygon
                id="Shape"
                fill="#81C8FB"
                points="9.65067921 30.5887312 5.95467441 32.3337259 1.6426688 26.4828612 5.44134041 24.7378665"
              />
              <path
                d="M3.9013384,31.4099052 C4.92800641,32.8469596 6.16000801,33.2575466 6.67334201,32.4363727 C7.18667601,31.7178454 5.95467441,29.4596169 6.77600881,29.0490299 C7.59734321,28.6384429 8.11067721,31.6151987 9.03467841,32.4363727 C10.0613464,33.3601934 9.95867961,33.9760739 10.0613464,35.1051881 C10.1640132,36.0290089 9.34267881,37.2607699 7.80267681,37.6713568 C7.80267681,37.6713568 4.51733921,36.7475361 3.9013384,36.0290089 C2.9773372,35.1051881 3.593338,33.4628401 3.1826708,32.7443129 C2.56667,31.7178454 2.6693368,31.1019649 2.9773372,30.0754974 L3.9013384,31.4099052 Z"
                id="Shape"
                fill="#C69675"
              />
              <path
                d="M23.10003,3.69528296 L26.3853676,4.92704395 L25.9747004,9.4435009 L27.0013684,10.7779086 C27.0013684,10.7779086 28.23337,9.13556065 29.1573712,9.95673464 C29.773372,10.5726151 30.9027068,13.4467241 28.8493708,14.1652513 C29.5680384,16.9367136 32.4427088,19.194942 32.4427088,19.194942 C32.4427088,19.194942 35.5227128,18.784355 37.6787156,16.4234798 C41.2720537,12.5229034 41.0667201,7.59585942 38.8080504,4.00322321 C36.5493808,0.410586996 28.3360368,-2.46352197 23.10003,3.69528296 Z"
                id="Shape"
                fill="#1F154C"
              />
              <path
                d="M28.9520376,111.063782 C29.4653716,110.961136 29.773372,110.550549 29.6707052,110.139962 L29.3627048,107.265853 L26.693368,107.573793 C26.0773672,107.67644 25.3586996,107.368499 24.9480324,106.957912 L24.3320316,106.342032 C24.126698,106.034092 23.7160308,106.034092 23.4080304,106.342032 C22.9973632,106.752619 22.2786956,107.265853 20.7386936,107.471146 C18.3773572,107.471146 17.5560228,108.60026 17.3506892,109.524081 C17.1453556,110.242608 17.5560228,110.961136 18.2746904,111.063782 C19.9173592,111.269076 23.4080304,111.884956 28.9520376,111.063782 Z"
                id="Shape"
                fill="url(#linearGradient-19)"
              />
            </g>
          </g>
        </TweenOne>
        <TweenOne
          id="Group"
          fillRule="nonzero"
          fill="#FE766E"
          component="g"
          animation={{ opacity: 0, type: 'from', delay: 600 }}
        >
          <g transform="translate(52.689998, 349.944392)">
            <path
              d="M3.4906712,15.7049526 L24.8453656,20.9399368 C25.7693668,21.1452303 26.3853676,21.9664043 26.3853676,22.890225 L26.3853676,22.9928718 C26.3853676,24.3272795 25.153366,25.2511002 23.9213644,24.94316 L2.56667,19.605529 C1.6426688,19.4002355 1.026668,18.5790616 1.026668,17.6552408 L1.026668,17.6552408 C1.026668,16.4234798 2.2586696,15.3970123 3.4906712,15.7049526 Z"
              id="Shape"
            />
            <path
              d="M3.1826708,26.4828612 L24.640032,31.8204922 C25.5640332,32.0257857 26.180034,32.8469596 26.180034,33.7707804 L26.180034,33.8734271 C26.180034,35.2078349 24.9480324,36.1316556 23.7160308,35.8237154 L2.3613364,30.4860844 C1.4373352,30.2807909 0.821334401,29.4596169 0.821334401,28.5357962 L0.821334401,28.5357962 C0.718667601,27.2013885 1.9506692,26.174921 3.1826708,26.4828612 Z"
              id="Shape"
            />
            <path
              d="M4.0040052,36.7475361 L25.3586996,41.9825203 C26.2827008,42.1878138 26.8987016,43.0089878 26.8987016,43.9328085 L26.8987016,44.0354553 C26.8987016,45.369863 25.6667,46.2936838 24.4346984,45.9857435 L3.080004,40.6481126 C2.1560028,40.4428191 1.540002,39.6216451 1.540002,38.6978243 L1.540002,38.6978243 C1.540002,37.4660633 2.7720036,36.4395959 4.0040052,36.7475361 Z"
              id="Shape"
            />
            <path
              d="M14.9893528,9.85408789 L14.9893528,9.85408789 C16.3240212,9.85408789 17.1453556,8.51968016 16.7346884,7.28791917 C15.40002,3.18204922 11.293348,-1.23176099 5.85200761,1.64234798 C-2.4640032,6.15880493 0.821334401,50.8101407 1.540002,58.3033534 C1.6426688,59.0218806 2.1560028,59.6377611 2.7720036,59.7404079 L2.7720036,59.7404079 C3.9013384,60.0483481 4.92800641,59.1245274 4.82533961,57.9954131 C4.0040052,48.4492655 1.4373352,8.62232691 7.39200961,4.8243972 C10.1640132,3.07940247 12.4226828,6.46674518 13.0386836,8.41703341 C13.4493508,9.34085415 14.1680184,9.95673464 14.9893528,9.85408789 Z"
              id="Shape"
            />
            <path
              d="M38.0893828,14.9864253 L38.0893828,14.9864253 C39.4240512,14.9864253 40.2453856,13.6520176 39.8347184,12.4202566 C38.50005,8.31438666 34.2907112,3.90057646 28.9520376,6.87733218 C20.6360268,11.3937891 24.126698,56.0451249 24.8453656,63.5383376 C24.9480324,64.2568648 25.4613664,64.8727453 26.0773672,64.9753921 L26.0773672,64.9753921 C27.206702,65.2833323 28.23337,64.3595116 28.1307032,63.2303973 C27.3093688,53.7868964 24.640032,13.8573111 30.5947064,10.0593814 C33.36671,8.31438666 35.6253796,11.5990826 36.2413804,13.6520176 C36.446714,14.4731916 37.2680484,14.9864253 38.0893828,14.9864253 Z"
              id="Shape"
            />
          </g>
        </TweenOne>
        <TweenOne
          id="Group-48"
          fillRule="nonzero"
          component="g"
          style={{ transformOrigin: '0% 50%' }}
          animation={{
            opacity: 0,
            scale: 0,
            type: 'from',
            delay: 400,
            ease: 'easeOutBack',
          }}
        >
          <g transform="translate(306.000000, 346.000000)">
            <ellipse
              id="Oval"
              fill="#529E88"
              cx="8.23906262"
              cy="63.3799082"
              rx="7.80267681"
              ry="3.28469596"
            />
            <ellipse
              id="Oval"
              fill="#3E6C5D"
              cx="8.23249945"
              cy="63.4764571"
              rx="6.67334201"
              ry="2.25822848"
            />
            <g id="Group" transform="translate(30.751220, 0.575342)">
              <polygon
                id="Shape"
                fill="#B3DFFF"
                points="85.5214445 2.25822848 5.95467441 52.0419017 0.3080004 50.5022005 79.8747705 0.718527242"
              />
              <polygon
                id="Shape"
                fill="#63A9DC"
                points="5.95467441 52.0419017 6.46800841 107.471146 0.821334401 105.931445 0.3080004 50.5022005"
              />
              <polygon
                id="Shape"
                fill="#81C8FB"
                points="85.8294449 57.6874729 6.26267481 107.471146 5.95467441 52.0419017 85.5214445 2.25822848"
              />
              <polygon
                id="Shape"
                fill="#4D96CB"
                points="69.0947565 57.8927664 14.373352 92.5873675 14.1680184 57.6874729 68.9920897 22.9928718"
              />
              <polygon
                id="Shape"
                fill="#FFFFFF"
                points="47.7400621 65.8992128 43.8387237 68.3627348 43.8387237 64.0515713 47.7400621 61.4854026"
              />
              <g transform="translate(19.506692, 31.820492)" id="Shape" fill="#FFFFFF">
                <polygon points="39.526718 13.6520176 35.93338 15.9102461 35.93338 6.46674518 39.526718 4.10586996" />
                <polygon points="3.9013384 49.6810265 0.3080004 52.0419017 0.2053336 28.9463832 3.7986716 26.6881547" />
                <polygon points="12.5253496 44.3433955 8.93201161 46.601624 8.93201161 23.6087522 12.5253496 21.247877" />
                <polygon points="44.5573913 5.23498419 40.040052 8.21173991 40.040052 3.90057646 44.5573913 0.821173991" />
                <polygon points="8.31601081 28.2278559 4.41467241 30.7940247 4.41467241 26.3802145 8.31601081 23.8140457" />
                <polygon points="8.31601081 46.9095642 4.41467241 49.475733 4.41467241 45.1645695 8.31601081 42.5984008" />
                <polygon points="23.8186976 36.7475361 20.2253596 39.1084113 20.1226928 16.1155396 23.7160308 13.7546644" />
                <polygon points="32.4427088 31.4099052 28.8493708 33.6681336 28.8493708 10.6752619 32.4427088 8.41703341" />
                <polygon points="28.23337 15.2943656 24.3320316 17.8605343 24.3320316 13.4467241 28.23337 10.9832021" />
                <polygon points="17.6586896 27.6119755 15.7080204 28.9463832 15.7080204 24.532573 17.6586896 23.1981653" />
                <polygon points="17.6586896 39.7242918 15.7080204 41.0586996 15.7080204 36.7475361 17.6586896 35.4131284" />
                <polygon points="40.3480524 26.0722742 36.0360468 28.9463832 35.93338 24.6352197 40.2453856 21.7611108" />
                <polygon points="44.6600581 23.6087522 41.0667201 25.8669807 41.0667201 17.9631811 44.6600581 15.6023058" />
                <polygon points="44.4547245 14.8837786 35.93338 20.6319965 35.93338 16.9367136 44.4547245 11.1884956" />
              </g>
              <ellipse
                id="Oval"
                fill="#63A9DC"
                transform="translate(76.302438, 46.753845) rotate(-85.184666) translate(-76.302438, -46.753845) "
                cx="76.3024382"
                cy="46.7538455"
                rx="8.72693698"
                ry="4.82454037"
              />
              <ellipse
                id="Oval"
                fill="#F49F05"
                transform="translate(76.965422, 46.900158) rotate(-85.184666) translate(-76.965422, -46.900158) "
                cx="76.9654223"
                cy="46.9001575"
                rx="7.90557821"
                ry="4.41394119"
              />
              <ellipse
                id="Oval"
                fill="#63A9DC"
                transform="translate(76.301822, 26.224454) rotate(-85.184666) translate(-76.301822, -26.224454) "
                cx="76.3018222"
                cy="26.2244542"
                rx="8.72693698"
                ry="4.82454037"
              />
              <ellipse
                id="Oval"
                fill="#D75149"
                transform="translate(76.998840, 26.031638) rotate(-85.184666) translate(-76.998840, -26.031638) "
                cx="76.9988404"
                cy="26.0316377"
                rx="7.90557821"
                ry="4.41394119"
              />
              <ellipse
                id="Oval"
                fill="#FE766E"
                transform="translate(78.963061, 26.201559) rotate(-85.184666) translate(-78.963061, -26.201559) "
                cx="78.9630609"
                cy="26.2015586"
                rx="7.08421943"
                ry="3.90069221"
              />
              <path
                d="M82.6467741,47.1148577 C82.3387737,50.9127875 80.3881045,53.8895432 78.2321017,53.6842497 C76.0760989,53.4789562 74.6387637,50.296907 74.9467641,46.4989773 C75.2547645,42.7010475 77.2054337,39.7242918 79.3614365,39.9295853 C81.5174393,40.0322321 82.9547745,43.316928 82.6467741,47.1148577 Z"
                id="Shape"
                fill="#FFBF00"
              />
            </g>
            <ellipse
              id="Oval"
              fill="#FFFFFF"
              cx="32.9137857"
              cy="79.4161761"
              rx="3.2853376"
              ry="4.72175045"
            />
            <g id="Group" transform="translate(4.927472, 52.234097)">
              <path
                d="M6.57067521,10.8805554 C6.57067521,6.15880493 9.13734521,6.56939193 9.13734521,6.56939193 C13.4493508,6.56939193 11.7040152,14.5758383 13.0386836,24.532573 C14.2706852,33.2575466 27.6173692,30.1781442 27.6173692,30.1781442 C27.6173692,30.1781442 29.260038,29.3569702 29.1573712,27.2013885 C28.9520376,23.5061055 26.5907012,24.2246327 26.5907012,24.2246327 C24.126698,24.7378665 21.560028,25.1484535 20.4306932,23.4034587 C18.5826908,20.6319965 19.4040252,13.5493709 18.5826908,8.82762041 C17.1453556,0.410586996 10.8826808,-1.02646749 5.64667401,0.821173991 C-0.2053336,2.77146222 0.410667201,10.4699684 0.410667201,10.4699684 C0.410667201,10.4699684 0.1026668,11.9070229 4.0040052,11.9070229 C6.87867561,12.1123164 6.57067521,10.8805554 6.57067521,10.8805554 Z"
                id="Shape"
                fill="#FFBF00"
              />
              <path
                d="M6.98134241,8.21173991 C6.98134241,8.21173991 6.98134241,8.21173991 6.77600881,8.62232691 C2.4640032,8.93026715 0.924001201,6.46674518 0.924001201,6.46674518 C0.924001201,6.46674518 1.026668,5.95351144 1.1293348,5.64557119 C1.4373352,6.15880493 2.7720036,8.41703341 6.98134241,8.21173991 Z"
                id="Shape"
                fill="#F39A18"
              />
              <path
                d="M7.90534361,6.87733218 C7.90534361,6.87733218 7.80267681,6.87733218 7.49467641,7.18527242 C3.3880044,5.95351144 2.6693368,2.87410897 2.6693368,2.87410897 C2.6693368,2.87410897 2.9773372,2.56616872 3.1826708,2.36087522 C3.2853376,2.87410897 4.0040052,5.64557119 7.90534361,6.87733218 Z"
                id="Shape"
                fill="#F39A18"
              />
              <path
                d="M7.39200961,0.410586996 C7.39200961,0.410586996 6.26267481,3.59263621 8.82934481,6.56939193 C9.24001201,6.56939193 9.34267881,6.56939193 9.34267881,6.56939193 C9.34267881,6.56939193 6.67334201,3.59263621 7.90534361,0.307940247 C7.70001001,0.410586996 7.39200961,0.410586996 7.39200961,0.410586996 Z"
                id="Shape"
                fill="#F39A18"
              />
              <path
                d="M14.1680184,1.33440774 C14.1680184,1.33440774 10.5746804,3.38734271 10.9853476,7.28791917 C11.1906812,7.59585942 11.293348,7.80115292 11.293348,7.80115292 C11.293348,7.80115292 10.8826808,4.00322321 14.6813524,1.53970123 C14.4760188,1.43705448 14.1680184,1.33440774 14.1680184,1.33440774 Z"
                id="Shape"
                fill="#F39A18"
              />
              <path
                d="M18.3773572,7.39056592 C18.3773572,7.39056592 14.2706852,6.46674518 11.9093488,9.54614765 C12.0120156,9.85408789 12.0120156,10.0593814 12.0120156,10.0593814 C12.0120156,10.0593814 14.0653516,7.08262567 18.480024,7.80115292 C18.3773572,7.69850617 18.3773572,7.39056592 18.3773572,7.39056592 Z"
                id="Shape"
                fill="#F39A18"
              />
              <path
                d="M18.993358,11.4964359 C18.8906912,11.4964359 15.5026868,9.95673464 12.2173492,12.6255501 C12.2173492,12.9334904 12.2173492,13.2414306 12.2173492,13.2414306 C12.2173492,13.2414306 14.6813524,10.7779086 18.993358,12.0096696 C18.993358,11.7017294 18.993358,11.4964359 18.993358,11.4964359 Z"
                id="Shape"
                fill="#F39A18"
              />
              <path
                d="M19.0960248,16.1155396 C18.993358,16.1155396 15.5026868,14.5758383 12.320016,16.6287733 C12.320016,16.8340668 12.320016,17.2446538 12.320016,17.2446538 C12.320016,17.2446538 14.886686,15.0890721 19.0960248,16.6287733 C19.1986916,16.3208331 19.0960248,16.1155396 19.0960248,16.1155396 Z"
                id="Shape"
                fill="#F39A18"
              />
              <path
                d="M20.4306932,23.4034587 C20.3280264,23.4034587 18.480024,25.6616872 13.2440172,25.4563937 C13.2440172,25.6616872 13.346684,25.9696275 13.346684,25.9696275 C13.346684,25.9696275 17.3506892,26.6881547 20.6360268,23.8140457 C20.53336,23.6087522 20.4306932,23.4034587 20.4306932,23.4034587 Z"
                id="Shape"
                fill="#F39A18"
              />
              <path
                d="M22.6893628,24.7378665 C22.6893628,24.8405132 23.613364,27.817269 19.506692,30.4860844 C19.8146924,30.5887312 20.2253596,30.5887312 20.2253596,30.5887312 C20.2253596,30.5887312 23.9213644,28.6384429 23.2026968,24.7378665 C22.8946964,24.7378665 22.6893628,24.7378665 22.6893628,24.7378665 Z"
                id="Shape"
                fill="#F39A18"
              />
              <path
                d="M19.506692,20.426703 C19.4040252,20.426703 15.8106872,18.784355 12.6280164,20.9399368 C12.6280164,21.1452303 12.6280164,21.5558173 12.6280164,21.5558173 C12.6280164,21.5558173 15.1946864,19.4002355 19.506692,21.0425835 C19.6093588,20.6319965 19.506692,20.426703 19.506692,20.426703 Z"
                id="Shape"
                fill="#F39A18"
              />
            </g>
          </g>
        </TweenOne>
        <TweenOne
          id="Group-Copy-2"
          fillRule="nonzero"
          component="g"
          style={{ transformOrigin: '50% 50%' }}
          animation={[
            {
              opacity: 0,
              scale: 0,
              type: 'from',
              delay: 800,
              ease: 'easeOutBack',
            },
            {
              y: 15,
              yoyo: true,
              repeat: -1,
              duration: 3500,
              ease: 'easeInOutQuad',
            },
          ]}
        >
          <g transform="translate(246.695065, 49.097699)">
            <path
              d="M52.5680868,14.373882 C50.6764171,6.80868096 42.3530703,0.189130027 33.0838887,0.56739008 C27.4088795,0.756520107 20.5988686,5.48477077 17.572197,10.5912815 C9.81635117,8.69998123 -0.776999248,15.3195322 0.358002583,26.4782037 C1.11467047,34.7999249 8.3030154,38.3933954 17.0046961,38.2042654 C20.7880355,38.0151354 24.193041,36.6912252 26.8413786,34.4216649 C29.3005493,41.0412158 36.8672281,45.7694665 45.5689088,45.3912064 C55.9730923,45.0129464 64.296439,37.4477453 63.9181051,28.558634 C63.7289381,22.3173431 58.9997638,16.8325724 52.5680868,14.373882 Z"
              id="Shape"
              fill="url(#linearGradient-20)"
            />
            <path
              d="M47.4605785,22.1282131 C46.3255767,21.7499531 45.5689088,20.993433 45.1905749,19.8586528 C43.1097382,12.4825818 35.9213933,7.56520107 28.1655474,8.32172117 C24.003874,8.69998123 20.4097016,10.7804115 17.761364,13.6173619 C17.0046961,14.5630121 15.6805273,14.9412721 14.5455255,14.5630121 C13.4105236,14.184752 12.4646888,14.184752 11.14052,14.184752 C4.8980099,14.373882 -0.209498333,19.8586528 0.168835611,26.4782037 C0.358002583,31.5847145 3.5738411,35.745575 7.73551448,37.4477453 C7.73551448,37.4477453 43.1097382,45.7694665 44.0555731,45.7694665 C50.865584,45.5803364 56.1622592,39.9064356 55.9730923,33.2868847 C55.9730923,27.9912439 52.3789198,23.6412533 47.4605785,22.1282131 Z"
              id="Shape"
              fill="#FFFFFF"
            />
          </g>
        </TweenOne>
        <TweenOne
          id="Group-Copy-3"
          fillRule="nonzero"
          component="g"
          style={{ transformOrigin: '50% 50%' }}
          animation={[
            {
              opacity: 0,
              scale: 0,
              type: 'from',
              delay: 1200,
              ease: 'easeOutBack',
            },
            {
              y: -15,
              yoyo: true,
              repeat: -1,
              duration: 4000,
              ease: 'easeInOutQuad',
            },
          ]}
        >
          <g transform="translate(450.151154, 120.403402)">
            <path
              d="M30.9223598,8.45521263 C29.8096145,4.00510072 24.9135351,0.111252798 19.4610832,0.333758393 C16.1228473,0.445011191 12.1169642,3.22633114 10.3365717,6.23015668 C5.77431596,5.1176287 -0.457057728,9.01147662 0.210589453,15.5753917 C0.655687574,20.4705148 4.88411972,22.584318 10.0027481,22.4730652 C12.2282387,22.3618124 14.2311803,21.5830428 15.7890237,20.2480092 C17.2355926,24.1418571 21.6865738,26.9231771 26.8052022,26.7006715 C32.9253013,26.4781659 37.8213806,22.028054 37.5988316,16.7991725 C37.4875571,13.1278301 34.7056938,9.90149901 30.9223598,8.45521263 Z"
              id="Shape"
              fill="url(#linearGradient-20)"
            />
            <path
              d="M27.9179475,13.0165773 C27.2503003,12.7940717 26.8052022,12.3490606 26.5826531,11.6815438 C25.3586333,7.34268466 21.1302011,4.45011191 16.5679454,4.8951231 C14.1199057,5.1176287 12.0056897,6.34140948 10.4478462,8.01020144 C10.0027481,8.56646543 9.2238264,8.78897103 8.55617922,8.56646543 C7.88853203,8.34395984 7.33215938,8.34395984 6.55323767,8.34395984 C2.88117818,8.45521263 -0.123234137,11.6815438 0.0993149232,15.5753917 C0.210589453,18.5792172 2.10225647,21.0267788 4.55029613,22.028054 C4.55029613,22.028054 25.3586333,26.9231771 25.9150059,26.9231771 C29.920889,26.8119243 33.0365759,23.4743403 32.9253013,19.5804924 C32.9253013,16.4654141 30.8110852,13.9065997 27.9179475,13.0165773 Z"
              id="Shape"
              fill="#FFFFFF"
            />
          </g>
        </TweenOne>
        <TweenOne
          id="Group"
          fillRule="nonzero"
          component="g"
          style={{ transformOrigin: '50% 100%' }}
          animation={{
            opacity: 0,
            scale: 0,
            type: 'from',
            delay: 600,
            ease: 'easeOutBack',
          }}
        >
          <g transform="translate(301.723213, 207.321307)">
            <path
              d="M42.6300383,100.901754 C41.8087039,100.901754 40.8847027,100.901754 40.0633683,101.004401 C37.7020319,99.2594062 33.0820259,98.1302919 27.7433523,98.1302919 C20.0433423,98.1302919 13.7806675,100.593814 13.7806675,103.57057 C13.7806675,106.547325 20.0433423,109.010847 27.7433523,109.010847 C28.5646867,109.010847 29.4886879,109.010847 30.3100223,108.908201 C32.6713587,110.653195 37.2913647,111.78231 42.6300383,111.78231 C50.3300483,111.78231 56.5927231,109.318788 56.5927231,106.342032 C56.5927231,103.365276 50.3300483,100.901754 42.6300383,100.901754 Z"
              id="Shape"
              fill="#4BAA8A"
            />
            <g>
              <path
                d="M42.4247047,26.585508 C42.4247047,26.585508 49.3033803,37.2607699 48.2767123,41.3666398 C47.2500443,45.4725098 40.3713687,51.118081 40.3713687,51.118081 L37.2913647,48.7572057 C37.2913647,48.7572057 43.8620399,42.1878138 43.3487059,39.4163516 C42.8353719,36.6448894 38.7286999,30.1781442 38.7286999,30.1781442 C38.7286999,30.1781442 39.0367003,25.764334 42.4247047,26.585508 Z"
                id="Shape"
                fill="#F9D5A5"
              />

              <TweenOne
                d="M24.0473475,24.532573 C24.0473475,24.532573 15.5260031,30.0754974 13.9860011,28.2278559 C11.3166643,24.94316 6.79932508,18.2711213 6.79932508,18.2711213 L4.02732147,19.7081758 C4.02732147,19.7081758 9.16066148,34.6946011 14.6020019,33.8734271 C20.1460091,32.9496064 26.1006835,29.8702039 26.1006835,29.8702039 C26.1006835,29.8702039 27.7433523,25.9696275 24.0473475,24.532573 Z"
                id="Shape"
                fill="#F9D5A5"
                animation={{
                  d: 'M26.0473475,23.532573 C26.0473475,23.532573 20.2767874,30.8702039 16.2458897,30.8702039 C11.2767874,30.8702039 6.79932508,29.2711213 6.79932508,29.2711213 L5.02732147,32.7081758 C5.02732147,32.7081758 12.335447,36.4998665 17.7767874,35.6786925 C23.3207946,34.7548718 28.1006835,29.8702039 28.1006835,29.8702039 C28.1006835,29.8702039 29.7433523,24.9696275 26.0473475,23.532573 Z',
                  yoyo: true,
                  repeat: -1,
                  style: { x: -2 },
                  duration: 1000,
                }}
                component="path"
                attr="attr"
              />

              <path
                d="M24.5606815,30.4860844 C24.5606815,30.4860844 23.8420139,33.1548999 24.7660151,35.1051881 C25.6900163,37.0554764 26.4086839,42.8036943 26.4086839,42.8036943 C26.4086839,42.8036943 26.1006835,44.959276 27.0246847,45.2672163 C27.0246847,45.2672163 32.2606915,49.065146 38.4206995,44.8566293 C38.7286999,43.316928 38.0100323,41.0586996 37.7020319,40.3401723 C37.0860311,38.3898841 38.5233663,36.4395959 39.4473675,32.0257857 C39.4473675,31.8204922 40.6793691,33.4628401 40.6793691,33.2575466 C40.6793691,33.2575466 43.1433723,31.9231389 44.8887079,29.5622637 C43.9647067,28.1252092 43.4513727,26.996095 42.8353719,26.0722742 C41.2953699,23.5061055 40.0633683,23.4034587 37.0860311,23.0955185 C34.1086939,22.6849315 26.9220179,22.2743445 26.5113507,22.2743445 C26.5113507,22.2743445 24.3553479,22.069051 23.0206795,24.6352197 C22.6100123,25.353747 22.1993451,25.5590405 21.2753439,26.174921 C21.1726771,26.174921 23.8420139,27.6119755 24.5606815,30.4860844 Z"
                id="Shape"
                fill="#6664B3"
              />
              <path
                d="M29.2833543,22.479638 C30.2073555,22.3769913 29.2833543,18.0658278 29.2833543,18.0658278 L33.4926931,17.9631811 C33.4926931,17.9631811 34.0060271,21.0425835 34.6220279,22.5822848 C34.8273615,23.1981653 35.9566963,22.9928718 36.4700303,23.1981653 C37.0860311,23.9166925 35.2380287,28.0225624 30.5153559,27.7146222 C25.8953499,27.406682 27.5380187,22.3769913 27.5380187,22.3769913 C27.5380187,22.3769913 28.5646867,22.479638 29.2833543,22.479638 Z"
                id="Shape"
                fill="#E9B774"
              />
              <ellipse
                id="Oval"
                fill="#F9D5A5"
                transform="translate(31.720561, 11.843177) rotate(-82.461390) translate(-31.720561, -11.843177) "
                cx="31.7205615"
                cy="11.8431766"
                rx="8.00836035"
                ry="6.56967901"
              />
              <path
                d="M33.2873595,5.13233744 C33.2873595,5.13233744 32.4660251,2.36087522 36.3673635,0.821173991 C39.0367003,-0.205293498 43.6567063,4.4138102 42.4247047,8.72497366 C41.6033703,11.8043761 45.2993751,12.1123164 44.8887079,16.7314201 C44.5807075,21.3505238 40.9873695,24.0193392 40.6793691,19.8108225 C40.3713687,15.6023058 37.8046987,17.4499473 37.8046987,12.0096696 C37.8046987,5.33763094 35.5460291,7.59585942 35.5460291,7.59585942 C35.5460291,7.59585942 32.1580247,6.36409843 33.2873595,5.13233744 Z"
                id="Shape"
                fill="#5B2F16"
              />
              <ellipse
                id="Oval"
                fill="#F4333C"
                transform="translate(34.785604, 5.526731) rotate(-4.404644) translate(-34.785604, -5.526731) "
                cx="34.7856041"
                cy="5.52673115"
                rx="3.07986026"
                ry="3.07925875"
              />
              <path
                d="M30.6180227,8.21173991 C30.6180227,8.21173991 26.4086839,6.97997893 25.1766823,11.0858489 C25.2793491,6.26145168 28.9753539,2.77146222 33.4926931,3.59263621 C38.5233663,4.10586996 40.7820359,9.75144115 38.3180327,15.3970123 C38.7286999,18.0658278 35.3406955,22.7875783 35.8540295,19.194942 C36.4700303,15.6023058 36.0593631,13.7546644 33.5953599,12.5229034 C31.3366903,11.2911424 30.6180227,8.21173991 30.6180227,8.21173991 Z"
                id="Shape"
                fill="#7A4A2A"
              />
              <g transform="translate(17.579339, 53.376309)" id="Shape">
                <path
                  d="M5.74934081,42.5984008 C5.13334001,37.8766503 3.1826708,21.658464 3.4906712,18.5790616 C4.10667201,13.4467241 6.77600881,0.513233744 6.77600881,0.513233744 C6.77600881,0.513233744 11.3960148,0.92382074 13.346684,3.69528296 C14.5786856,5.44027769 9.44534561,20.1187628 9.44534561,20.1187628 C9.44534561,20.1187628 10.4720136,24.2246327 10.0613464,29.2543234 C9.65067921,34.1813674 8.52134441,43.8301618 8.52134441,43.8301618 C8.52134441,43.8301618 6.16000801,45.369863 5.74934081,42.5984008 Z"
                  fill="#F9D5A5"
                />
                <path
                  d="M8.41867761,41.4692866 C8.41867761,41.4692866 8.93201161,43.4195748 9.13734521,45.369863 C9.34267881,47.3201512 7.70001001,46.7042707 6.98134241,48.243972 C6.26267481,49.88632 4.72267281,51.118081 2.1560028,50.1942602 C-0.410667201,49.2704395 3.7986716,47.9360317 4.51733921,46.0883903 C6.26267481,41.9825203 6.46800841,41.5719333 8.41867761,41.4692866 Z"
                  fill="#F9D5A5"
                />
                <path
                  d="M3.9013384,46.8069175 C3.9013384,46.8069175 4.41467241,47.7307382 5.54400721,47.5254447 C7.90534361,47.1148577 8.41867761,43.727515 8.93201161,44.0354553 C9.54801241,44.3433955 10.1640132,47.6280915 8.93201161,48.4492655 C8.11067721,48.9624992 6.87867561,49.065146 5.95467441,50.5022005 C5.03067321,51.9392549 1.9506692,51.7339614 0.718667601,50.707494 C-0.513334001,49.6810265 2.9773372,48.0386785 3.9013384,46.8069175 Z"
                  fill="url(#linearGradient-21)"
                />
              </g>
              <g transform="translate(30.926023, 56.455712)" id="Shape">
                <path
                  d="M9.65067921,41.6745801 C7.80267681,37.2607699 1.9506692,22.069051 1.540002,18.8870018 C0.821334401,13.7546644 0.2053336,0.615880493 0.2053336,0.615880493 C0.2053336,0.615880493 4.72267281,-0.102646749 7.39200961,2.05293498 C9.03467841,3.48998946 7.70001001,18.8870018 7.70001001,18.8870018 C7.70001001,18.8870018 9.65067921,22.6849315 10.4720136,27.5093287 C11.293348,32.4363727 12.6280164,41.9825203 12.6280164,41.9825203 C12.6280164,41.9825203 10.6773472,44.2407488 9.65067921,41.6745801 Z"
                  fill="#F9D5A5"
                />
                <path
                  d="M12.1146824,39.8269386 C12.1146824,39.8269386 12.83335,41.6745801 13.2440172,43.6248683 C13.6546844,45.5751565 11.9093488,45.0619228 11.4986816,46.7042707 C10.9853476,48.3466187 9.54801241,49.7836732 6.98134241,49.1677927 C4.41467241,48.5519122 8.31601081,46.7042707 8.82934481,44.8566293 C9.95867961,40.5454658 10.1640132,40.1348788 12.1146824,39.8269386 Z"
                  fill="#F9D5A5"
                />
                <path
                  d="M8.21334401,45.6778033 C8.21334401,45.6778033 8.82934481,46.601624 9.95867961,46.2936838 C12.2173492,45.6778033 12.320016,42.1878138 12.9360168,42.3931073 C13.5520176,42.5984008 14.5786856,45.78045 13.4493508,46.8069175 C12.7306832,47.3201512 11.4986816,47.6280915 10.780014,49.1677927 C10.0613464,50.707494 6.87867561,50.9127875 5.54400721,49.88632 C4.20933881,48.8598525 7.49467641,46.9095642 8.21334401,45.6778033 Z"
                  fill="url(#linearGradient-22)"
                />
              </g>
              <path
                d="M26.2033503,44.548689 L38.2153659,44.8566293 C38.2153659,44.8566293 42.7327051,50.8101407 40.3713687,56.8662989 C40.8847027,61.4854026 40.8847027,65.385979 40.8847027,65.385979 C40.8847027,65.385979 30.9260231,66.7203868 21.3780107,63.6409843 C24.0473475,53.4789562 26.2033503,44.548689 26.2033503,44.548689 Z"
                id="Shape"
                fill="#FD6F8D"
              />
              <g transform="translate(17.579339, 34.899895)">
                <path
                  d="M26.180034,17.2446538 L0.410667201,17.2446538 L0.410667201,1.74499473 C0.410667201,1.12911424 0.821334401,0.718527242 1.4373352,0.718527242 L25.153366,0.718527242 C25.7693668,0.718527242 26.180034,1.12911424 26.180034,1.74499473 L26.180034,17.2446538 Z"
                  id="Shape"
                  fill="#E6E6E6"
                />
                <rect
                  id="Rectangle-path"
                  fill="#BFBFBF"
                  x="0.3080004"
                  y="17.0393603"
                  width="25.7693668"
                  height="1"
                />
                <ellipse
                  id="Oval"
                  fill="#BFBFBF"
                  opacity="0.7"
                  cx="13.2440172"
                  cy="9.6487944"
                  rx="1.3346684"
                  ry="1.33440774"
                />
              </g>
              <TweenOne
                id="Group-62"
                style={{ transformOrigin: '50%' }}
                animation={{
                  x: -2,
                  y: 11,
                  rotate: -15,
                  yoyo: true,
                  repeat: -1,
                  duration: 1000,
                }}
                component="g"
              >
                <g transform="translate(0.000000, 11.000000)">
                  <path
                    d="M6.18332428,11.426703 C6.18332428,11.426703 7.62065948,10.4002355 7.41532588,8.24465382 C7.31265908,6.19171884 6.08065748,4.95995785 6.28599108,3.00966962 C6.49132468,1.36732164 4.54065548,2.90702287 5.15665628,5.0626046 C3.10332027,2.08584888 0.947317471,0.34085415 0.22864987,1.05938139 C-0.490017731,1.67526189 1.97398547,2.80437612 2.58998627,4.44672411 C1.66598507,4.54937085 1.87131867,5.67848509 1.87131867,5.67848509 C1.87131867,5.67848509 0.741983871,5.67848509 1.04998427,7.11553958 C0.22864987,7.32083308 0.53665027,8.55259406 0.947317471,9.16847456 C3.71932107,12.8637575 6.18332428,11.426703 6.18332428,11.426703 Z"
                    id="Shape"
                    fill="#F9D5A5"
                  />
                  <path
                    d="M5.15665628,5.0626046 C5.15665628,5.0626046 4.12998827,6.80759933 4.23265507,8.34730056 C3.71932107,8.03936032 3.92465467,6.91024608 4.12998827,6.60230583 C4.33532187,6.08907209 3.92465467,5.2678981 3.61665427,4.95995785 C4.12998827,4.95995785 4.74598908,4.8573111 4.74598908,4.6520176 C4.95132268,4.8573111 5.15665628,5.0626046 5.15665628,5.0626046 Z"
                    id="Shape"
                    fill="#F9BF98"
                  />
                  <path
                    d="M1.04998427,8.65524081 C1.04998427,8.65524081 1.76865187,9.37376805 2.17931907,9.06582781 C2.69265307,8.75788756 2.17931907,7.93671357 2.17931907,7.93671357 C2.17931907,7.93671357 2.79531987,8.55259406 3.20598707,8.03936032 C3.61665427,7.52612657 3.41132067,7.11553958 3.20598707,6.80759933 C3.71932107,7.01289283 3.71932107,7.01289283 4.02732147,6.70495258 C4.12998827,7.42347982 4.02732147,9.06582781 4.74598908,9.8870018 C3.92465467,9.16847456 3.20598707,8.96318106 3.00065347,9.16847456 C2.89798667,9.2711213 1.66598507,9.57906155 1.04998427,8.65524081 Z"
                    id="Shape"
                    fill="#F9BF98"
                  />
                </g>
              </TweenOne>
            </g>
          </g>
        </TweenOne>
        <TweenOne
          id="Group-40"
          fillRule="nonzero"
          component="g"
          animation={[
            { opacity: 0, type: 'from', delay: 900 },
            {
              y: 10,
              yoyo: true,
              repeat: -1,
              duration: 4000,
              ease: 'easeInOutQuad',
            },
          ]}
        >
          <g transform="translate(52.332192, 0.222821)">
            <TweenOne
              id="Shape"
              component="polygon"
              style={{ transformOrigin: '50% 50%' }}
              animation={{
                opacity: 0,
                yoyo: true,
                repeat: -1,
                duration: 300,
                ease: 'easeInOutQuad',
              }}
              fill="url(#linearGradient-23)"
              opacity="0.5"
              points="122.212227 175.502316 176.830965 138.754779 177.036299 11.2675173 122.828228 0.900195695 73.8561644 135.778024 73.8561644 166.674695"
            />
            <polygon
              id="Shape"
              fill="#8689BF"
              points="123.616844 175.677467 121.563508 177.011875 72.1807776 166.233966 71.1541096 136.261115 73.7207796 135.029354 123.616844 144.678149"
            />
            <polygon
              id="Shape"
              fill="#F4F4F4"
              points="121.460842 177.50963 71.1541096 167.655542 71.1541096 139.530333 121.460842 149.487067"
            />
            <polygon
              id="Shape"
              fill="#E4E8EF"
              points="121.460842 149.455296 71.1541096 139.498561 71.1541096 136.829746 121.460842 146.683833"
            />
            <polygon
              id="Shape"
              fill="#FFBF00"
              points="118.278547 160.920775 90.9691781 155.172557 90.9691781 145.831703 118.278547 151.477274"
            />
            <polygon
              id="Shape"
              fill="#FE766E"
              points="118.278547 166.954122 90.9691781 161.205904 90.9691781 158.434442 118.278547 164.080013"
            />
            <polygon
              id="Shape"
              fill="#B1DEFF"
              points="93.5358481 166.42541 90.9691781 165.80953 90.9691781 162.935421 93.5358481 163.551301"
            />
            <polygon
              id="Shape"
              fill="#FE766E"
              points="97.1385878 167.222959 94.5719178 166.709725 94.5719178 163.835616 97.1385878 164.34885"
            />
            <polygon
              id="Shape"
              fill="#FFBF00"
              points="100.741328 168.123155 98.1746575 167.507274 98.1746575 164.735812 100.741328 165.249046"
            />
            <polygon
              id="Shape"
              fill="#69C6B3"
              points="104.344067 169.125997 101.777397 168.510117 101.777397 165.636008 104.344067 166.251888"
            />
            <polygon
              id="Shape"
              fill="#B1DEFF"
              points="116.878819 171.771188 105.380137 169.307666 105.380137 166.536204 116.878819 168.999725"
            />
            <path
              d="M81.4722179,144.948147 C77.7762131,145.358734 75.2095431,149.875191 75.7228771,155.212822 C76.2362111,160.550453 79.6242155,164.451029 83.4228871,164.143089 C87.1188919,163.732502 89.6855619,159.216045 89.1722279,153.878414 C88.5562271,148.64343 85.1682227,144.640206 81.4722179,144.948147 Z M83.1148867,162.090154 C80.2402163,162.398094 77.4682127,159.216045 77.1602123,155.110175 C76.7495451,151.004305 78.8028811,147.411669 81.6775515,147.103728 C84.5522219,146.795788 87.3242255,149.977837 87.6322259,154.083707 C88.0428931,158.189577 85.9895571,161.782214 83.1148867,162.090154 Z"
              id="Shape"
              fill="#E4E8EF"
            />
            <path
              d="M80.5715329,145.848342 C76.8755281,146.258929 74.3088581,150.775386 74.8221921,156.113017 C75.3355261,161.450648 78.7235305,165.351225 82.5222021,165.043284 C86.2182069,164.632697 88.7848769,160.11624 88.2715429,154.77861 C87.7582089,149.543625 84.3702045,145.540402 80.5715329,145.848342 Z M82.2142017,162.990349 C79.3395313,163.29829 76.5675277,160.11624 76.2595273,156.010371 C75.8488601,151.904501 77.9021961,148.311864 80.7768665,148.003924 C83.6515369,147.695984 86.4235405,150.878033 86.7315409,154.983903 C87.2448749,159.089773 85.1915389,162.682409 82.2142017,162.990349 Z"
              id="Shape"
              fill="#FFCA45"
            />
            <ellipse
              id="Oval"
              fill="#E4E8EF"
              transform="translate(82.468335, 154.416260) rotate(-2.864109) translate(-82.468335, -154.416260) "
              cx="82.4683352"
              cy="154.41626"
              rx="2.1561089"
              ry="3.079554"
            />
            <ellipse
              id="Oval"
              fill="#F4333C"
              transform="translate(81.567650, 156.216652) rotate(-2.864109) translate(-81.567650, -156.216652) "
              cx="81.5676503"
              cy="156.216652"
              rx="2.1561089"
              ry="3.079554"
            />
            <g id="Group" transform="translate(0.000000, 148.532290)">
              <path
                d="M52.3587604,5.54908372 C53.632978,7.97680785 54.3280058,11.3293793 53.2854641,13.1790738 C52.8221122,13.8727093 51.0845428,14.219527 50.157839,14.3351329 C45.9876723,14.9131625 42.8600472,14.7975566 41.1224777,11.5605911 C38.6898805,7.05196056 41.2383157,4.39302461 43.439237,3.12135959 C46.6827001,1.27166502 50.5053529,2.08090639 52.3587604,5.54908372 Z"
                id="Shape"
                fill="#E5A383"
              />
              <path
                d="M39.8482601,8.09241376 C39.8482601,8.09241376 39.1532323,4.39302461 42.8600472,3.00575368 C44.7134547,2.19651231 47.2618899,1.96530048 48.7677834,0.809241376 C50.8528668,-0.693635465 52.2429224,3.12135959 50.157839,4.16181279 C49.1152973,4.85544825 50.273677,5.43347781 50.273677,6.3583251 C50.273677,6.82074874 50.389515,7.5143842 50.273677,7.74559603 C50.0420011,8.90165513 49.5786492,8.43923149 49.2311353,8.20801967 C48.6519455,7.74559603 48.1885936,7.97680785 47.8410797,8.20801967 C46.914376,9.01726104 47.7252418,10.8669556 49.2311353,10.6357438 C49.3469733,11.4449852 48.3044316,13.4102857 48.3044316,13.4102857 C48.3044316,13.4102857 44.2501028,14.1039211 43.439237,14.9131625 C42.0491815,12.7166502 38.5740425,11.2137734 39.8482601,8.09241376 Z"
                id="Shape"
                fill="#7A4A2A"
              />
              <path
                d="M42.7442092,15.9536157 C42.7442092,15.9536157 42.7442092,12.7166502 47.3777279,12.8322561 C51.6637326,12.947862 53.51714,15.4911921 53.51714,15.4911921 C53.51714,15.4911921 44.5976167,18.150128 42.7442092,15.9536157 Z"
                id="Shape"
                fill="#DB4C6B"
              />
              <path
                d="M76.1055433,57.1093199 C76.1055433,57.1093199 79.6965202,53.6411426 80.7390619,54.2191722 C81.7816036,54.7972017 84.0983629,56.8781081 85.8359324,55.9532608 C89.1952334,54.3347781 88.3843676,59.0746204 83.866687,59.8838618 C81.4340897,59.8838618 81.0865758,62.8896155 79.5806822,62.8896155 C76.1055433,62.6584037 76.1055433,57.1093199 76.1055433,57.1093199 Z"
                id="Shape"
                fill="#7A4A2A"
              />
              <TweenOne
                d="M76.2213813,12.3698325 L78.4223026,9.13286696 C78.4223026,9.13286696 80.9707378,8.09241376 81.7816036,7.5143842 C82.5924694,6.93635465 82.4766314,8.67044331 80.8548999,10.0577142 C81.3182517,11.4449852 81.2024138,12.8322561 81.2024138,12.8322561 C81.2024138,12.8322561 80.6232239,14.7975566 78.6539785,15.4911921 C76.4530572,15.7224039 75.6421914,13.2946797 76.2213813,12.3698325 Z"
                id="Shape"
                fill="#E5A383"
                component="path"
                animation={{
                  x: 2,
                  y: 4,
                  yoyo: true,
                  repeat: -1,
                }}
              />
              <TweenOne
                d="M67.4176959,24.0460295 C64.7534227,24.7396649 62.3208254,24.3928472 57.4556309,23.8148176 L57.5714688,34.5661673 C57.5714688,34.5661673 60.467418,37.3407092 56.992279,40.3464629 C48.3044316,43.6990343 44.4817787,41.7337338 42.8600472,39.9996451 C42.0491815,39.1904038 41.8175055,38.4967683 41.8175055,38.4967683 C41.8175055,38.4967683 40.195774,27.6298127 40.195774,25.5489063 C40.195774,25.5489063 36.720635,29.3639013 36.3731211,30.7511723 C38.5740425,32.6008669 40.5432879,33.4101082 40.5432879,33.4101082 L39.1532323,37.571921 C39.1532323,37.571921 29.0753293,34.3349555 30.6970608,29.5951132 C30.6970608,29.5951132 34.4038757,23.5836058 38.8057184,18.9593694 C39.9640981,17.8033103 41.2383157,16.6472512 42.6283713,15.9536157 C44.4817787,14.9131625 53.632978,14.1039211 57.9189827,15.9536157 C62.2049874,17.8033103 63.9425569,17.9189162 66.954344,18.150128 L76.6847331,12.4854384 C76.6847331,12.4854384 79.5806822,14.1039211 79.0014924,16.3004334 C75.2946775,19.1905812 69.1552654,23.5836058 67.4176959,24.0460295 Z"
                id="Shape"
                fill="#FD6F8D"
                component="path"
                attr="attr"
                animation={{
                  d: 'M67.4176959,24.0460295 C64.4603828,24.0460295 62.3208254,24.3928472 57.4556309,23.8148176 L57.5714688,34.5661673 C57.5714688,34.5661673 60.467418,37.3407092 56.992279,40.3464629 C48.3044316,43.6990343 44.4817787,41.7337338 42.8600472,39.9996451 C42.0491815,39.1904038 41.8175055,38.4967683 41.8175055,38.4967683 C41.8175055,38.4967683 40.195774,27.6298127 40.195774,25.5489063 C40.195774,25.5489063 36.720635,29.3639013 36.3731211,30.7511723 C38.5740425,32.6008669 40.5432879,33.4101082 40.5432879,33.4101082 L39.1532323,37.571921 C39.1532323,37.571921 29.0753293,34.3349555 30.6970608,29.5951132 C30.6970608,29.5951132 34.4038757,23.5836058 38.8057184,18.9593694 C39.9640981,17.8033103 41.2383157,16.6472512 42.6283713,15.9536157 C44.4817787,14.9131625 53.632978,14.1039211 57.9189827,15.9536157 C62.2049874,17.8033103 63.9425569,17.9189162 66.954344,18.150128 L78.6847331,15.4854384 C78.6847331,15.4854384 81.5806822,17.1039211 81.0014924,19.3004334 C77.2946775,22.1905812 69.6678082,24.0460295 67.4176959,24.0460295 Z',
                  yoyo: true,
                  repeat: -1,
                }}
              />
              <path
                d="M53.51714,39.6528274 C53.51714,39.6528274 65.3326125,35.7222264 73.4412701,37.4563151 C77.8431128,42.1961575 80.9707378,52.3694776 80.9707378,52.3694776 L82.0132795,53.6411426 C82.0132795,53.6411426 81.0865758,57.1093199 77.148085,57.6873495 C71.9353765,51.3290244 69.1552654,44.3926698 69.1552654,44.3926698 C69.1552654,44.3926698 60.8149319,46.2423643 55.6022234,49.1325121 C52.1270844,50.9822067 47.4935658,48.7856944 47.4935658,48.7856944 C47.4935658,48.7856944 43.3233991,49.7105417 41.7016676,46.9359998 C39.7324221,43.4678225 42.7442092,39.8840392 42.7442092,39.8840392 C42.7442092,39.8840392 48.3044316,39.8840392 53.51714,39.6528274 Z"
                id="Shape"
                fill="#6664B3"
              />
              <path
                d="M40.079936,20.693458 C40.079936,20.693458 40.079936,22.4275467 40.079936,25.6645122 C39.1532323,26.7049654 38.3423666,27.7454186 38.3423666,27.7454186 C38.3423666,27.7454186 37.7631767,23.8148176 40.079936,20.693458 Z"
                id="Shape"
                fill="url(#linearGradient-24)"
              />
              <path
                d="M80.1598721,58.4965909 C80.27571,49.0169062 73.2095942,40.6932806 63.826719,39.3060097 C60.235742,38.843586 56.876441,39.3060097 53.8646539,40.4620688 C52.1270844,41.1557043 50.0420011,40.4620688 49.2311353,38.843586 C44.9451306,30.9823841 36.720635,25.4333004 26.874408,25.3176945 C14.1322318,25.0864826 2.89594914,34.5661673 1.04254169,47.2828175 C-1.15837965,62.0803741 9.49871317,75.3750539 24.0942968,76.6467189 L60.9307698,77.3403543 L60.9307698,77.3403543 C71.2403487,77.3403543 79.9281961,69.1323347 80.1598721,58.4965909 Z"
                id="Shape"
                fill="url(#linearGradient-25)"
              />
            </g>
            <g id="Group" transform="translate(120.691781, 90.019569)">
              <polygon
                id="Shape"
                fill="#D8D8D8"
                points="56.7747405 48.243972 55.2347385 49.5783797 0.410667201 39.3137048 0.410667201 2.15558173 1.9506692 0.821173991 56.7747405 11.0858489"
              />
              <polygon
                id="Shape"
                fill="#FFFFFF"
                points="55.2347385 49.5783797 0.410667201 39.3137048 0.410667201 2.15558173 55.2347385 12.4202566"
              />
              <polygon
                id="Shape"
                fill="#90D1FF"
                opacity="0.7"
                points="29.4653716 25.0458067 5.13334001 20.2214095 5.13334001 8.41703341 29.4653716 13.2414306"
              />
              <path
                d="M36.6520476,40.7507593 L32.853376,39.9295853 L32.853376,19.0922953 C32.853376,17.8605343 33.9827108,17.0393603 35.1120456,17.2446538 L35.1120456,17.2446538 C36.0360468,17.4499473 36.6520476,18.1684746 36.6520476,19.0922953 L36.6520476,40.7507593 Z"
                id="Shape"
                fill="#FFA49F"
              />
              <path
                d="M41.4773873,41.6745801 L37.6787156,40.8534061 L37.6787156,34.5919544 C37.6787156,33.3601934 38.8080504,32.5390194 39.9373852,32.7443129 L39.9373852,32.7443129 C40.8613865,32.9496064 41.4773873,33.6681336 41.4773873,34.5919544 L41.4773873,41.6745801 Z"
                id="Shape"
                fill="#FFDF7F"
              />
              <path
                d="M46.2000601,42.7010475 L42.4013885,41.8798736 L42.4013885,30.1781442 C42.4013885,28.9463832 43.5307233,28.1252092 44.6600581,28.3305027 L44.6600581,28.3305027 C45.5840593,28.5357962 46.2000601,29.2543234 46.2000601,30.1781442 L46.2000601,42.7010475 Z"
                id="Shape"
                fill="#69C6B3"
              />
              <path
                d="M51.0253997,43.8301618 L47.2267281,43.0089878 L47.2267281,25.764334 C47.2267281,24.532573 48.3560629,23.711399 49.4853977,23.9166925 L49.4853977,23.9166925 C50.4093989,24.121986 51.0253997,24.8405132 51.0253997,25.764334 L51.0253997,43.8301618 Z"
                id="Shape"
                fill="#90D1FF"
              />
              <polygon
                id="Shape"
                fill="#90D1FF"
                points="29.6707052 30.5887312 5.13334001 25.6616872 5.13334001 23.1981653 29.6707052 28.0225624"
              />
              <polygon
                id="Shape"
                fill="#90D1FF"
                points="29.6707052 35.1051881 5.13334001 30.1781442 5.13334001 27.7146222 29.6707052 32.5390194"
              />
              <polygon
                id="Shape"
                fill="#90D1FF"
                points="29.6707052 39.3137048 5.13334001 34.3866609 5.13334001 31.9231389 29.6707052 36.7475361"
              />
            </g>
            <polygon
              id="Shape"
              fill="#D8D8D8"
              points="177.237871 92.9474458 175.800536 93.8712665 121.592466 83.4012981 121.592466 69.2360468 122.927134 68.4148728 177.237871 78.7821944"
            />
            <g id="Group" transform="translate(121.592466, 68.414873)">
              <polygon
                id="Shape"
                fill="#FFFFFF"
                points="54.4134041 25.0458067 0.2053336 14.5758383 0.2053336 0.410586996 54.4134041 10.8805554"
              />
              <path
                d="M48.4587297,19.194942 L5.13334001,10.8805554 C4.31200561,10.6752619 3.6960048,9.95673464 3.6960048,9.13556065 L3.6960048,9.13556065 C3.6960048,8.00644641 4.72267281,7.18527242 5.85200761,7.39056592 L49.1773973,15.7049526 C49.9987317,15.9102461 50.6147325,16.6287733 50.6147325,17.4499473 L50.6147325,17.4499473 C50.6147325,18.5790616 49.5880645,19.4002355 48.4587297,19.194942 Z"
                id="Shape"
                fill="#B1DEFF"
              />
              <path
                d="M23.4080304,14.3705448 L5.23600681,10.8805554 C4.41467241,10.6752619 3.7986716,9.95673464 3.7986716,9.13556065 L3.7986716,9.13556065 C3.7986716,8.00644641 4.82533961,7.18527242 5.95467441,7.39056592 L23.5106972,10.7779086 L23.5106972,14.3705448 L23.4080304,14.3705448 Z"
                id="Shape"
                fill="#FFA49F"
              />
            </g>
            <polygon
              id="Shape"
              fill="#D8D8D8"
              points="177.443205 67.849501 175.800536 69.1839088 121.592466 58.8165871 121.592466 0.92382074 124.67247 0 177.443205 10.0593814"
            />
            <g id="Group" transform="translate(121.592466, 0.900196)">
              <polygon
                id="Shape"
                fill="#FFFFFF"
                points="54.4134041 68.260088 0.2053336 57.8927664 0.2053336 0 54.4134041 10.3673216"
              />
              <polygon
                id="Shape"
                fill="#C7E8FF"
                points="49.2800641 62.1012831 4.62000601 53.1710159 4.62000601 6.46674518 49.2800641 15.3970123"
              />
              <path
                d="M4.62000601,53.1710159 C4.62000601,53.1710159 6.46800841,34.6946011 14.4760188,32.6416662 C22.1760288,30.5887312 25.5640332,44.0354553 31.4160408,49.88632 C39.526718,57.9954131 49.3827309,45.5751565 49.3827309,45.5751565 L49.3827309,62.2039298 L4.62000601,53.1710159 Z"
                id="Shape"
                fill="#69C6B3"
              />
              <ellipse
                id="Oval"
                fill="#FFF4D4"
                cx="37.473382"
                cy="30.1781442"
                rx="5.33867361"
                ry="7.39056592"
              />
            </g>
          </g>
        </TweenOne>
        <TweenOne
          id="Group"
          fillRule="nonzero"
          component="g"
          style={{ transformOrigin: '50% 50%' }}
          animation={[
            {
              opacity: 0,
              scale: 0,
              type: 'from',
              delay: 700,
              ease: 'easeOutBack',
            },
            {
              rotate: 5,
              yoyo: true,
              repeat: -1,
              duration: 2000,
              ease: 'easeInOutSine',
            },
          ]}
        >
          <g transform="translate(170.581019, 233.150685)">
            <path
              d="M78.5401021,62.7110658 C73.2014285,72.5651536 44.2493909,87.7568725 24.8453656,86.4224647 C5.44134041,85.088057 -0.3080004,76.8763171 1.1293348,68.7672239 C3.9013384,53.5755051 34.7013784,46.3902327 54.1054037,47.7246404 C73.5094289,49.0590481 86.2401121,48.6484612 78.5401021,62.7110658 Z"
              id="Shape"
              fill="#4BAA8A"
            />
            <path
              d="M84.8093401,23.4034587 C87.7866773,43.0089878 79.1626661,68.7733218 46.7199572,79.0379967 C31.7306044,83.7597471 15.9199172,86.5312093 4.52390238,75.5480072 C-3.79210843,67.5415608 3.59990118,47.1148577 15.4065832,41.6745801 C23.6199272,37.8766503 32.2439384,38.2872373 35.015942,29.7675572 C48.2599592,-12.6255501 80.6000013,-4.10586996 84.8093401,23.4034587 Z"
              id="Shape"
              fill="#90D1FF"
            />
            <path
              d="M75.1586609,23.9166925 C78.4439985,40.3401723 73.7213257,43.2142813 53.7012996,50.8101407 C48.0546256,52.9657224 47.2332912,57.8927664 41.7919508,66.3097998 C35.7346096,75.6506539 12.7372464,82.8359264 6.88523878,67.7468543 C5.34523678,63.8462778 6.26923798,59.3298209 8.73324119,54.3001302 C16.8439184,38.3898841 33.3732732,41.8798736 37.069278,31.7178454 C50.3132952,-4.72175045 70.8466553,2.46352197 75.1586609,23.9166925 Z"
              id="Shape"
              fill="#4D96CB"
            />
            <path
              d="M48.0546256,23.4034587 C48.0546256,23.4034587 44.255954,30.5887312 42.715952,32.2310792 C41.17595,33.8734271 28.4452668,31.6151987 27.829266,32.1284324 C27.2132652,32.6416662 28.2399332,36.3369491 28.2399332,36.3369491 C28.2399332,36.3369491 40.3546156,39.0057646 43.9479536,37.6713568 C47.5412916,36.3369491 50.8266292,30.2807909 51.3399632,26.8934482 C52.0586308,23.5061055 48.0546256,23.4034587 48.0546256,23.4034587 Z"
              id="Shape"
              fill="#E5A383"
            />
            <path
              d="M52.1612976,27.817269 C59.2453068,25.2511002 59.4506404,17.8605343 57.1919708,17.1420071 C57.1919708,17.1420071 49.902628,14.8837786 48.1572924,21.7611108 C48.1572924,21.7611108 47.5412916,23.9166925 47.0279576,24.4299262 C47.7466252,26.0722742 51.44263,28.0225624 52.1612976,27.817269 Z"
              id="Shape"
              fill="#FD6F8D"
            />
            <path
              d="M14.5852488,56.1477716 L13.5585808,60.9721688 C13.5585808,60.9721688 9.96524279,66.9256803 6.26923798,67.1309738 C3.59990118,67.849501 9.24657519,71.0315502 12.429246,68.3627348 C15.6119168,65.6939193 14.482582,67.6442075 16.2279176,67.438914 C17.9732532,67.2336205 17.1519188,61.7933428 17.1519188,61.7933428 L18.7945876,57.3795326 L14.5852488,56.1477716 Z"
              id="Shape"
              fill="#F9BF98"
            />
            <path
              d="M18.4865872,67.849501 L16.2279176,71.7500775 C16.2279176,71.7500775 11.402578,76.8824149 7.70657318,76.2665344 C4.93456958,76.3691812 9.75990919,80.6803446 13.455914,78.8327032 C17.1519188,76.9850617 15.50925,78.6274097 17.3572524,78.8327032 C18.9999212,78.9353499 19.4105884,74.7268332 19.5132552,73.597719 C19.5132552,73.3924255 19.615922,73.2897787 19.7185888,73.0844852 L22.2852588,70.0050827 L18.4865872,67.849501 Z"
              id="Shape"
              fill="#F9BF98"
            />
            <path
              d="M40.149282,40.5454658 C40.149282,40.5454658 49.4919608,35.6184219 51.3399632,21.658464 C51.5452968,20.1187628 52.7772984,17.4499473 54.9333012,17.2446538 C56.57597,17.0393603 67.2533173,17.3473006 68.3826521,19.7081758 C69.4093201,22.069051 68.9986529,25.764334 67.9719849,29.1516767 C66.4319829,34.2840141 58.8346396,43.316928 50.7239624,48.9624992 C50.8266292,48.9624992 45.282622,44.2407488 40.149282,40.5454658 Z"
              id="Shape"
              fill="#FD6F8D"
            />
            <path
              d="M19.8212556,39.2110581 C15.9199172,42.085167 13.7639144,56.9689456 13.7639144,56.9689456 L18.9999212,58.6112936 C18.9999212,58.6112936 21.669258,48.1413252 23.8252608,46.0883903 C25.9812636,44.0354553 38.1986128,45.9857435 38.1986128,45.9857435 C38.1986128,45.9857435 33.0652728,48.5519122 27.2132652,52.3498419 C23.6199272,54.7107172 17.4599192,69.3892023 17.4599192,69.3892023 L21.669258,71.0315502 C21.669258,71.0315502 26.9052648,64.0515713 29.6772684,60.6642286 C32.449272,57.2768859 40.149282,53.2736627 45.1799552,52.0419017 C49.7999612,51.0154342 55.035968,47.2175045 53.8039664,45.1645695 C46.4119568,33.4628401 37.3772784,38.4925308 37.3772784,38.4925308 C37.3772784,38.4925308 22.695926,37.1581231 19.8212556,39.2110581 Z"
              id="Shape"
              fill="#4D4882"
            />
            <g transform="translate(22.490592, 23.608752)" id="Shape">
              <polygon
                fill="#CCCCCC"
                points="25.5640332 20.5293498 5.13334001 14.9864253 16.3240212 8.41703341 36.5493808 13.4467241 36.5493808 14.1652513"
              />
              <polygon
                fill="#F2F2F2"
                points="25.3586996 20.016116 4.82533961 14.4731916 16.0160208 7.90379967 36.5493808 13.4467241"
              />
              <polygon
                fill="#B3B3B3"
                points="24.640032 18.9896485 8.82934481 14.6784851 18.3773572 9.2382074 34.1880444 13.6520176"
              />
              <polygon
                fill="#CCCCCC"
                points="26.180034 20.2214095 25.5640332 20.5293498 5.64667401 14.4731916 0 1.02646749 0.513334001 0.513233744 20.9440272 6.05615819"
              />
              <polygon
                fill="#F2F2F2"
                points="25.5640332 20.5293498 5.03067321 14.9864253 0 1.02646749 20.4306932 6.56939193"
              />
            </g>
            <path
              d="M65.4053149,27.7146222 L70.9493221,25.2511002 C70.9493221,25.2511002 74.0293261,35.0025414 73.4133253,39.0057646 C73.0026581,41.1613463 66.9453169,43.4195748 64.5839805,44.959276 C64.5839805,44.959276 63.8653129,46.8069175 62.9413117,48.1413252 C60.8879756,50.9127875 60.169308,49.9889667 59.2453068,50.0916135 C58.3213056,50.1942602 58.9373064,49.6810265 59.655974,48.243972 C59.3479736,48.654559 58.2186388,50.1942602 57.4999712,49.6810265 C56.7813036,49.1677927 58.629306,47.2175045 59.2453068,46.191037 C58.629306,46.3963305 57.602638,46.8069175 57.602638,46.0883903 C57.602638,45.78045 59.2453068,44.7539825 59.9639744,44.138102 C61.2986428,42.906341 61.9146437,42.3931073 63.3519789,41.6745801 C64.7893141,40.9560528 67.9719849,38.2872373 69.1013197,37.1581231 C68.5879857,34.7972479 65.4053149,27.7146222 65.4053149,27.7146222 Z"
              id="Shape"
              fill="#F9BF98"
            />
            <path
              d="M71.4626561,26.0722742 C70.6413217,24.2246327 70.3333213,21.4531705 69.4093201,20.1187628 C68.2799853,18.5790616 65.8159821,17.7578876 64.7893141,18.0658278 C62.8386449,18.784355 63.1466453,22.890225 63.1466453,22.890225 C63.0439785,24.121986 64.4813137,27.406682 65.9186489,29.9728507 C65.9186489,29.9728507 67.4586509,29.6649104 69.5119869,28.2278559 C71.2573225,27.0987417 71.4626561,26.0722742 71.4626561,26.0722742 Z"
              id="Shape"
              fill="#FD6F8D"
            />
            <path
              d="M56.8839704,14.0626046 L62.3253109,14.0626046 C62.3253109,14.0626046 62.6333113,16.9367136 63.0439785,17.5525941 C63.1466453,17.7578876 64.0706465,17.7578876 65.0973145,18.0658278 C63.3519789,19.4002355 62.4279777,20.9399368 58.629306,20.5293498 C54.6253008,20.1187628 55.7546356,16.8340668 55.7546356,16.8340668 L57.089304,16.9367136 L56.8839704,14.0626046 Z"
              id="Shape"
              fill="#EDB38C"
            />
            <path
              d="M66.0213157,8.72497366 C66.5346497,12.4202566 62.5306445,17.3473006 58.7319728,17.2446538 C55.1386348,17.1420071 53.1879656,13.4467241 52.7772984,9.4435009 C52.3666312,5.33763094 55.7546356,1.74499473 59.2453068,1.33440774 C62.7359781,0.92382074 65.4053149,4.72175045 66.0213157,8.72497366 Z"
              id="Shape"
              fill="#F9D5A5"
            />
            <path
              d="M58.7319728,1.23176099 C58.7319728,1.23176099 53.8039664,1.74499473 52.5719648,6.36409843 C51.8532972,9.13556065 52.6746316,10.0593814 52.6746316,10.0593814 L58.3213056,6.46674518 L58.3213056,8.31438666 C58.3213056,8.31438666 60.4773084,7.80115292 61.6066432,6.36409843 C61.0933092,8.72497366 62.8386449,8.62232691 63.1466453,9.95673464 C63.4546457,11.1884956 63.8653129,9.75144115 64.4813137,9.75144115 C65.8159821,9.85408789 65.8159821,12.2149631 64.2759801,12.7281969 C64.0706465,14.3705448 63.3519789,15.0890721 62.6333113,15.8075993 C62.7359781,16.2181863 62.9413117,16.8340668 62.9413117,16.8340668 C62.9413117,16.8340668 64.1733133,14.9864253 64.6866473,14.2678981 C66.6373165,11.5990826 67.4586509,9.75144115 67.1506505,7.18527242 C66.4319829,2.56616872 61.7093101,0.615880493 58.7319728,1.23176099 Z"
              id="Shape"
              fill="#A75561"
            />
            <path
              d="M12.94258,74.008306 C12.94258,74.008306 14.482582,72.776545 14.8932492,75.3427137 C15.0985828,76.3691812 18.2812536,76.6771214 19.3079216,75.7533007 C19.8212556,78.4221162 18.1785868,79.6538771 16.9465852,79.4485836 C15.7145836,79.2432902 8.11724038,80.7829914 6.47457158,78.2168227 C4.83190278,75.6506539 9.14390839,75.3427137 10.889244,74.9321267 C12.6345796,74.418893 12.94258,74.008306 12.94258,74.008306 Z"
              id="Shape"
              fill="#F4F4F4"
            />
            <path
              d="M10.9919108,63.5383376 C10.9919108,63.5383376 12.1212456,61.9986363 13.2505804,64.3595116 C13.6612476,65.2833323 16.6385848,66.3097998 17.4599192,63.6409843 C18.1785868,66.7203868 17.8705864,67.5415608 16.1252508,67.849501 C14.8932492,68.0547945 7.91190678,71.3394905 5.65323718,69.1839088 C3.39456758,67.028327 7.60390638,65.796566 9.14390839,64.8727453 C10.7865772,64.0515713 10.9919108,63.5383376 10.9919108,63.5383376 Z"
              id="Shape"
              fill="#F4F4F4"
            />
          </g>
        </TweenOne>
        <TweenOne
          id="Group-57"
          fillRule="nonzero"
          component="g"
          style={{ transformOrigin: '50% 100%' }}
          animation={{
            opacity: 0,
            scale: 0,
            type: 'from',
            delay: 350,
            ease: 'easeOutBack',
          }}
        >
          <g transform="translate(347.000000, 312.000000)">
            <ellipse
              id="Oval"
              fill="#529E88"
              cx="12.3749508"
              cy="39.5023864"
              rx="11.3960148"
              ry="4.92704395"
            />
            <ellipse
              id="Oval"
              fill="#3E6C5D"
              cx="12.3618245"
              cy="41.2290877"
              rx="9.13734521"
              ry="3.28469596"
            />
            <g id="Group" transform="translate(3.224479, 0.884850)">
              <path
                d="M9.75334601,40.4428191 L9.34267881,40.4428191 C4.31200561,40.4428191 0.2053336,36.3369491 0.2053336,31.3072584 L0.2053336,10.1620281 C0.2053336,5.0296907 4.41467241,0.821173991 9.54801241,0.821173991 L9.54801241,0.821173991 C14.6813524,0.821173991 18.8906912,5.0296907 18.8906912,10.1620281 L18.8906912,31.3072584 C18.8906912,36.3369491 14.7840192,40.4428191 9.75334601,40.4428191 Z"
                id="Shape"
                fill="#FFBF00"
              />
              <path
                d="M8.82934481,39.3137048 L8.21334401,39.3137048 C3.7986716,39.3137048 0.2053336,35.7210686 0.2053336,31.3072584 L0.2053336,9.85408789 C0.2053336,5.33763094 3.9013384,1.64234798 8.41867761,1.64234798 L8.72667801,1.64234798 C13.2440172,1.64234798 16.940022,5.33763094 16.940022,9.85408789 L16.940022,31.3072584 C16.940022,35.7210686 13.346684,39.3137048 8.82934481,39.3137048 Z"
                id="Shape"
                fill="#FFD557"
              />
              <ellipse
                id="Oval"
                fill="#FFFFFF"
                transform="translate(7.839389, 13.977861) rotate(-3.569641) translate(-7.839389, -13.977861) "
                cx="7.8393885"
                cy="13.9778614"
                rx="5.13354646"
                ry="6.26170351"
              />
              <ellipse
                id="Oval"
                fill="#FFBF00"
                transform="translate(7.839491, 13.980633) rotate(-3.569641) translate(-7.839491, -13.980633) "
                cx="7.83949117"
                cy="13.9806329"
                rx="4.72286275"
                ry="5.8511"
              />
              <ellipse
                id="Oval"
                fill="#FFFFFF"
                transform="translate(8.052084, 29.278952) rotate(-3.481923) translate(-8.052084, -29.278952) "
                cx="8.05208419"
                cy="29.2789525"
                rx="5.13357653"
                ry="6.26174018"
              />
              <ellipse
                id="Oval"
                fill="#FFBF00"
                transform="translate(8.154669, 29.275387) rotate(-3.481923) translate(-8.154669, -29.275387) "
                cx="8.15466886"
                cy="29.2753869"
                rx="4.7228904"
                ry="5.85113427"
              />
              <ellipse
                id="Oval"
                fill="#FF9100"
                transform="translate(7.943270, 13.875691) rotate(-3.569641) translate(-7.943270, -13.875691) "
                cx="7.94327045"
                cy="13.8756905"
                rx="3.59348252"
                ry="4.31133684"
              />
              <path
                d="M10.9853476,13.6520176 C11.0880144,15.4996591 9.95867961,17.0393603 8.41867761,17.1420071 C6.87867561,17.2446538 5.54400721,15.8075993 5.44134041,13.9599579 C5.33867361,12.1123164 6.46800841,10.5726151 8.00801041,10.4699684 C9.54801241,10.3673216 10.8826808,11.7017294 10.9853476,13.6520176 Z"
                id="Shape"
                fill="#FFFFFF"
              />
              <ellipse
                id="Oval"
                fill="#FF9100"
                transform="translate(7.948062, 29.276872) rotate(-3.481923) translate(-7.948062, -29.276872) "
                cx="7.94806219"
                cy="29.2768725"
                rx="3.59350357"
                ry="4.31136209"
              />
              <path
                d="M10.9853476,29.1516767 C11.0880144,30.9993182 9.95867961,32.5390194 8.41867761,32.6416662 C6.87867561,32.7443129 5.54400721,31.3072584 5.44134041,29.4596169 C5.33867361,27.6119755 6.46800841,26.0722742 8.00801041,25.9696275 C9.54801241,25.8669807 10.8826808,27.3040352 10.9853476,29.1516767 Z"
                id="Shape"
                fill="#FFFFFF"
              />
            </g>
          </g>
        </TweenOne>
        <TweenOne
          id="Group-Copy-4"
          fillRule="nonzero"
          component="g"
          style={{ transformOrigin: '50% 50%' }}
          animation={[
            {
              opacity: 0,
              scale: 0,
              type: 'from',
              delay: 800,
              ease: 'easeOutBack',
            },
            {
              y: 15,
              yoyo: true,
              repeat: -1,
              duration: 3800,
              ease: 'easeInOutQuad',
            },
          ]}
        >
          <g transform="translate(394.012573, 135.002616)">
            <path
              d="M30.9223598,8.45521263 C29.8096145,4.00510072 24.9135351,0.111252798 19.4610832,0.333758393 C16.1228473,0.445011191 12.1169642,3.22633114 10.3365717,6.23015668 C5.77431596,5.1176287 -0.457057728,9.01147662 0.210589453,15.5753917 C0.655687574,20.4705148 4.88411972,22.584318 10.0027481,22.4730652 C12.2282387,22.3618124 14.2311803,21.5830428 15.7890237,20.2480092 C17.2355926,24.1418571 21.6865738,26.9231771 26.8052022,26.7006715 C32.9253013,26.4781659 37.8213806,22.028054 37.5988316,16.7991725 C37.4875571,13.1278301 34.7056938,9.90149901 30.9223598,8.45521263 Z"
              id="Shape"
              fill="url(#linearGradient-20)"
            />
            <path
              d="M27.9179475,13.0165773 C27.2503003,12.7940717 26.8052022,12.3490606 26.5826531,11.6815438 C25.3586333,7.34268466 21.1302011,4.45011191 16.5679454,4.8951231 C14.1199057,5.1176287 12.0056897,6.34140948 10.4478462,8.01020144 C10.0027481,8.56646543 9.2238264,8.78897103 8.55617922,8.56646543 C7.88853203,8.34395984 7.33215938,8.34395984 6.55323767,8.34395984 C2.88117818,8.45521263 -0.123234137,11.6815438 0.0993149232,15.5753917 C0.210589453,18.5792172 2.10225647,21.0267788 4.55029613,22.028054 C4.55029613,22.028054 25.3586333,26.9231771 25.9150059,26.9231771 C29.920889,26.8119243 33.0365759,23.4743403 32.9253013,19.5804924 C32.9253013,16.4654141 30.8110852,13.9065997 27.9179475,13.0165773 Z"
              id="Shape"
              fill="#FFFFFF"
            />
          </g>
        </TweenOne>
        <TweenOne
          id="Group-59"
          fillRule="nonzero"
          component="g"
          style={{ transformOrigin: '50% 100%' }}
          animation={{
            opacity: 0,
            scale: 0,
            type: 'from',
            delay: 500,
            ease: 'easeOutBack',
          }}
        >
          <g transform="translate(141.000000, 324.000000)">
            <polygon
              id="Shape"
              fill="#4BAA8A"
              points="27.2231985 46.495592 0.388956652 39.9820968 20.1497373 28.1904245 46.9839792 34.5916181"
            />
            <g id="Group-43">
              <polygon
                id="Shape"
                fill="#FFBF00"
                points="27.3354757 43.1327211 0.388956652 36.619226 0.388956652 11.3451784 27.3354757 17.8586735"
              />
              <polygon
                id="Shape"
                fill="#F39A18"
                points="47.2085335 31.1199952 27.3354757 42.7993659 27.3354757 17.5314967 47.2085335 5.7300963"
              />
              <polygon
                id="Shape"
                fill="#FFDE79"
                points="27.3354757 18.4201817 0.388956652 11.9066866 20.1497373 0.115014241 47.0962563 6.62850943"
              />
            </g>
          </g>
        </TweenOne>
        <TweenOne
          id="Group-58"
          fillRule="nonzero"
          component="g"
          style={{ transformOrigin: '50% 100%' }}
          animation={{
            opacity: 0,
            scale: 0,
            type: 'from',
            delay: 600,
            ease: 'easeOutBack',
          }}
        >
          <g transform="translate(123.000000, 342.000000)">
            <polygon
              id="Shape"
              fill="#4BAA8A"
              points="30.9639989 37.3674217 0.424610621 30.1801166 8.73312066 25.9126543 39.1602318 33.0999593"
            />
            <g id="Group-31" transform="translate(3.792926, 0.083277)">
              <polygon
                id="Shape"
                fill="#81C8FB"
                points="27.1710734 34.4766038 0.336831488 27.9631086 0.336831488 2.80754103 27.1710734 9.20873457"
              />
              <polygon
                id="Shape"
                fill="#B3DFFF"
                points="27.1710734 9.20873457 0.336831488 2.80754103 6.06296679 0.224603282 33.0094858 6.73809847"
              />
              <polygon
                id="Shape"
                fill="#63A9DC"
                points="27.1710734 34.3643022 27.1710734 9.20873457 32.8972087 6.73809847 33.0094858 31.8936661"
              />
              <polygon
                id="Shape"
                fill="#4D96CB"
                points="24.2518671 30.9952529 3.03148339 25.9416791 3.03148339 6.17659026 24.2518671 11.2301641"
              />
            </g>
          </g>
        </TweenOne>
      </g>
    </svg>
  );
}
