import React from 'react';
import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import api from 'utils/api'
import { getValue, useSiblingEntity } from './utils/values';
import { Entity } from 'core/interfaces';
import { getContractValues } from 'hooks';
import { insertEntity } from 'appRedux/actions';
import EntitySelect, { parseOptionProps } from 'components/ui/EntitySelect';

function EntityInput(props) {
  const { label, handleChange, language, field, isTemplate, card, cardUid } = props;
  const { target } = field;
  const dispatch = useDispatch()
  const value = getValue(props, {});
  const contract = getContractValues();
  const entities = useSelector(Entity.getBaseState);
  const targetEntity = useSiblingEntity(card, cardUid, target?.fromEntityField)
  const { id } = value;

  const entity = Entity.getById(entities, id);

  // console.log('entity entry in input ', { entity, id });

  const onSelect = async (key, option) => {
    if (!option) return
    const { entity } = option
    const existingEntity = Entity.getById(entities, entity.id)
    if (!existingEntity) {
      const newEntity = entity
      dispatch(insertEntity(newEntity, { meta: { noApi: true, actionsToFollow: true } }))
      await api.post(`/documententityrelations`, {
        documentId: contract.documentId,
        entityId: entity.id,
      })
      handleChange(entity)
    }
  }

  const onChange = (key, option) => {
    if (!option) return handleChange(null)
    const { entity } = option
    const existingEntity = Entity.getById(entities, entity.id)
    if (existingEntity)
      handleChange(entity)
  }
  
  const targetType = target?.targetRelative && `${target.targetRelative}s`
  const targetSuggestions = targetEntity?.[targetType]
    ?.map((entity) => entities.find(({id}) => entity.id === id) ?? entity)
  const suggestions = targetSuggestions ?? [...new Map([
    ...entities.map(e => [e.id, e]),
    ...Entity.getAgents(entities).map(e => [e.id, e]),
  ]).values()]

  return (
    <Form.Item
      label={label}
      extra={field.info ? <small className="muted">{field.info[language]}</small> : null}
      style={{paddingLeft: 8, paddingRight: 8}}
    >
      <EntitySelect
        suggestions={suggestions}
        selected={entity ? parseOptionProps(entity) : undefined}
        onSelect={onSelect}
        onChange={onChange}
        onSearch={isTemplate ? () => { throw new Error('Not implemented')} : undefined}
      />
    </Form.Item>
  )
}

export default EntityInput;
