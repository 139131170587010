import React from 'react';
import { Row, Col } from 'antd';
import IntlMessages from 'util/IntlMessages';
import growth_img from 'assets/images/bb5_growth_small.jpg';
import skills_img from 'assets/images/bb5_skills_small.jpg';
import structures_img from 'assets/images/bb5_structures_small.jpg';

const images = [growth_img, skills_img, structures_img];

const items = 3;

export default function Section2() {
  const children = Array(items)
    .fill(0)
    .map((d, i) => {
      return (
        <Col key={i} className="col" md={8} sm={24}>
          <div className="content-wrapper home-hover">
            <div className="image">
              <img src={images[i]} alt="growth" />
            </div>
            <div className="code-wrapper">
              <p className="mx-3">
                <IntlMessages id={`bb5.landing.section2.item${(i + 1).toString()}.text`} />
              </p>
            </div>
          </div>
        </Col>
      );
    });

  return (
    <div className="landing-section section-2 prim-bg">
      {/* <h2 key="h2">Page 3</h2>
        <i key="i" className="line" /> */}
      <div className="content">
        <Row gutter={16}>{children}</Row>
      </div>
    </div>
  );
}
