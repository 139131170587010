import { Form } from 'antd';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { CountrySelect, Label } from '.';

export const EntityEditFieldJurisdiction = ({
  onChange,
  required,
  placeholder,
  ignoreLabel = false,
  ...rest
}) => {
  const formatMessage = useIntlMessage();
  return (
    <Form.Item
      {...rest}
      label={
        ignoreLabel ? null : (
          <Label>
            <IntlMessages id="app.general.Jurisdiction" />
          </Label>
        )
      }
      name="jurisdiction"
      fieldKey="jurisdiction"
      rules={[
        {
          required,
          whitespace: true,
          message:
            formatMessage('app.general.Jurisdiction') + formatMessage('app.general.fields.fieldRequired'),
        },
      ]}
    >
      <CountrySelect
        onChange={onChange}
        placeholder={placeholder ? <IntlMessages id="app.general.Jurisdiction" /> : null}
      />
    </Form.Item>
  );
};
