import React, { useEffect } from 'react';
import { Button, Form, Input, message, Card } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import logo from 'assets/images/Logo_white_bb5.svg';
import { showAuthLoader, userSignIn } from 'appRedux/actions/Auth';
import { hideMessage } from 'appRedux/actions/Common';

import IntlMessages from 'util/IntlMessages';
import CircularProgress from 'components/CircularProgress/index';

const FormItem = Form.Item;

const SignIn = (props) => {
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, user } = useSelector(({ auth }) => auth);
  const history = useHistory();

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (user !== null) {
      console.log('re-direct', { user });
      history.push('/main');
    }
  }, [showMessage, user, dispatch, history]);

  const onFinish = (values) => {
    dispatch(showAuthLoader());
    dispatch(userSignIn(values));
  };

  return (
    <div className="app-login-wrap">
      <div className="app-login-container">
        <div className="app-login-main-content" style={{ borderRadius: '3px' }}>
          <div className="app-logo-content dark-container">
            <div className="app-logo-content-bg"></div>
            <div className="app-logo-wid">
              <h1>
                <IntlMessages id="app.userAuth.signIn" />
              </h1>
              <p>
                <IntlMessages id="app.userAuth.bySigning" />
              </p>
            </div>
            <div className="app-logo">
              <img
                src={logo}
                alt="logo"
                className="app-login-logo"
                style={{
                  maxWidth: 300,
                }}
              />
            </div>
          </div>
          <div className="app-login-content light-container">
            <Form onFinish={onFinish} className="signin-form form-row0">
              <FormItem
                name="email"
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                ]}
              >
                <Input placeholder="Email" />
              </FormItem>
              <FormItem name="password" rules={[{ required: true, message: 'Please input your Password!' }]}>
                <Input type="password" placeholder="Password" />
              </FormItem>
              <FormItem>
                <Button type="primary" className="mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signIn" />
                </Button>
                <Link to="/forgotpwd">Forgot password?</Link>
              </FormItem>
              <FormItem>
                Do not have an account? <Link to="/signup">Click here to sign up</Link>
              </FormItem>
            </Form>
          </div>

          {loader ? (
            <div className="loader-view">
              <CircularProgress />
            </div>
          ) : null}
          {showMessage ? message.error(alertMessage.toString()) : null}
        </div>
      </div>
    </div>
  );
};

// const WrappedNormalLoginForm = Form.create()(SignIn);

export default SignIn;
