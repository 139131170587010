import React from 'react';
import { Layout } from 'antd';
import Header from './Header';
import Banner from './Banner';
import Page1 from './Section1';
import Page2 from './Section2';
import Page3 from './Section3';
import Footer from './Footer';
import './landing.less';

const { Content } = Layout;

const Landing = () => (
  <div className="landing-page">
    <Layout>
      <Header key="header" />
      <Content className="landing-content">
        <Banner key="banner" />
        <Page1 key="page1" />
        <Page2 key="page2" />
        <Page3 key="page3" />
      </Content>
      <Footer key="footer" />
    </Layout>
  </div>
);

export default Landing;
