import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { Row, Col, Button, Checkbox, Radio, Form, Input, Steps, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { hideAuthLoader, showAuthLoader, userSignUp } from 'appRedux/actions/Auth';
import { hideMessage } from 'appRedux/actions/Common';
import IntlMessages from 'util/IntlMessages';
import {
  EntityEditFieldFirstname,
  EntityEditFieldLastname,
  EntityEditFieldJurisdiction,
  EntityEditFieldIdentificationNumber,
} from 'components/entity/EditEntity/components';
import { AutoModal } from 'components/ui';
import { normalizeAndValidate } from 'utils/validation';
import { smallGutter } from 'config';
import countries from 'core/config/countries.json';
import api from 'utils/api';
import growth_img from 'assets/images/bb5_growth_small.jpg';
import skills_img from 'assets/images/bb5_skills_small.jpg';
import config from 'config/config';

const { applicationResourceIds } = config;
const {
  referringClientId,
  projectTemplateIds,
  permissionAccessorId,
  permissionAccessRequestGroupName,
  referringUserEntityId,
} = applicationResourceIds;
const [projectTemplateId] = projectTemplateIds;

const FormItem = Form.Item;
const { Step } = Steps;

const Block = ({ children }) => {
  return (
    <div className="app-login-main-content" style={{ borderRadius: '3px' }}>
      <div className="w-100 p-4 light-container">{children}</div>
    </div>
  );
};

const PersonalStep = ({ form, next }) => {
  const onLastFieldKeyDown = (evt) => {
    if (evt.key === 'Enter') next();
  };

  return (
    <Block>
      <Form form={form} className="signup-form form-row0">
        <Row gutter={smallGutter}>
          <Col md={12}>
            <EntityEditFieldFirstname ignoreAutocomplete ignoreLabel placeholder entityType="RealPerson" />
          </Col>
          <Col md={12}>
            <FormItem name="lastName">
              <EntityEditFieldLastname ignoreLabel placeholder entityType="RealPerson" />
            </FormItem>
          </Col>
        </Row>

        <FormItem
          name="email"
          rules={[
            {
              required: true,
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
          ]}
        >
          <Input placeholder="Email" />
        </FormItem>
        <Row gutter={smallGutter}>
          <Col md={12}>
            <FormItem
              name="password"
              rules={[
                { required: true, message: 'Please input your password!' },
                {
                  message: 'Password needs to be at least 8 characters',
                  validator: (_, value) => {
                    if (value.length < 8) return Promise.reject();
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input type="password" placeholder="Password" />
            </FormItem>
          </Col>
          <Col md={12}>
            <FormItem
              name="password2"
              rules={[
                { required: true, message: 'Please input your password' },

                {
                  message: 'Passwords do not match',
                  validator: (_, value) => {
                    const pw1 = form.getFieldValue('password');
                    if (value !== pw1) return Promise.reject();
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input type="password" placeholder="Repeat password" onKeyDown={onLastFieldKeyDown} />
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Block>
  );
};

const CorporateStep = ({ form }) => {
  const jurisdiction = form.getFieldValue('jurisdiction');
  const [jurisdictionData, setJurisdictionData] = useState({});

  const onChangeJurisdiction = useCallback(
    (jurisdiction) => {
      const country = countries.find((c) => c.code === jurisdiction);
      if (country && country.data) {
        setJurisdictionData(country.data);
        const newFormValue = country.data.corporateForms?.defaultValue || '';
        form.setFieldsValue({ form: newFormValue });
      } else {
        setJurisdictionData({});
        form.setFieldsValue({ form: null });
      }
    },
    [form]
  );

  useEffect(() => {
    onChangeJurisdiction(jurisdiction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Block>
      <Form form={form} className="signup-form form-row0">
        <Row>
          <Col span={24} className="flex-column align-items-center justify-content-center">
            <h4>Organisation type</h4>
            <Form.Item
              name="corpMetaType"
              label={null}
              rules={[{ required: true, message: 'Please select if lender or borrower' }]}
            >
              <Radio.Group>
                <Radio.Button
                  value="lender"
                  style={{
                    minHeight: '150px',
                    marginRight: '20px',
                  }}
                >
                  <div>
                    <img
                      src={skills_img}
                      alt="lender"
                      style={{
                        width: '100px',
                        height: '100px',
                        marginTop: '15px',
                      }}
                    />
                  </div>
                  <div className="w-100 d-flex justify-content-center">Lender</div>
                </Radio.Button>
                <Radio.Button
                  value="borrower"
                  style={{
                    minHeight: '150px',
                    marginRight: '10px',
                  }}
                >
                  <div>
                    <img
                      src={growth_img}
                      alt="lender"
                      style={{
                        width: '100px',
                        height: '100px',
                        marginTop: '15px',
                      }}
                    />
                  </div>
                  <div className="w-100 d-flex justify-content-center">Borrower</div>
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={24}></Col>
        </Row>
        <Row gutter={smallGutter}>
          <Col md={12}>
            <EntityEditFieldFirstname
              ignoreAutocomplete
              ignoreLabel
              placeholder
              fieldName="corpName"
              entityType="LegalPerson"
            />
            {/* <FormItem name="firstName">
              <Input placeholder="First name" />
            </FormItem> */}
          </Col>
          <Col md={12}>
            <EntityEditFieldJurisdiction onChange={onChangeJurisdiction} ignoreLabel placeholder required />
          </Col>
        </Row>
        <Row gutter={smallGutter}>
          <Col md={12}>
            <EntityEditFieldLastname
              ignoreLabel
              placeholder
              jurisdictionData={jurisdictionData}
              entityType="LegalPerson"
            />
          </Col>
          <Col md={12}>
            <EntityEditFieldIdentificationNumber
              ignoreLabel
              ignoreAutocomplete
              placeholder
              entityType="LegalPerson"
              rules={[
                {
                  message: 'Invalid identification number',
                  validator: (_, value) => {
                    const jurisdiction = form.getFieldValue('jurisdiction');
                    try {
                      normalizeAndValidate(value, 'LegalPerson', jurisdiction);
                    } catch (err) {
                      return Promise.reject(err);
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="d-flex">
              <Form.Item name="acceptterms" label={null} valuePropName="checked">
                <Checkbox>
                  I accept the
                  <AutoModal
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    trigger={<span className="link ml-1">terms and conditions</span>}
                    title={'Terms and conditions'}
                    className="modal-md"
                  >
                    BB5 terms and conditions:
                    <ul>
                      <li>Bla</li>
                    </ul>
                  </AutoModal>
                </Checkbox>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </Block>
  );
};

const steps = [
  {
    title: 'Personal Info',
    content: PersonalStep,
  },
  {
    title: 'Corporate Info',
    content: CorporateStep,
  },
];

const SignUp = (props) => {
  const history = useHistory();
  const { loader, alertMessage, showMessage, user } = useSelector(({ auth }) => auth);

  const [personalForm] = Form.useForm();
  const [corporateForm] = Form.useForm();

  const forms = useMemo(() => [personalForm, corporateForm], [corporateForm, personalForm]);

  const submit = async (data) => {
    try {
      const result = await api.post('/clients/signup', data);
      if (!result?.data) {
        return notification.error({
          message: 'Error',
          description: 'An error occurred when signup up. Please try again later.',
        });
      }
      history.push('/signin');
    } catch (err) {
      console.log('err resp ', err.response);
      let errorDesc;
      if (err.response?.data?.errors) {
        errorDesc = (
          <div>
            <ul>
              {Object.entries(err.response.data.errors).map(([key, val]) => (
                <li key={key}>
                  {key}: {val}
                </li>
              ))}
            </ul>
          </div>
        );
      } else {
        errorDesc = 'An error occurred when signup up. Please try again later.';
      }
      return notification.error({
        message: 'Error',
        description: errorDesc,
      });
    }
  };

  const onFinish = async () => {
    try {
      await personalForm.validateFields();
      await corporateForm.validateFields();
    } catch (err) {
      notification.error({
        message: 'Fill out the form',
      });
    }
    const personalValues = personalForm.getFieldsValue();
    const corporateValues = corporateForm.getFieldsValue();

    const accepted = corporateForm.getFieldValue('acceptterms');

    if (!accepted) {
      return notification.error({
        message: 'Acceptance of terms',
        description: 'Before signing up, you need to accept the terms and conditions',
      });
    }

    const values = {
      ...personalValues,
      ...corporateValues,
      referringClientId,
      referringUserEntityId,
      permissionAccessorId,
      permissionAccessRequestGroupName,
    };

    console.log('submit ', { personalValues, corporateValues, values });
    submit(values);
  };

  const [current, setCurrent] = useState(0);

  const next = useCallback(async () => {
    try {
      await forms[current].validateFields();
    } catch (err) {
      console.log('err', err);
      return;
    }
    setCurrent(current + 1);
  }, [current, forms]);

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <div className="app-login-wrap">
      <div className="app-login-container">
        <Steps current={current}>
          <Step title={'Personal Info'} />
          <Step title={'Corporate Info'} />
        </Steps>
        <div className={`steps-content mt-4 d-${current === 0 ? 'block' : 'none'}`}>
          <PersonalStep form={personalForm} next={next} />
        </div>
        <div className={`steps-content mt-4 d-${current === 1 ? 'block' : 'none'}`}>
          <CorporateStep form={corporateForm} />
        </div>
        <div className="steps-action mt-4 w-100 d-flex" style={{ justifyContent: 'space-between' }}>
          <div>
            {current > 0 && (
              <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                Previous
              </Button>
            )}
          </div>
          <div>
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <FormItem>
                <Button
                  type="primary"
                  className="mb-0"
                  onClick={onFinish}
                  size="large"
                  block
                  style={{ minWidth: '120px' }}
                >
                  <IntlMessages id="app.userAuth.signUp" />
                </Button>
              </FormItem>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
