import React from 'react';
import { Row, Col } from 'antd';
import IntlMessages from 'util/IntlMessages';
import logo from 'assets/images/Logo_white_bb5.svg';

function footerContent() {
  return (
    <>
      <Row>
        <Col sm={24} md={8} className="col">
          <IntlMessages id="bb5.landing.footer.col1.text" />
        </Col>
        <Col sm={24} md={8} className="col">
          <div>
            <div>
              <IntlMessages id="bb5.landing.footer.col2.contact" />
            </div>
            <div>info@bb5.se</div>
            <div>+46 760 35 05 03</div>
          </div>
        </Col>
        <Col sm={24} md={8} className="col">
          <ul className="footer-links">
            <li>
              <a href={'#'}>
                <IntlMessages id="bb5.landing.footer.col3.link1" />
              </a>
            </li>
            <li>
              <a href={'#'}>
                <IntlMessages id="bb5.landing.footer.col3.link2" />
              </a>
            </li>
            <li>
              <a href={'#'}>
                <IntlMessages id="bb5.landing.footer.col3.link3" />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
      <div className="logo-wrapper">
        <img src={logo} alt="bb5 logo" className="footer-logo" />
        <p key="cop" className="copy">
          Copyright ©2022. BB5 AB (559283-6182).
        </p>
      </div>
    </>
  );
}

function Footer() {
  return <div className="landing-footer">{footerContent()}</div>;
}

export default Footer;
