import { Form, Input } from 'antd';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { Label, AutoCompleteEntity } from '.';

export const EntityEditFieldIdentificationNumber = ({
  entityType,
  required = false,
  rules = [],
  onSearchSelect = () => {},
  searchData = {},
  ignoreLabel,
  ignoreAutocomplete,
  placeholder,
  ...rest
}) => {
  const formatMessage = useIntlMessage();

  const labelText =
    entityType === 'RealPerson'
      ? formatMessage('app.general.PersonIdNumber')
      : formatMessage('app.general.LegalPersonIdNumber');

  const autocompleteWrapper = (item) => {
    if (ignoreAutocomplete) return item;
    return (
      <AutoCompleteEntity
        field="identificationNumber"
        inactive={entityType !== 'LegalPerson'}
        onSearchSelect={onSearchSelect}
        placeholder={labelText}
      >
        {item}
      </AutoCompleteEntity>
    );
  };

  return (
    <Form.Item
      {...rest}
      label={ignoreLabel ? null : <Label>{labelText}</Label>}
      name="identificationNumber"
      rules={[
        {
          required,
          whitespace: true,
          message: formatMessage('app.persons.validation.identificationNumber'),
        },
        ...rules,
      ]}
      placeholder={labelText}
    >
      {autocompleteWrapper(<Input placeholder={labelText} />)}
      {/* <AutoCompleteEntity
        field="identificationNumber"
        inactive={entityType !== 'LegalPerson'}
        onSearchSelect={onSearchSelect}
        placeholder={labelText}
      >
        <Input />
      </AutoCompleteEntity> */}
    </Form.Item>
  );
};
