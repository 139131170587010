import React, { memo, useEffect, useRef, useState } from 'react';
import { Button, Card } from 'antd';
import { useSelector } from 'react-redux';
import { useContract, useSetStudioPage, useYjsEditor } from 'hooks';
import IntlMessages from 'util/IntlMessages';
import { getPageSteps, getStudioPageByPage } from '../../utils/pageMenu';
import { findPageByStudioPage } from '../../../../utils/draftPath';
import StudioInputFormContent from './StudioInputFormContent';
import InputTopbar from './InputTopbar';
import FinishedInputs from './FinishedInputs';

export default function StudioInputContainerHolder(props) {
  return <StudioInputContainer {...props} />;
}

const StudioInputContainer = memo((props) => {
  const { activePagePaths, activeFlatUiPages, flatUiPages, isTemplate, hideOneSection, hideFinishActions } =
    props;

  return (
    <StudioInputForms
      activeFlatUiPages={activeFlatUiPages}
      flatUiPages={flatUiPages}
      activePagePaths={activePagePaths}
      isTemplate={isTemplate}
      hideOneSection={hideOneSection}
      hideFinishActions={hideFinishActions}
    />
  );
});

const StudioInputForms = (props) => {
  const { activePagePaths, activeFlatUiPages, isTemplate, hideOneSection, hideFinishActions } = props;
  const studioPage = useSelector(({ draft }) => draft.studioPage);
  const contract = useContract();
  const { onlineMode, onlineLoaded } = useYjsEditor() || {};
  const setStudioPage = useSetStudioPage();
  const scrollRef = useRef();
  const [extraWidth, setExtraWidth] = useState(0);
  const [extraHeight, setExtraHeight] = useState(0);

  useEffect(() => {
    const inputForm = scrollRef.current;
    if (!inputForm) return;
    const hasScroll = inputForm.children[0]?.clientHeight > inputForm?.clientHeight;
    const clientWidth = inputForm.clientWidth - parseFloat(getComputedStyle(inputForm).paddingRight);
    const clientHeight = inputForm.clientHeight - parseFloat(getComputedStyle(inputForm).paddingBottom);
    const newExtraWidth = hasScroll ? 0 : window.innerWidth - 200 - clientWidth;
    const newExtraHeight = hasScroll ? 0 : window.innerHeight - 400 - clientHeight;
    if (extraWidth !== newExtraWidth) setExtraWidth(newExtraWidth);
    if (extraHeight !== newExtraHeight) setExtraHeight(newExtraHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentPage = findPageByStudioPage(contract, studioPage);
  const { next, prev } = getPageSteps({ activeFlatUiPages, page: currentPage });
  const nextLink = getStudioPageByPage(next);
  const prevLink = getStudioPageByPage(prev);

  const initialLoading = onlineMode && !onlineLoaded;
  if (initialLoading || !activeFlatUiPages.length) return null;

  return (
    <>
      <div id="draft-input-forms" className="draft-input-forms">
        <Card
          bodyStyle={{ padding: 0 }}
          actions={
            hideFinishActions || isTemplate
              ? null
              : [
                  <Button disabled={prev === null} onClick={() => setStudioPage(prevLink)}>
                    <IntlMessages id="desc.previous" cap />
                  </Button>,
                  next === null ? (
                    <FinishedInputs contract={contract} />
                  ) : (
                    <Button type="primary" disabled={next === null} onClick={() => setStudioPage(nextLink)}>
                      <IntlMessages id="desc.next" cap />
                    </Button>
                  ),
                ]
          }
        >
          <div>
            <InputTopbar
              activeFlatUiPages={activeFlatUiPages}
              activePagePaths={activePagePaths}
              hideOneSection={hideOneSection}
            />
          </div>
          <div
            className="draft-input-forms-scroll"
            ref={scrollRef}
            style={
              {
                /* paddingRight: extraWidth,
            marginRight: -extraWidth,
            paddingBottom: extraHeight,
            marginBottom: -extraHeight, */
              }
            }
          >
            <StudioInputFormContent {...props} currentPage={currentPage} />
          </div>
        </Card>
      </div>
    </>
  );
};
