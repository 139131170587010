import React from 'react';
import { useSelector } from 'react-redux';
import { userToName } from 'components/ui';

const UserProfile = () => {
  const user = useSelector((state) => state.auth.user)

  return (
    <div className="sidebar-profile">
      <div className="avatar-name user-name">
        {user && userToName(user)}
      </div>
    </div>
  );
};

export default UserProfile;
