import React from 'react';
import logo from 'assets/images/bb5.png'


const SidebarLogo = () => {

  return (
    <div className="layout-sider-header">
      <div className="w-100 d-flex justify-content-center">
        <div className="sider-header-logo">
          <img src={logo} className="site-logo" />
        </div>
      </div>
    </div>
  );
};

export default SidebarLogo;
