import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import { Row, Col, Card, Table, Spin, Button, notification, Form, Input, Radio } from 'antd';
import { useSelector } from 'react-redux';
import { Entity } from 'core/interfaces';
import api from 'utils/api';
import IntlMessages from 'util/IntlMessages';
import { useIsReferringClient, useIsLender, useIsBorrower, useMountFetch, useFetch } from 'hooks';
import { gutter } from 'config';
import WidgetHeader from 'components/WidgetHeader/index';
import DocumentWidget from 'components/Widgets/DocumentWidget';
import { AutoModal } from 'components/ui';
import config from 'config/config';
import { smallGutter } from 'config';

const { Column } = Table;

const { applicationResourceIds } = config;
const { projectTemplateIds, documentTemplateIds, permissionAccessorId, permissionAccessRequestGroupName } =
  applicationResourceIds;

function getClientEntitiesUrl(clientId) {
  return `/clients/${clientId}/userEntities`;
}
function getAccessorRequestsUrl() {
  return '/tasks/my?type=system-access&resourceType=Client&status=pending';
}
function getRequestingClientsUrl(tasks) {
  if (!tasks) return '';
  const clientIds = tasks.map((t) => t.clientId);
  const uriIds = encodeURI(JSON.stringify(clientIds));
  const include = [{ model: 'Entity', as: 'Entity' }];
  const includeQuery = encodeURI(JSON.stringify(include));
  return `/clients?ids=${uriIds}&include=${includeQuery}`;
}

function PendingClientUserEntities({ clientId }) {
  const [userEntities] = useMountFetch(getClientEntitiesUrl(clientId));
  if (!userEntities) return <span>Loading . . .</span>;
  return (
    <span>
      {Entity.name(userEntities[0])}
      <small className="ml-2">{Entity.firstEmail(userEntities[0])}</small>
    </span>
  );
}

function approvalPosts(clientId) {
  const objs = [];
  for (const id of projectTemplateIds) {
    objs.push({
      accessorType: 'Client',
      accessorId: clientId,
      resourceType: 'ProjectTemplate',
      resourceId: id,
      groupName: 'viewer',
    });
  }
  for (const id of documentTemplateIds) {
    objs.push({
      accessorType: 'Client',
      accessorId: clientId,
      resourceType: 'DocumentTemplate',
      resourceId: id,
      groupName: 'viewer',
    });
  }
  objs.push({
    accessorType: 'Client',
    accessorId: clientId,
    resourceType: 'PermissionAccessor',
    resourceId: permissionAccessorId,
    groupName: permissionAccessRequestGroupName,
  });

  return objs;
}

const PendingSystemClients = () => {
  const [tasks, setTasks] = useState(null);
  const [clients, setClients] = useState(null);
  useMountFetch(getAccessorRequestsUrl(), setTasks);
  useFetch(getRequestingClientsUrl(tasks), setClients);

  if (!clients) return <div>Loading applicant organisations. . .</div>;
  if (clients.length === 0) return <div>No applicants</div>;

  const approveApplicant = async (client) => {
    const { id } = client;
    const perms = approvalPosts(id);
    const promises = [];
    const task = tasks.find((t) => t.clientId === id && t.type === 'system-access');
    try {
      for (const obj of perms) promises.push(api.post('/permissions/grant', obj));
      if (task) {
        promises.push(api.post(`/tasks/${task.id}/outcomeStatus`, { value: 'approved' }));
      }
      const results = await Promise.all(promises);

      notification.success({
        message: 'Approved',
        description: 'Organisation was granted permission',
      });

      setClients(clients.filter((c) => c.id !== id));
    } catch (err) {
      console.log('Approval err ', err);
      console.log('Err resp ', err.response);
    }
  };

  return (
    <Table rowKey="id" className="table-responsive documents-table" dataSource={clients}>
      <Column
        title={<IntlMessages id="general.company" cap />}
        dataIndex="name"
        key="name"
        render={(text, record) => {
          return record.Entity.firstName;
        }}
      />
      <Column
        title={<IntlMessages id="app.general.Type" cap />}
        dataIndex="name"
        key="name"
        render={(text, record) => {
          const isLender = record.Entity.tags.includes('lender');
          const isBorrower = record.Entity.tags.includes('borrower');
          if (isLender) return 'Lender';
          if (isBorrower) return 'Borrower';
          return 'Unknown';
        }}
      />
      <Column
        title={<IntlMessages id="general.person" cap />}
        dataIndex="person"
        key="person"
        render={(text, record) => {
          return <PendingClientUserEntities clientId={record.id} />;
        }}
      />
      <Column
        title={null}
        dataIndex="person"
        key="person"
        render={(text, record) => {
          return (
            <Button type="primary" onClick={() => approveApplicant(record)}>
              Approve
            </Button>
          );
        }}
      />
    </Table>
  );
};

const AdminView = () => {
  return (
    <div className="mb-4">
      <WidgetHeader title={<>Pending organisations and users</>} styleName="mb-3" />
      <PendingSystemClients />
    </div>
  );
};

export default AdminView;
