import { useState, useEffect, useCallback, useRef } from 'react';
import debounce from 'lodash/debounce';
import { COLLAPSE_SIZE } from 'config';

export const useIsSidebarMini = () => {
  const [isMini, setIsMini] = useState(false);
  const toggleMini = useCallback(() => {
    setIsMini(!isMini);
  }, [isMini, setIsMini]);

  useEffect(() => {
    window.addEventListener('menu_mini', toggleMini);
    return () => {
      window.removeEventListener('menu_mini', toggleMini);
    };
  }, [toggleMini]);

  return isMini;
};

export const useIsNarrowView = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const widthRef = useRef(window.innerWidth);

  const updateWindowW = useCallback(
    debounce(() => {
      const windowInnerWidth = window.innerWidth;
      if (windowInnerWidth === widthRef.current) return;
      widthRef.current = windowInnerWidth;
      setWidth(windowInnerWidth);
    }, 500),
    []
  );

  useEffect(() => {
    window.addEventListener('resize', updateWindowW);
    return () => {
      window.removeEventListener('resize', updateWindowW);
    };
  }, [updateWindowW]);

  const narrowView = width < COLLAPSE_SIZE;
  return narrowView;
};
