import React from 'react';

import { Form, InputNumber } from 'antd';
import { getValue, usePrefixSuffix } from './utils/values';
import { isNumeric, combineNumber } from 'core/utils/general';

function InputNumeric(props) {
  const { label, handleChange, cardId, language, field, inputIndex, cardUid } = props;

  const [prefix, suffix] = usePrefixSuffix(field);

  let value = getValue(props, 0);

  const handleNumericChange = (newVal) => {
    if (!isNumeric(combineNumber(newVal, language)) && newVal !== 0) return;

    handleChange(newVal, { format: field.format });
  };

  return (
    <Form.Item
      label={label}
      extra={field.info ? <small className="muted">{field.info[language]}</small> : null}
    >
      {field.introText && field.introText[language] && <div>{field.introText[language]}</div>}
      <InputNumber
        type="text"
        name={field.name}
        autoComplete="off"
        min={field.min}
        max={field.max}
        onChange={handleNumericChange}
        id={field.name + inputIndex + cardId + cardUid}
        value={value}
        placeholder=""
        addonBefore={prefix}
        addonAfter={suffix}
      />
    </Form.Item>
  );
}

export default InputNumeric;
