import { Form, Input, Select } from 'antd';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { Label } from '.';

const { Option } = Select;

function Lastname({ entityType, jurisdictionData, disabled, ignoreLabel, placeholder, ...rest }) {
  const formatMessage = useIntlMessage();
  const labelText = formatMessage('app.general.LastName');

  return (
    <Form.Item
      {...rest}
      label={ignoreLabel ? null : <Label>{labelText}</Label>}
      name="lastName"
      rules={[
        {
          required: true,
          whitespace: true,
          message: formatMessage('app.persons.validation.lastName'),
        },
      ]}
      placeholder={placeholder ? labelText : null}
    >
      <Input placeholder={placeholder ? labelText : null} />
    </Form.Item>
  );
}

function Corpform({ entityType, jurisdictionData, disabled, ignoreLabel, placeholder, ...rest }) {
  const formatMessage = useIntlMessage();
  const labelText = formatMessage('app.persons.corporateForm');
  return (
    <Form.Item
      {...rest}
      label={ignoreLabel ? null : <Label>{labelText}</Label>}
      name="form"
      placeholder={placeholder ? labelText : null}
    >
      {jurisdictionData && jurisdictionData.corporateForms && jurisdictionData.corporateForms.values ? (
        <Select disabled={disabled}>
          {jurisdictionData.corporateForms.values.map(({ id, code, label }) => (
            <Option key={id} value={code}>
              {label}
            </Option>
          ))}
        </Select>
      ) : (
        <Input placeholder={placeholder ? labelText : null} />
      )}
    </Form.Item>
  );
}

export const EntityEditFieldLastname = (props) => {
  const { entityType } = props;
  if (entityType === 'RealPerson') return <Lastname {...props} />;
  return <Corpform {...props} />;
};
