import { IS_EXTERNAL } from './env';

export const backendServer = process.env.REACT_APP_API_URL;
if (!backendServer) throw new Error('Must specify REACT_APP_API_URL');

export const frontendServer = process.env.REACT_APP_MT_FRONT_URL || 'http://localhost:8080';

const apiVersion = '1.0';
export const apiAddress = IS_EXTERNAL ? backendServer : `${backendServer}/api/v${apiVersion}/x`;

export const wsAddress = `ws${backendServer.replace(/^http/, '')}/ws`;
