import jwt_decode from 'jwt-decode';
import { useSelector } from 'react-redux';
import config from 'config/config';

const { applicationResourceIds } = config;
const { referringClientId } = applicationResourceIds;

export function useIsReferringClient() {
  const client = useSelector(({ auth }) => auth.client);
  return client && referringClientId === client.id;
}

export function useIsLender() {
  const client = useSelector(({ auth }) => auth.client);

  return client?.info?.corpMetaType === 'lender';
}
export function useIsBorrower() {
  const client = useSelector(({ auth }) => auth.client);
  return client?.info?.corpMetaType === 'borrower';
}

export function getLocalAuth() {
  const encodedAuthToken = localStorage.jwtToken;
  if (!encodedAuthToken) return 'none';

  const authObj = jwt_decode(encodedAuthToken);

  if (authObj.exp < Date.now() / 1000) return 'expired';

  return authObj;
}

export function useUser() {
  const user = useSelector(({ auth }) => auth.user);
  return user;
}
