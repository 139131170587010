import React, { memo } from 'react';
import { Route, Switch } from 'react-router-dom';


import Main from './main/index';
import Offers from './offers/index'
import Search from './search/index';
import Projects from './projects/index';
import Project from './project/index';
import Tasks from './tasks/index';

import Notifications from './notifications/index';

const App = memo(() => {
  return (
    <div className="main-content-wrapper">
      <Switch>
        <Route path={`/main`} render={() => <Main />} />
        <Route path={`/offers`} render={() => <Offers />} />
        <Route path={`/search`} render={() => <Search />} />
        <Route path={`/projects`} render={() => <Projects />} />
        <Route
          path={`/project/:id`}
          render={({ match }) => <Project projectId={match.params.id} projectUrl={match.url} />}
        />
        <Route path={`/tasks`} component={Tasks} />
        <Route path={`/notifications`} component={Notifications} />
      </Switch>
    </div>
  );
});

export default App;
