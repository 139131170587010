import React from 'react';
import { Row, Col } from 'antd';
import IntlMessages from 'util/IntlMessages';

const items = 3;
const texts = 3;

export default function Section1() {
  const children = Array(items)
    .fill(0)
    .map((x, i) => (
      <Col md={8} sm={24} className="col" key={i.toString()}>
        <h3>
          <IntlMessages id={`bb5.landing.section1.item${(i + 1).toString()}.title`} />
        </h3>
        <ul>
          {Array(texts)
            .fill(0)
            .map((y, j) => (
              <li key={j.toString()}>
                <p>
                  <IntlMessages
                    id={`bb5.landing.section1.item${(i + 1).toString()}.text${(j + 1).toString()}`}
                  />
                </p>
              </li>
            ))}
        </ul>
      </Col>
    ));

  return (
    <div className="landing-section section-1">
      {/* <h2 key="h2">Page 3</h2>
        <i key="i" className="line" /> */}
      <div className="content">
        <Row gutter={16}>{children}</Row>
      </div>
    </div>
  );
}
