import React from 'react';
import { useHistory } from 'react-router-dom';
import QueueAnim from 'rc-queue-anim';
import { Row, Col, Button } from 'antd';
import { useUser } from 'hooks';
import IntlMessages from 'util/IntlMessages';
import BannerImage from './BannerImage';

export default function Banner() {
  const history = useHistory();
  const user = useUser();

  const exploreUrl = user ? '/main' : '/signin';

  return (
    <div className={`landing-banner prim-bg`}>
      <Row>
        <Col md={12} className="banner-left">
          <h1 key="h2">
            <IntlMessages id="bb5.landing.banner.title" />
          </h1>
          <p key="p">
            <IntlMessages id="bb5.landing.banner.desc" />
          </p>
          <span key="button">
            <Button
              type="secondary"
              className='explore-btn'
              onClick={() => {
                history.push(exploreUrl);
              }}
            >
              <IntlMessages id="bb5.landing.explore" cap />
            </Button>
          </span>
        </Col>
        <Col md={12} className="banner-right">
          <BannerImage />
        </Col>
      </Row>
      {/* <div className="home-layout">
        <QueueAnim className={`home-banner-content-wrapper`} delay={300} ease="easeOutQuart">
          <h1 key="h2">
            <IntlMessages id="bb5.landing.banner.title" />
          </h1>
          <p key="p">
            <IntlMessages id="bb5.landing.banner.desc" />
          </p>
          <span key="button">
            <Button
              type="secondary"
              onClick={() => {
                history.push(exploreUrl);
              }}
            >
              <IntlMessages id="bb5.landing.explore" cap />
            </Button>
          </span>
        </QueueAnim>
        <div className={`home-banner-image-wrapper`}>
          <BannerImage />
        </div>
      </div> */}
    </div>
  );
}
