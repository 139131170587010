import React from 'react';
import { Layout } from 'antd';
import { useIsTemplateStudio, useYjsEditor } from 'hooks';
import EditTopbar from './EditTopbar';
import { TemplateSettingsMenu } from './TemplateSettingsMenu';

const { Header } = Layout;

function StudioTopbar({ activePagePaths }) {
  const isTemplate = useIsTemplateStudio();
  const { onlineMode, onlineLoaded } = useYjsEditor() || {};

  return (
    <Header className="studio-header" style={onlineMode && !onlineLoaded ? loadingStyle : {}}>
      {isTemplate && <TopLeft />}
      <EditTopbar />
    </Header>
  );
}

function TopLeft() {
  return (
    <div className="topbar-left">
      <TemplateSettingsMenu />
    </div>
  );
}

export default StudioTopbar;

const loadingStyle = {
  pointerEvents: 'none',
  mixBlendMode: 'overlay',
};
