import React, { memo } from 'react';
import ListOffers from 'components/Offers/ListOffers';

const Offers = memo(() => {
  return (
    <div className="padded-page">
      <ListOffers />
    </div>
  );
});

export default Offers;
