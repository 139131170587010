import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Layout } from 'antd';
import ErrorBoundry from 'containers/Error/index';
import Sidebar from '../Sidebar/index';
import { useIsNarrowView, setEventState } from 'hooks';
import App from 'routes/index';
import { setNumbersAll } from 'appRedux/actions';
import DevGlobals from 'utils/devGlobals'

const { Content } = Layout;

const MainApp = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setNumbersAll());
  }, [dispatch]);

  return (
    <Layout className="app-layout">
      <Sidebar />
      <Layout>
        <DrawerHamburger />
        <Content className={`layout-content`}>
          <ErrorBoundry>
            <App />
          </ErrorBoundry>
        </Content>
      </Layout>
    </Layout>
  );
};
export default MainApp;

export function DrawerHamburger({ className = '', style = {} }) {
  const narrowView = useIsNarrowView();
  const openDrawer = () => {
    setEventState('sidebar_drawer_open', true);
  };

  if (!narrowView) return null;

  return (
    <i
      className={`icon-btn mdi mdi-menu fs-xxxl drawer-hamburger ${className}`}
      // style={{ top: '4px', ...style, position: 'absolute', zIndex: 15 }}
      onClick={openDrawer}
    />
  );
}
