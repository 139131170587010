import React, { useEffect, memo } from 'react';
import { Row, Col, Alert } from 'antd';
import PropTypes from 'prop-types';
import manager from 'core/engine/manager';
import CardsContainer from './CardsContainer';
import TemplatePageActions from './PageActions';
import { useContract, useIsTemplateStudio, setEventState } from 'hooks';
import { Contract } from 'core/interfaces';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';

function CreatePage(props) {
  const { page, isActive } = props;
  const contract = useContract();
  const isTemplate = useIsTemplateStudio();
  const language = Contract.getLanguage(contract);
  const { collapse } = page;
  const formatMessage = useIntlMessage();

  useEffect(() => {
    if (!page) return;
    manager.navigateTo(page.scrollContractOnFocus);
  }, [page, isActive]);

  const addNewChildPage = () => {
    setEventState('template_edit_input_sections', {
      type: 'page',
      method: 'add',
      selected: { addChildPage: true, page },
    });
  };

  const mainCol = 24;

  if (collapse) {
    if (!isTemplate) return null;
    return (
      <div id={`creating-input-${page.paths.join('-')}`} className="creating-input">
        <Row className="create-page-row">
          <Col span={mainCol}>
            <div className="px-2 pb-2">
              <div className="sub-sections-holder p-2">
                {page.pages && Object.keys(page.pages).length > 0 && (
                  <Row className="">
                    <Col span={mainCol}>
                      <div className="p-1">
                        <h4>
                          <IntlMessages id="studio.template.pages.subPages" />
                        </h4>
                        <div className="subpages-holder">
                          Fix link to child pages.
                          {/* {Object.values(page.pages).map((childPage) => {
                              
                              const childLink = getStudioPageByPage(childPage);
                              return (
                                <div className="subpages-page" key={childPage.id}>
                                  <div>
                                    <Link to={childPath}>{childPage.header[language]}</Link>
                                  </div>
                                  <div className="d-flex">
                                    <div>Condition</div>
                                    <Divider type="vertical" />
                                    <div>Remove</div>
                                  </div>
                                </div>
                              );
                            })} */}
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
                {isTemplate && (
                  <Row className="">
                    <Col span={mainCol}>
                      <div className="p-1">
                        <div className="subpages-page add-new liftable clickable" onClick={addNewChildPage}>
                          <div className="">
                            <IntlMessages id="studio.template.modal.page.addChild" />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div id={`creating-input-${page.paths.join('-')}`} className="creating-input position-relative">
      {isTemplate && <TemplatePageActions page={page} />}
      <Row className="create-page-row">
        <Col span={mainCol} className="pb-4">
          {page ? (
            <CardsContainer page={page} language={language} />
          ) : (
            <Alert
              message={formatMessage('studio.template.pages.addButton')}
              description=" x"
              type="warning"
              style={{ minHeight: '120px' }}
            />
          )}
        </Col>
      </Row>
    </div>
  );
}

CreatePage.propTypes = {
  page: PropTypes.object.isRequired,
};

export default memo(CreatePage);
