import React, { useState, useMemo, useCallback } from 'react';
import { Row, Card, Spin, Select } from 'antd';
import { useSelector } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import { useFetch, useMountFetch, useIsReferringClient, useIsLender, useIsBorrower } from 'hooks';
import { gutter } from 'config';
import WidgetHeader from 'components/WidgetHeader/index';
import DocumentWidget from 'components/Widgets/DocumentWidget';
import config from 'config/config';

const { applicationResourceIds } = config;
const { documentTemplateIds } = applicationResourceIds;

const documentTemplateIdsUri = encodeURI(JSON.stringify(documentTemplateIds));

function ListOffersContent({
  allCount,
  documents,
  removeDocument,
  documentsError,
  docTemplatesById,
  pendingAccessTasks,
  isBorrower,
}) {
  if (documentsError) {
    return (
      <Card>
        <IntlMessages id="app.main.notRetrieveLatest" /> <IntlMessages id="app.documents" />
      </Card>
    );
  }

  if (allCount && documents.length === 0) {
    return (
      <div>
        <p>
          <IntlMessages id="general.noFilterResults" />.
        </p>
      </div>
    );
  }

  if (documents && documents.length === 0) {
    return (
      <div>
        <p>
          <IntlMessages id="app.main.noDocumentsCreated" />.
        </p>
        {isBorrower && (
          <p>
            <IntlMessages id="general.clickNewProject" />.
          </p>
        )}
      </div>
    );
  }

  if (!documents) {
    return <Spin className="loader-container" />;
  }

  return (
    <div className="document-main-list">
      <Row className="mt-2 pt-3" gutter={gutter}>
        {documents.map((doc) => (
          <DocumentWidget
            key={doc.id}
            doc={doc}
            removeDocument={removeDocument}
            template={docTemplatesById && docTemplatesById[doc.documentTemplateId]}
            pendingAccessTasks={pendingAccessTasks}
          />
        ))}
      </Row>
    </div>
  );
}

function _getArrayFromStorage(id) {
  const stringedFavourites = localStorage.getItem(id);
  if (!stringedFavourites) return [];
  try {
    const f = JSON.parse(stringedFavourites);
    return f;
  } catch (err) {
    console.log('No value');
  }
  return [];
}
function getFavourites() {
  return _getArrayFromStorage('bb5_favourites');
}
function getSeenDocuments() {
  return _getArrayFromStorage('bb5_seen_documents');
}

function getDocumentsUrl(user, isBorrower) {
  let url = `/documents?limit=15&order=desc&fields=id,byEntitId,name,description,projectId,clientId,status,documentTemplateId,createdAt`;
  if (!isBorrower) return url;
  return `${url}&where=byEntityId_->_${user.entityId}`;
}

export default function ListOffers({ reloadKey = 0 }) {
  const user = useSelector(({ auth }) => auth.user);
  const [filterType, setFilterType] = useState('all');
  const [documents, setDocuments] = useState(null);
  const [documentsError, setDocumentsError] = useState(null);
  const [documentTemplates, setDocumentTemplates] = useState(null);
  const [documentTemplatesError, setDocumentTemplatesError] = useState(null);
  const docTemplatesById = useMemo(
    () =>
      documentTemplates &&
      documentTemplates.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {}),
    [documentTemplates]
  );
  const isLender = useIsLender();
  const isReferringClient = useIsReferringClient();
  const isBorrower = useIsBorrower();
  const [pendingAccessTasks] = useMountFetch('/tasks/for?resourceType=Project&type=resource-access');

  const removeDocument = useCallback(
    (id) => {
      if (!documents) return;
      setDocuments(documents.filter((d) => d.id !== id));
    },
    [documents]
  );

  const filteredDocuments = useMemo(() => {
    if (filterType === 'all') return documents;
    if (filterType === 'favourites') {
      const favs = getFavourites();
      return documents.filter((d) => favs.includes(d.id));
    }
    if (filterType === 'not-seen') {
      const seen = getSeenDocuments();
      return documents.filter((d) => !seen.includes(d.id));
    }
    if (filterType === 'seen') {
      const seen = getSeenDocuments();
      return documents.filter((d) => seen.includes(d.id));
    }
  }, [documents, filterType]);

  useFetch(getDocumentsUrl(user, isBorrower), setDocuments, setDocumentsError, [reloadKey]);

  useFetch(
    `/documenttemplates?ids=${documentTemplateIdsUri}`,
    setDocumentTemplates,
    setDocumentTemplatesError
  );
  const title = `general.${isLender || isReferringClient ? 'latest' : 'my'}Offers`;

  return (
    <div className="mt-4">
      <WidgetHeader
        title={<IntlMessages id={title} cap />}
        styleName="mb-3"
        extra={
          <div>
            <Select value={filterType} onChange={setFilterType} style={{ minWidth: 130 }}>
              <Select.Option value="all">All</Select.Option>
              <Select.Option value="favourites">Favourites</Select.Option>
              <Select.Option value="not-seen">Not seen</Select.Option>
              <Select.Option value="seen">Seen</Select.Option>
            </Select>
          </div>
        }
      />
      <ListOffersContent
        allCount={documents?.length}
        documents={filteredDocuments}
        removeDocument={removeDocument}
        documentsError={documentsError}
        docTemplatesById={docTemplatesById}
        isLender={isLender}
        isBorrower={isBorrower}
        isReferringClient={isReferringClient}
        pendingAccessTasks={pendingAccessTasks}
      />
    </div>
  );
}
