import React from 'react';
import { Provider } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import 'assets/vendors/style';
import configureStore from './appRedux/store';
import App from './containers/App/index';

const createBrowserHistory = require('history').createBrowserHistory;

const history = createBrowserHistory();

const store = configureStore();

const NextApp = () => {
  return (
    <Provider store={store}>
      <Router basename="/" history={history}>
        <Switch>
          <Route path="/" render={() => <App />} />
        </Switch>
      </Router>
    </Provider>
  );
};

export default NextApp;
