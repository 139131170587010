import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  SET_NUMBER,
  SET_NUMBERS,
} from '../../constants/ActionTypes';
import api from 'utils/api';

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchSuccess = () => {
  return {
    type: FETCH_SUCCESS,
  };
};

export const fetchError = (error) => {
  return {
    type: FETCH_ERROR,
    payload: error,
  };
};

export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

export const setNumber = (id, value) => {
  return {
    type: SET_NUMBER,
    payload: { id, value },
  };
};

export const setNumbers = (obj) => {
  return {
    type: SET_NUMBERS,
    payload: obj,
  };
};

const COUNT_URLS = {
  projects: '/projects/count?where=isFolder_->_NULL-or-FALSE',
  folders: '/projects/count?where=isFolder_->_true',
  entities: '/entities/count',
  templates: '/documenttemplates/count',
  // pendingTasks: '/tasks/myCount?status=pending',
  newNotifications: '/notifications/my?type=new&mode=count',
};

const setNumberCustom = (id, url) => {
  return async (dispatch) => {
    const url = COUNT_URLS[id];
    if (!url) return;
    try {
      const result = await api.get(url);
      if (result.data && result.data.hasOwnProperty('count')) dispatch(setNumber(id, result.data.count));
      else dispatch(setNumber(id, 0));
    } catch (err) {
      dispatch(setNumber(id, 0));
    }
  };
};

export const setNumbersAll = () => {
  return (dispatch) => {
    const numbers = {};
    const entries = Object.entries(COUNT_URLS);
    const promises = entries.map(([id, url]) => api.get(url));
    Promise.allSettled(promises).then((results) => {
      results.forEach((result, index) => {
        if (result.status !== 'fulfilled' || !result.value.data || !result.value.data.hasOwnProperty('count'))
          return;
        const [itemName] = entries[index];
        numbers[itemName] = result.value.data.count;
      });
      dispatch(setNumbers(numbers));
    });
  };
};

export const setNumberProjects = () => {
  return setNumberCustom('projects');
};
export const setNumberFolders = () => {
  return setNumberCustom('folders');
};
export const setNumberEntities = () => {
  return setNumberCustom('entities');
};
export const setNumberTemplates = () => {
  return setNumberCustom('templates');
};
export const setNumberPendingTasks = () => {
  return setNumberCustom('pendingTasks');
};
