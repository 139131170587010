import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Row, Col, Button, notification, Form, Input, Radio } from 'antd';
import { useSelector } from 'react-redux';
import api from 'utils/api';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { useMountFetch } from 'hooks';
import { AutoModal } from 'components/ui';
import config from 'config/config';
import { smallGutter } from 'config';
import ListOffers from 'components/Offers/ListOffers';
import { EditOfferButton } from 'components/Offers/EditOffer';

const FormItem = Form.Item;

const { applicationResourceIds, defaultNames } = config;
const { projectTemplateIds, documentTemplateIds } = applicationResourceIds;
const [documentTemplateId] = documentTemplateIds;
const { documentName, documentDescription, projectDescription } = defaultNames;

const BorrowerView = () => {
  const [reloadKey, setReloadKey] = useState(0);
  return (
    <>
      <div>
        <NewBorrowerProject setReloadKey={setReloadKey} reloadKey={reloadKey} />
        <ListOffers isBorrower reloadKey={reloadKey} />
      </div>
    </>
  );
};

const NewBorrowerProject = ({ reloadKey, setReloadKey }) => {
  const closeModalFnRef = useRef();
  const [newDocId, setNewDocId] = useState(null);
  const [projectTemplates] = useMountFetch('/projecttemplates?fields=id,name');
  const eligibleTemplates = useMemo(
    () => projectTemplates && projectTemplates.filter((pt) => projectTemplateIds.includes(pt.id)),
    [projectTemplates]
  );

  const [form] = Form.useForm();

  const client = useSelector(({ auth }) => auth.client);

  if (Array.isArray(eligibleTemplates) && eligibleTemplates.length === 0) {
    return <div>No system access</div>;
  }

  const getCloseFunction = (fn) => (closeModalFnRef.current = fn);

  const createNewProject = async (values) => {
    const { projectTemplateId = projectTemplateIds[0], name } = values;
    const template = await api.get('/projecttemplates/' + projectTemplateId);
    if (!template) return console.log('No template');

    const newProjectData = {
      name,
      description: projectDescription,
      projectTemplateId,
      isSecret: false,
      data: {
        ...template.data.data,
        setup: {},
        roles: [],
      },
      info: template.data.info,
      customerId: null,
      __selectedEntities: [
        {
          entityId: client.entityId,
          withSubsidiaries: true,
        },
      ],
    };

    const newProject = await api.post('/projects', newProjectData);
    if (!newProject) {
      return notification.error({
        message: 'Error',
        description: 'Failed to create new project. Please try again later.',
      });
    }
    return newProject.data;
  };

  const onFinish = async () => {
    try {
      form.validateFields();
    } catch (err) {
      return notification.error({
        message: 'Error',
        description: 'Fill out required fields',
      });
    }
    const values = form.getFieldsValue();
    const project = await createNewProject(values);
    await createNewDocument(project);
  };

  const createNewDocument = async (project) => {
    const createResult = await api.post('/documents/new', {
      name: documentName,
      description: documentDescription,
      setup: {},
      projectId: project.id,
      documentTemplateId: documentTemplateId,
      returnType: ['ids'],
    });
    // xxx
    if (typeof closeModalFnRef.current === 'function') closeModalFnRef.current();
    console.log('CreateResult ', createResult);
    setNewDocId(createResult.data.documentId);
    setReloadKey(reloadKey + 1);
  };

  return (
    <div className="w-100 d-flex justify-content-center">
      <AutoModal
        trigger={
          <Button type="primary" size="large">
            <i className="mdi mdi-cube-outline mr-2" />
            <IntlMessages id="app.projects.NewProject" cap />
          </Button>
        }
        title={<IntlMessages id="app.projects.NewProject" cap />}
        okButton={
          <Button key="create" type="primary" onClick={onFinish}>
            <IntlMessages id="app.general.create" cap />
          </Button>
        }
        getCloseFunction={getCloseFunction}
      >
        <NewBorrowerDocument form={form} />
      </AutoModal>
      <div className="mt-2 mb-2">
        {newDocId && (
          <EditOfferButton
            doc={{ id: newDocId }}
            defaultOpen={true}
            trigger={<span className="link">Edit newly created document</span>}
          />
        )}
      </div>
    </div>
  );
};

function NewBorrowerDocument({ form }) {
  const inputRef = useRef();
  const formatter = useIntlMessage();
  const [projectTemplates] = useMountFetch('/projecttemplates?fields=id,name');
  const eligibleTemplates = useMemo(
    () => projectTemplates && projectTemplates.filter((pt) => projectTemplateIds.includes(pt.id)),
    [projectTemplates]
  );

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);

  return (
    <div>
      <Form form={form} autoComplete="off">
        {eligibleTemplates && eligibleTemplates.length > 1 && (
          <div>
            <h4>
              <IntlMessages id="app.general.Type" cap />
            </h4>
            <FormItem name="projectTemplateId">
              <Radio.Group size="large">
                {eligibleTemplates.map((pt) => (
                  <Radio.Button key={pt.id} value={pt.id}>
                    {pt.name}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </FormItem>
          </div>
        )}
        <Row gutter={smallGutter}>
          <Col md={12}>
            <FormItem
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Name cannot be empty',
                },
              ]}
            >
              <Input placeholder={formatter('app.projects.ProjectName')} ref={inputRef} />
            </FormItem>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default BorrowerView;
