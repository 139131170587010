import { deepEquals } from './object';

export function normalizeInsertDelta(delta) {
  const normalized = [];
  for (const element of delta) {
    if (typeof element.insert === 'string' && element.insert.length === 0) {
      continue;
    }

    const prev = normalized[normalized.length - 1];
    if (!prev || typeof prev.insert !== 'string' || typeof element.insert !== 'string') {
      normalized.push(element);
      continue;
    }

    const merge =
      prev.attributes === element.attributes ||
      (!prev.attributes === !element.attributes &&
        deepEquals(prev.attributes ?? {}, element.attributes ?? {}));

    if (merge) {
      prev.insert += element.insert;
      continue;
    }

    normalized.push(element);
  }

  return normalized;
}

export function yTextToInsertDelta(yText, nextSnap, prevSnap, computeYChange) {
  return normalizeInsertDelta(yText.toDelta(nextSnap, prevSnap, computeYChange(yText)));
}
