import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Col, Row, Card, Spin, Button, Dropdown, Menu, Avatar, List } from 'antd';
import { useHistory } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setNumber, setTasks, updateTask, setTaskOutcome } from 'appRedux/actions';
import WidgetHeader from 'components/WidgetHeader/index';
import { useEntityData, useMountFetch } from 'hooks';
import { fixDate, ListItem } from 'components/ui';
import IntlMessages from 'util/IntlMessages';
import api from 'utils/api';
import { gutter } from 'config';
import config from 'config/config';
import { DocumentSummaryButton } from 'components/Widgets/DocumentWidget';
import { ReferringClientExtraInfo } from 'components/Widgets/ProposalSummary';

const { applicationResourceIds } = config;
const { permissionAccessorId } = applicationResourceIds;

const APPROVE_REJECT_TASKS = ['document-approval', 'version-approval'];

function isTaskApproveReject(task) {
  return task && APPROVE_REJECT_TASKS.includes(task.type);
}

function filterTasks(tasks, filter) {
  if (!filter) return tasks;
  if (typeof filter === 'function') {
    return tasks.filter(filter);
  }
  if (filter && typeof filter === 'object') {
    return tasks.filter((task) => {
      let fail = false;
      for (const [key, value] of Object.entries(filter)) {
        if (task[key] !== value) {
          fail = true;
          break;
        }
      }
      return !fail;
    });
  }
}

export const useTasks = (props = {}) => {
  const { filter = null } = props;
  const reduxNumberPending = useSelector(({ common }) => common.numbers.pendingTasks);
  const tasks = useSelector(({ tasks }) => tasks);
  const filteredTasks = useRef(filterTasks(tasks, filter));
  const dispatch = useDispatch();

  function rupdateTask(newTask) {
    dispatch(updateTask(newTask.id, newTask));
  }

  function setTaskOutcomeStatus(id, value, options) {
    dispatch(setTaskOutcome(id, value, options));
  }

  useEffect(() => {
    filteredTasks.current = filterTasks(tasks, filter);
  }, [tasks, filter]);

  useEffect(() => {
    async function setPendingTasks() {
      const pendingTasksLength = tasks.filter((t) => t.status === 'pending').length;
      if (reduxNumberPending !== pendingTasksLength) {
        // console.log('Need update nums', pendingTasksLength);
        await api
          .get('/tasks/my')
          .then((res) => {
            if (!res.data) return;
            dispatch(setTasks(res.data));
          })
          .catch((err) => {
            console.log('Err setting tasks ', err);
          });
        dispatch(setNumber('pendingTasks', pendingTasksLength));
      }
    }
    setPendingTasks();
  }, [reduxNumberPending, tasks, dispatch]);

  return { tasks, filteredTasks: filteredTasks.current, updateTask: rupdateTask, setTaskOutcomeStatus };
};

const Tasks = (props) => {
  const { tasks, taskError, updateTask, setTaskOutcomeStatus } = useTasks({
    filter: {
      // resourceType: 'Version',
    },
  });

  // console.log('rerender tasks...');

  if (taskError) {
    return (
      <Card title="" className="card">
        <div>Cannot retrieve tasks at this time.</div>
      </Card>
    );
  }

  if (!tasks) {
    return (
      <Card title="" className="card">
        <Spin className="loader-container" />
      </Card>
    );
  }

  const pending = tasks.filter((task) => task.status === 'pending');
  const completed = tasks.filter((task) => task.status === 'completed');

  return (
    <>
      <div className="padded-page pb-4">
        <Row className="mb-4">
          <Col sm={24}>
            <div className="light-container p-5">
              <WidgetHeader
                title={
                  <>
                    <IntlMessages id="app.general.Pending" className="mr-1" />{' '}
                    <IntlMessages id="app.general.tasks.tasks" />
                  </>
                }
              />
              <div className="m-3">
                {pending.length === 0 ? (
                  <div>
                    <IntlMessages id="app.general.tasks.noPendingTasks" />
                  </div>
                ) : (
                  <Row gutter={gutter}>
                    {pending.map((task) => (
                      <Task
                        task={task}
                        key={task.id}
                        updateTask={updateTask}
                        setTaskOutcomeStatus={setTaskOutcomeStatus}
                      />
                    ))}
                  </Row>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-4 mb-4">
          <Col sm={24}>
            <div className="light-container p-5">
              <WidgetHeader
                title={
                  <>
                    <IntlMessages id="app.general.Completed" className="mr-1" />{' '}
                    <IntlMessages id="app.general.tasks.tasks" />
                  </>
                }
              />
              <div className="m-3">
                {completed.length === 0 ? (
                  <div>
                    <IntlMessages id="app.general.tasks.noCompletedTasks" />
                  </div>
                ) : (
                  <Row gutter={gutter}>
                    {completed.map((task) => (
                      <Task
                        task={task}
                        key={task.id}
                        updateTask={updateTask}
                        setTaskOutcomeStatus={setTaskOutcomeStatus}
                      />
                    ))}
                  </Row>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

/*
assignedEntityId: "4fc1403e-1bbb-46d2-b474-9930a37c1736"
assignedEntityType: "User"
assignedOrgId: "51672ad1-97c1-428a-8b12-8bb9726c8503"
clientId: "51672ad1-97c1-428a-8b12-8bb9726c8503"
createdAt: "2021-09-23T16:11:02.000Z"
data: null
description: "Test"
id: "ab672ad1-97c1-428a-8b12-8bb9726c89c4"
info: null
name: "Heh"
outcomeStatus: "completed"
   |_
    'pending',
    'failed',
    'completed',
resourceId: "7cb226c3-4f2d-4715-9a87-be543fafeffb"
resourceType: "Version"
status: "pending"
    |_
     'pending',
     'initiated',
     'aborted',
     'completed',
type: "version-approval"
updatedAt: "2021-09-23T16:11:02.000Z"
userId: "4fc1403e-1bbb-46d2-b474-9930a37c1736"
*/

const Task = ({ task, updateTask, setTaskOutcomeStatus }) => {
  const { id, status, outcomeStatus } = task;

  const setOutcomeStatus = (value) => {
    setTaskOutcomeStatus(id, value);
    /* api
      .post('/tasks/' + id + '/outcomeStatus', { value })
      .then((res) => updateTask(res.data))
      .catch((err) => console.log('err', err.response)); */
  };

  const approveTask = async (options = {}) => {
    const { grantAccess = false } = options;
    console.log('approve ', task);
    if (grantAccess) {
      const grantToPermAcc = await api.post('/permissions/grant', {
        accessorType: 'Client',
        accessorId: task.resourceId,
        resourceType: 'PermissionAccessor',
        resourceId: permissionAccessorId,
        groupName: 'member',
      });

      console.log('Result is ', { grantToPermAcc });
    }

    setOutcomeStatus('approved');
  };

  const rejectTask = async (options = {}) => {
    const { revokeAccess = false } = options;
    if (revokeAccess) {
      const result = await api.post('/permissions/revoke', {
        accessorType: 'User',
        accessorId: task.userId,
        resourceType: task.resourceType,
        resourceId: task.resourceId,
        groupName: 'member',
      });
      console.log('Result is ', result);
    }
    setOutcomeStatus('rejected');
  };

  const undeterminedTask = () => {
    setOutcomeStatus('pending');
  };

  const { actions } = useTaskContent(task, approveTask, rejectTask);

  let textClass, iconClass;

  switch (outcomeStatus) {
    case 'completed':
    case 'approved':
      textClass = 'text-success';
      iconClass = 'mdi-check-circle';
      break;
    case 'failed':
    case 'rejected':
      textClass = 'text-danger';
      iconClass = 'mdi-close-circle';
      break;
    default:
      textClass = 'text-muted';
      iconClass = 'mdi-help-circle';
  }

  // console.log('task ', task);

  const isTaskStandardActionable = isTaskApproveReject(task);
  const isCompleted = status === 'completed';
  const actionableOrCompleted = isTaskStandardActionable || isCompleted;

  return (
    <Col xl={8} lg={12} md={12} sm={12} xs={24}>
      <Card className="light-container widget mb-4">
        <Card.Meta
          className="text-center mb-2"
          title={<span style={{ fontWeight: 400 }}>{task.name}</span>}
          description={task.description}
        />
        <div>
          <div style={{ margin: '40px 0' }}>
            <table className="w-100">
              <tbody>
                <tr>
                  <td className="fs-sm mb-0">Task created</td>
                  <td className="fs-sm mb-0">{fixDate(task.createdAt)}</td>
                </tr>
                <tr>
                  <td
                    className="fs-sm"
                    // style={{ verticalAlign: 'top' }}
                  >
                    Requested by
                  </td>
                  <td>
                    <RequestedByUser task={task} />
                  </td>
                </tr>
                {/* <tr>
                <td className="text-grey fs-sm mb-0">Task details</td>
                <td className="text-grey fs-sm mb-0">{details}</td>
              </tr> */}
              </tbody>
            </table>
          </div>
          <div>{actions}</div>

          {status === 'pending' && isTaskStandardActionable && (
            <div className="">
              <h5>
                <IntlMessages id="app.general.tasks.handleTask" />
              </h5>
              <List className="document-actions" loading={false} itemLayout="horizontal" split={false}>
                <ListItem
                  title={<IntlMessages id="app.approval.approve" cap />}
                  desc={
                    <>
                      <IntlMessages id="app.approval.approve" cap />{' '}
                      <IntlMessages id="app.general.tasks.task" />
                    </>
                  }
                  mdiIcon="mdi-check"
                  onClick={approveTask}
                  loading={false}
                />
                <ListItem
                  title={<IntlMessages id="app.approval.reject" cap />}
                  desc={
                    <>
                      <IntlMessages id="app.approval.reject" cap />{' '}
                      <IntlMessages id="app.general.tasks.task" />
                    </>
                  }
                  mdiIcon="mdi-close"
                  onClick={rejectTask}
                  loading={false}
                />
              </List>
            </div>
          )}
          {isCompleted && (
            <div>
              <div className="mb-2">
                <span>Task Outcome:</span>
              </div>
              <div className="d-flex justify-content-space-between">
                <div>
                  <span>
                    <b className={'ml-1 text-uppercase ' + textClass}>
                      <i className={'mr-1 mdi ' + iconClass} />
                      {outcomeStatus}
                    </b>
                  </span>
                </div>
                {isTaskStandardActionable && (
                  <div>
                    <span>
                      <Dropdown
                        overlay={
                          <Menu>
                            <Menu.Item
                              key="approve"
                              onClick={approveTask}
                              disabled={outcomeStatus === 'approved'}
                            >
                              Approve
                            </Menu.Item>
                            <Menu.Item
                              key="reject"
                              onClick={rejectTask}
                              disabled={outcomeStatus === 'rejected'}
                            >
                              Reject
                            </Menu.Item>
                            <Menu.Divider />
                            <Menu.Item
                              key="pending"
                              onClick={undeterminedTask}
                              disabled={outcomeStatus === 'pending'}
                            >
                              Mark as pending
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <span className="link ant-dropdown-link">
                          Change <DownOutlined />
                        </span>
                      </Dropdown>
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </Card>
    </Col>
  );
};

function useTaskContent(task, approveTask, rejectTask) {
  const { type, resourceType, resourceId } = task;

  let details = null;
  let actions = null;

  switch (type) {
    case 'resource-access':
      actions = <ResourceAccessActions task={task} approveTask={approveTask} rejectTask={rejectTask} />;
      break;
    case 'system-access':
      actions = <SystemAccessActions task={task} approveTask={approveTask} rejectTask={rejectTask} />;
      break;
    default:
      details = null;
      actions = null;
      break;
  }

  return { details, actions };
}

function docProjectUrl(task) {
  if (!task?.resourceId) return '';
  return `/projects/${task.resourceId}?fields=id,name,clientId,userId`;
}

function ResourceAccessActions({ task, approveTask, rejectTask }) {
  const { resourceType } = task;
  console.log('access action task ', task);

  const [project] = useMountFetch(docProjectUrl(task));

  return (
    <>
      <div>
        <div className="mb-3">
          <ReferringClientExtraInfo project={project} />
          <DocumentSummaryButton
            doc={{ id: task.resourceId, name: task.description }}
            type="secondary"
            block
          />
        </div>
        <div className="d-flex">
          <h5>
            Access to: <strong>{resourceType}</strong>
          </h5>
        </div>

        <div>
          <Button
            type="primary"
            onClick={() => {
              approveTask({ grantAccess: true });
            }}
          >
            Approve
          </Button>
          <Button
            type="danger"
            onClick={() => {
              rejectTask();
            }}
          >
            Reject
          </Button>
        </div>
      </div>
    </>
  );
}

function SystemAccessActions({ task, approveTask, rejectTask }) {
  const { resourceType } = task;
  console.log('access action task ', task);

  return (
    <>
      <div>
        <div className="mb-3">
          <DocumentSummaryButton
            doc={{ id: task.resourceId, name: task.description }}
            type="secondary"
            block
          />
        </div>
        <div className="d-flex">
          <h5>
            Access to: <strong>{resourceType}</strong>
          </h5>
        </div>

        <div>
          <Button
            type="primary"
            onClick={() => {
              approveTask({ grantAccess: true });
            }}
          >
            Approve
          </Button>
          <Button
            type="danger"
            onClick={() => {
              rejectTask();
            }}
          >
            Reject
          </Button>
        </div>
      </div>
    </>
  );
}

function ProjectAccessActions({ task, approveTask, rejectTask }) {
  // console.log('access action task ', task);
  return (
    <>
      <div>
        <div className="mb-3">
          <DocumentSummaryButton
            doc={{ id: task.resourceId, name: task.description }}
            type="secondary"
            block
          />
        </div>
        <div className="d-flex">
          <h5>Project access</h5>
        </div>

        <div>
          <Button
            type="primary"
            onClick={() => {
              approveTask({ grantAccess: true });
            }}
          >
            Approve
          </Button>
          <Button
            type="danger"
            onClick={() => {
              rejectTask({ revokeAccess: true });
            }}
          >
            Reject
          </Button>
        </div>
      </div>
    </>
  );
}

function InvalidTaskDetails() {
  return <div>Invalid task information</div>;
}

function RequestedByUser({ task }) {
  const { requestingEntityId } = task;
  const entityData = useEntityData([requestingEntityId]);
  if (!entityData?.[requestingEntityId]) return null;
  const person = entityData[requestingEntityId];

  if (!person) return null;
  return `${person.firstName ?? ''} ${person.lastName ?? ''}`;
}

export default Tasks;
