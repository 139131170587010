import React, { useCallback } from 'react';
import { Drawer, Layout } from 'antd';
import { useIsNarrowView, useEventState, setEventState } from 'hooks';
import SidebarContent from './SidebarContent';

const { Sider } = Layout;

const Sidebar = ({ children }) => {
  const drawerOpen = useEventState('sidebar_drawer_open', true);

  const narrowView = useIsNarrowView();

  const toggleDrawer = useCallback(() => {
    setEventState('sidebar_drawer_open', !drawerOpen);
  }, [drawerOpen]);

  let drawerStyle;
  if (narrowView) {
    drawerStyle = 'collapsed-sidebar';
  } else {
    drawerStyle = 'custom-sidebar';
  }

  const content = <SidebarContent narrowView={narrowView} />;

  return (
    <Sider
      className={`app-sidebar ${drawerStyle} full-sidebar`}
      trigger={null}
      // collapsed={narrowView ? false : false}
      theme="lite"
    >
      {narrowView ? (
        <Drawer
          className={`drawer-sidebar`}
          placement="left"
          closable={true}
          onClose={toggleDrawer}
          visible={drawerOpen}
        >
          {content}
        </Drawer>
      ) : (
        content
      )}
    </Sider>
  );
};
export default Sidebar;
