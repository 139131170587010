import calcTotalLoanAmount from './inputs/calcTotalLoanAmount';

const inputs = [
  {
    trigger: 'facility->pricing/upfront',
    function: calcTotalLoanAmount,
    info: {
      name: 'Automatically name facilities depending on types',
      category: 'legal',
    },
  },
];

export default inputs;
