import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { fixInput, setEntities } from 'appRedux/actions';
import { createBaseEditor } from '../../core/utils/editor';
import manager from 'core/engine/manager';
import { getFirstStudioPage } from 'utils/draftPath';
import api from 'utils/api';
import { Contract } from 'core/interfaces';
import { updateLoadedContract } from 'core/utils/initDraft/updateContract';
import { generateTemplateInputState } from './helpers/';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import config from 'config/config';

// import { generateStateValues } from 'core/utils/initDraft/generateStateValues';

const { TEMPLATE_SUBJECTS, COLLAB_SUBJECTS, ENGINE_SUBJECTS } = config.studio;

export default function useStudioLoad(subject) {
  const dispatch = useDispatch();
  // const currentWarnings = useSelector(({ draft }) => draft.warnings);
  const history = useHistory();

  const obj = useMemo(() => {
    return {};
  }, []);

  if (obj.loadFuntion) return obj;

  obj.loadFuntion = async function loadResource(id) {
    console.log('=== BEGIN LOAD ===');

    let resource;
    let entities = [];
    let state = {};
    // let accessLevel;
    let originalAccessLevel;
    let effectiveAccessLevel;
    if (TEMPLATE_SUBJECTS.includes(subject)) {
      try {
        const fetchTemplate = await api.get(`/documenttemplates/${id}/load`);
        resource = fetchTemplate.data.template;
        effectiveAccessLevel = fetchTemplate.data.effectiveAccessLevel;
        originalAccessLevel = fetchTemplate.data.originalAccessLevel;
      } catch (err) {
        console.log('Could not find id ', id, err, err.response);
        return null;
      }
    } else if (COLLAB_SUBJECTS.includes(subject)) {
      try {
        const loadVersion = await api.get(`/documents/${id}/load`);
        if (!loadVersion?.data?.version) {
          notification.error({
            message: 'Invalid document version.',
            duration: 10,
          });
          return history.push(`/projects`);
        }
        resource = loadVersion.data.version;
        resource = await updateLoadedContract(resource);
        effectiveAccessLevel = loadVersion.data.effectiveAccessLevel;
        originalAccessLevel = loadVersion.data.originalAccessLevel;

        if (resource.status === 'signed') {
          const { projectId, documentId } = resource;
          notification.error({
            message: 'An already signed Version cannot be edited.',
            duration: 10,
          });
          return history.push(`/project/${projectId}/document/${documentId}/version/${id}/overview`);
        }

        if (loadVersion.data.state) {
          state.input = loadVersion.data.state.input;
        }

        if (loadVersion.data.entities) {
          entities = loadVersion.data.entities;
          state.entities = entities;
        }
      } catch (err) {
        console.log('Could not find id ', id, err, err.response);
        return null;
      }
    } else {
      throw new Error('Invalid subject ' + subject);
    }

    if (!validateResource(resource)) return console.log('Cannot load version');

    Contract.ensurePagePaths(resource);

    if (subject !== 'template' && !state) {
      // Something is seriously wrong with this contract.
      return console.log('resource is invalid', resource);
    }

    const editor = createBaseEditor();
    manager.clearDrafts();

    if (subject === 'template') {
      const templateInputState = generateTemplateInputState(resource);
      dispatch(fixInput(templateInputState));
    } else {
      if (state.input) dispatch(fixInput(state.input));
      if (entities) dispatch(setEntities(entities));
    }

    /* if (ENGINE_SUBJECTS.includes(subject) && effectiveAccessLevel === 'write') {
      manager.addDraft(resource, { editor });
    } */

    console.log('=== FINISHED LOAD ===');

    return {
      studioPage: getFirstStudioPage(resource),
      contract: resource,
      editor,
      originalAccessLevel,
      effectiveAccessLevel,
    };
  };

  return obj;
}

function validateResource(resource) {
  if (!resource || !resource.data) {
    return console.warn('Invalid loadresource args', resource);
  }
  return true;
}
