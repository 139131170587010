import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Row, Tag } from 'antd';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useProjectMainEntities, useYjsState } from 'hooks';
import { Contract, Entity, State } from 'core/interfaces';
import { CardSkeleton } from '../../../../components/Skeletons/Overview';
import AboutItem from 'components/project/Info/InfoItem';
import { splitNumber } from 'core/utils/general';
import { supportedCurrenciesColors } from 'core/config/constants';
import { fixDate, userToName } from 'components/ui';
import IntlMessages from 'util/IntlMessages';
import applyLogic from 'core/utils/logic/applyLogic';
import fixNumbers, { fixCopyNumbersListener } from 'components/ui/fixNumbers';

const DEFAULT_DOC_STATUS_SHORT_VIEW = ['draft'];

function defaultShortView(document) {
  if (!document) return false;
  const { status } = document;
  return DEFAULT_DOC_STATUS_SHORT_VIEW.includes(status);
}

export default function Summary({ project, document, contract, options = {} }) {
  const [shortView, setShortView] = useState(false);
  const yjsState = useYjsState();
  const contractState = useSelector(State.produceContractState);
  const intl = useIntl();

  useEffect(() => {
    window.document.addEventListener('copy', fixCopyNumbersListener);
    return () => window.document.addEventListener('copy', fixCopyNumbersListener);
  }, []);

  useEffect(() => {
    if (!document) return;
    setShortView(defaultShortView(document));
  }, [document]);

  const toggleShortView = () => setShortView(!shortView);

  if (
    !document ||
    !contract ||
    !yjsState ||
    !yjsState.connected ||
    !contractState?.input ||
    Object.keys(contractState.input).length === 0
  ) {
    return <CardSkeleton title={<IntlMessages id="app.general.Summary" cap />} />;
  }

  if (!contract.info) return null;
  const { summary } = contract?.info;
  const showDynamicSummary = !!summary && !!contract?.data;

  const findValues = (fieldPath, text, fallbackText, prefix = '', suffix = '', acp) => {
    const { input } = contractState;
    const allPaths = findPaths(fieldPath, acp);
    return allPaths.map((path) => {
      if (path === null || path === undefined) return path;
      const pathArray = path.split('.');
      const value = text ?? pathArray.reduce((store, key) => store?.[key], input);
      const label = findLabel(pathArray.slice(-1), value, fallbackText);
      return label ? (
        <span>
          {prefix}
          {label}
          {suffix}
        </span>
      ) : (
        label
      );
    });
  };

  const findPaths = (fieldPath, acp) => {
    const { input } = contractState;
    const path = fieldPath.split('.');
    if (!path.includes('_')) return [fieldPath].map((p) => filterACP(p, acp));
    if (!input[path[0]]) {
      console.warn('No ', path, 'available in inputs');
      return [];
    }
    const allPaths = Object.keys(input[path[0]]).map((repeatable) => `${path[0]}.${repeatable}.${path[2]}`);
    return allPaths.map((p) => filterACP(p, acp));
  };

  const filterACP = (path, acp) => {
    const keys = path.split('.');
    const local = contractState.input[keys[0]][keys[1]];
    const rule = Contract.getRule(contract, acp);
    const approved = !rule || applyLogic(rule.data, contractState, { _meta: { data: { local } } });
    return approved ? path : undefined;
  };

  const findLabel = (field, value, fallbackText = '') => {
    const { entities } = contractState;
    if (value?.id)
      // check type instead when it's fixed from being null
      return (
        <a className="link" href={`/entity/${value.id}`}>
          {Entity.name(entities.find(({ id }) => value.id === id))}
        </a>
      );
    // build date? need multiple values tho
    const valueDefinition = contract.data.ui.inputs[field]?.content?.find(({ id }) => id === value);
    const label = valueDefinition?.label[intl.locale.split('-')[0]] ?? value ?? fallbackText;
    return fixNumbers(label);
  };

  const { withHeader = true } = options;

  return (
    <Card
      className="widget light-container"
      title={withHeader && <IntlMessages id="app.general.Summary" cap />}
    >
      <Row gutter={16} className="mb-3">
        {showDynamicSummary && (
          <DynamicSummary
            summary={summary}
            toggleShortView={toggleShortView}
            findValues={findValues}
            limit={shortView ? 1 : undefined}
          />
        )}
        {!showDynamicSummary && <StaticSummary project={project} contract={contract} />}
      </Row>
    </Card>
  );
}

export const DynamicSummary = ({ summary, toggleShortView, findValues, limit }) => {
  const additionalSections = limit ? summary.sections.length - limit : 0;
  return (
    <>
      {summary.sections.slice(0, limit).map((props, i) => (
        <Section key={i} {...{ ...props, i, findValues }} />
      ))}
      {additionalSections ? (
        <div className="d-inline" style={{ paddingLeft: 12, paddingTop: 24 }}>
          <span className="mr-3"> . . . and {additionalSections} more sections. </span>
          <Button type="secondary" className="m-0" onClick={toggleShortView}>
            <IntlMessages id="desc.expand" cap />
          </Button>
          {/* <small className="mt-2 w-100 pl-3">
          {' '}
          . . . and {additionalSections} more sections.{' '}
          <span className="link" onClick={toggleShortView}>
            <IntlMessages id="general.viewAll" cap />
          </span>
        </small> */}
        </div>
      ) : null}
    </>
  );
};

const Section = ({ i, findValues, title, type, content }) =>
  type === 'group' ? (
    <GroupSection {...{ isFirst: i === 0, findValues, title, type, content }} />
  ) : (
    <>
      <Title i={i} title={title} isFirst={i === 0} />
      {content.reduce(
        (
          store,
          {
            title,
            types = [],
            acp,
            merge,
            fieldPath,
            text,
            fallbackText,
            prefix,
            suffix,
            iconClass,
            color = 'primary',
            size = 'md',
          },
          key
        ) => {
          const xtitle = store[store.length - 1]?.props.xtitle;
          const xvalues = store[store.length - 1]?.props.xvalues ?? [];
          const values = [...new Set(findValues(fieldPath, text, fallbackText, prefix, suffix, acp))]
            .map((value, i) => {
              if (value === undefined && (merge !== 'concat' || !xvalues[i].props.value)) return undefined;
              if (merge === 'concat')
                value = (
                  <span>
                    {xvalues[i].props.value} {value ?? ''}
                  </span>
                );
              return <Label key={`${key}-${i}`} i={i} types={types} value={value} />;
            })
            .filter((value) => value !== undefined);
          const firstTitle = merge ? xtitle : title;
          const desc = merge === 'append' ? [...xvalues, ...values] : values;
          const value = (
            <Column key={key} size={size} xtitle={firstTitle} xvalues={desc}>
              <AboutItem
                data={{
                  title: firstTitle,
                  icon: iconClass,
                  desc,
                  color,
                  extendedBorder: types.includes('connect') && key < content.length - 1,
                }}
              />
            </Column>
          );
          if (merge && values.length) store[store.length - 1] = value;
          else store.push(values.length ? value : null);
          return store;
        },
        []
      )}
    </>
  );

const GroupSection = ({ isFirst, findValues, title, content }) => {
  let previousMerge = [];
  const rows = content.reduce(
    (
      store,
      { title, acp, types = [], merge, fieldPath, text, fallbackText, prefix, suffix, iconClass, color }
    ) => {
      const values = findValues(fieldPath, text, fallbackText, prefix, suffix, acp);
      for (let i = 0; i < values.length; i++) {
        const mergeNotInterupted = previousMerge[i] !== undefined;
        if (values[i] === undefined) {
          // hidden field with acp rules
          if (mergeNotInterupted) previousMerge[i] = (merge && previousMerge[i]) || merge;
        } else {
          const lastLabels = store[i]?.[store[i].length - 1]?.labels;
          const concat =
            (mergeNotInterupted && merge && previousMerge[i] === 'concat') ||
            (merge === 'concat' && !previousMerge[i]);
          const value = concat ? (
            <span>
              {lastLabels[lastLabels.length - 1].props.value} {values[i]}
            </span>
          ) : (
            values[i]
          );
          const label = <Label key={i} i={i} types={types} value={value} />;
          const labels =
            merge && mergeNotInterupted ? [...lastLabels.slice(0, concat ? -1 : undefined), label] : [label];
          const column = { title, labels: labels, types, iconClass, color, merge };
          if (!store[i]) store[i] = [];
          if (merge && mergeNotInterupted) store[i][store[i].length - 1] = column;
          else store[i].push(column);
          previousMerge[i] = false;
        }
      }
      return store;
    },
    []
  );
  return rows.map((row, i) => (
    <Fragment key={i}>
      <Title i={i} title={title} isFirst={isFirst} onlyTitleOnFirst />
      {row.map(({ title, labels, types, iconClass, color = 'primary', size = 'md' }, key) => (
        <Column key={key} size={size}>
          <AboutItem
            data={{
              title,
              icon: iconClass,
              desc: labels,
              color,
              extendedBorder: types.includes('connect') && key < row.length - 1,
            }}
          />
        </Column>
      ))}
    </Fragment>
  ));
};

const Column = ({ size, children, xtitle, xvalues }) => {
  const sizeProps = {
    xl: size === 'xl' ? 24 : size === 'lg' ? 16 : 8,
    lg: size === 'xl' ? 24 : size === 'lg' ? 16 : 12,
    md: size === 'xl' ? 24 : size === 'lg' ? 24 : 12,
    sm: size === 'xl' ? 24 : size === 'lg' ? 24 : 12,
    xs: 24,
  };
  return (
    <Col {...sizeProps} xtitle={xtitle} xvalues={xvalues}>
      {children}
    </Col>
  );
};

const Title = ({ i, title, isFirst, onlyTitleOnFirst }) => {
  const hasTitle = title && (!onlyTitleOnFirst || i === 0);
  const style = {
    width: '100%',
    fontWeight: 900,
    color: '#aaa',
    marginTop: isFirst ? 0 : hasTitle ? 42 : 24,
  };

  if (i === 0) return <p style={style}>{title}</p>;
  return <p style={style}>{onlyTitleOnFirst ? null : title}</p>;
};

const Label = ({ i, types = [], value }) =>
  types.includes('tag') ? <TagLabel i={i} label={value} /> : <p className="m-0">{value}</p>;

const TagLabel = ({ i, label }) =>
  label ? (
    <Tag key={i} color={'orange'}>
      {label}
    </Tag>
  ) : null;

function StaticSummary({ project, contract }) {
  const mainEntities = useProjectMainEntities(project);
  const language = Contract.getLanguage(contract);

  const { _summary = {} } = contract?.info;
  const {
    values = {},
    valuesData,
    contractParties,
    clauseStatuses = 0,
    contractTasks = 0,
    contentCounts = {},
  } = _summary;
  const { contractValue, terminationDate, jurisdiction } = values;
  const { sections, headings, clauses } = contentCounts;

  let contractValueContent = contractValue || <em>Unknown</em>;
  if (valuesData && valuesData.contractValue) {
    contractValueContent = (
      <div>
        {' '}
        <div>
          {Object.entries(valuesData.contractValue).map(([currency, amount]) => (
            <Tag key={currency} color={supportedCurrenciesColors[currency]}>{`${currency} ${splitNumber(
              amount,
              language
            )}`}</Tag>
          ))}
        </div>
        {valuesData.contractValueTotal && (
          <>
            {/* <Divider orientation="left" className="m-0">
              Total
            </Divider> */}
            <small className="d-block ml-2">
              <strong>Total</strong>
            </small>
            <Tag color={supportedCurrenciesColors[valuesData.contractValueTotal.currency]}>{`${
              valuesData.contractValueTotal.currency
            } ${splitNumber(valuesData.contractValueTotal.amount, language)}`}</Tag>
          </>
        )}
      </div>
    );
  }

  let contractPartyTags = null;
  if (contractParties && contractParties.length > 0) {
    contractPartyTags = contractParties.map((party) => (
      <Tag key={party.id} color={'orange'}>
        {userToName(party)}
      </Tag>
    ));
  } else {
    contractPartyTags = <em>No parties</em>;
  }
  return (
    <>
      <Col xl={12} lg={24} md={24} sm={24} xs={24}>
        <AboutItem
          data={{
            title: <IntlMessages id="apply.parties" />,
            icon: 'mdi-account-multiple',
            desc: contractPartyTags,
            color: 'primary',
          }}
        />
      </Col>
      {mainEntities &&
        mainEntities.length > 0 &&
        mainEntities.map((entity) => (
          <Col key={entity.id} xl={12} lg={24} md={24} sm={24} xs={24}>
            <AboutItem
              data={{
                title: 'Entity',
                icon: Entity.isLegalPerson(entity) ? 'mdi-city' : 'mdi-account',
                desc: <p>{Entity.name(entity)}</p>,
                color: 'primary',
              }}
            />
          </Col>
        ))}
      <Col xl={12} lg={12} md={12} sm={12} xs={24}>
        <AboutItem
          data={{
            title: 'Clauses to track',
            icon: 'mdi-format-section',
            desc: clauseStatuses || <em>None</em>,
            color: 'primary',
          }}
        />
      </Col>
      <Col xl={12} lg={12} md={12} sm={12} xs={24}>
        <AboutItem
          data={{
            title: 'Contract Tasks',
            icon: 'mdi-spotlight',
            desc: contractTasks || <em>None</em>,
            color: 'primary',
          }}
        />
      </Col>
      <Divider />
      <Col xl={8} lg={12} md={12} sm={12} xs={24}>
        <AboutItem
          data={{
            title: 'Contract Value',
            icon: 'mdi-diamond',
            desc: contractValueContent,
            color: 'primary',
          }}
        />
      </Col>
      <Col xl={8} lg={12} md={12} sm={12} xs={24}>
        <AboutItem
          data={{
            title: 'Termination Date',
            icon: 'mdi-calendar-remove',
            desc: terminationDate ? fixDate(terminationDate, { onlyDate: true }) : <em>Unknown</em>,
            color: 'primary',
          }}
        />
      </Col>
      <Col xl={8} lg={12} md={12} sm={12} xs={24}>
        <AboutItem
          data={{
            title: 'Disputes',
            icon: 'mdi-gavel',
            desc: jurisdiction ? <Tag>{jurisdiction}</Tag> : <em>Unknown</em>,
            color: 'primary',
          }}
        />
      </Col>
      <Divider />

      <Col xl={8} lg={12} md={12} sm={12} xs={24}>
        <AboutItem
          data={{
            title: 'Sections',
            icon: 'mdi-vector-square',
            color: 'primary',
            desc: sections || 0,
          }}
        />
      </Col>
      <Col xl={8} lg={12} md={12} sm={12} xs={24}>
        <AboutItem
          data={{
            title: 'Main Headings',
            icon: 'mdi-format-header-1',
            color: 'primary',
            desc: headings || 0,
          }}
        />
      </Col>
      <Col xl={8} lg={12} md={12} sm={12} xs={24}>
        <AboutItem
          data={{
            title: 'Clauses total',
            icon: 'mdi-format-section',
            color: 'primary',
            desc: clauses || 0,
          }}
        />
      </Col>
    </>
  );
}
