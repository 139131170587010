import { useState, useEffect } from 'react';
import api from 'utils/api';
import { useHistory, useRouteMatch } from 'react-router-dom';

export function useDraftBaseRoute() {
  const match = useRouteMatch();
  if (match.path !== '/draft/:id/:action' || match.path !== '/studio/:subject/:id/:action') return null;
  if (!match.params.id) return null;
  const { subject, id } = match.params;
  if (subject) return `/studio/${subject}/${id}`;
  else return `/${subject}/${id}`;
  // return "/draft/" + match.params.id;
}

export function useTemplateEditBaseRoute() {
  const match = useRouteMatch();
  if (match.path !== '/tedit/:id/:action') return null;
  if (!match.params.id) return null;
  return '/tedit/' + match.params.id;
}

export function useStudioBaseRoute() {
  const match = useRouteMatch();
  if (match.path !== '/studio/:subject/:id/:action') {
    const parts = match.url.split('/');
    if (parts[1] === 'studio' && (parts[2] === 'draft' || parts[2] === 'template'))
      return parts.slice(0, 4).join('/');
    return null;
  }
  if (!match.params.id) return null;
  const { subject, id } = match.params;
  return `/studio/${subject}/${id}`;
}

export function useIsTemplateStudio() {
  return window.location.pathname.startsWith('/studio/template/');
}
export function getIsTemplateStudio() {
  return window.location.pathname.startsWith('/studio/template/');
}
export function useIsReviewStudio() {
  return window.location.pathname.startsWith('/studio/review/');
}
export function getIsReviewStudio() {
  return window.location.pathname.startsWith('/studio/review/');
}

/* Documents, go to etc */

export const useGotoDraft = (id, opts = {}) => {
  const history = useHistory();
  const { target = 'document', replace } = opts;

  if (!id) return () => {};
  if (target === 'document') {
    return () => {
      api
        .get('/documents/' + id + '/versions?last&fields=id')
        .then((result) => {
          const path = `/studio/draft/${result.data.id}`;
          if (replace) history.replace(path);
          else window.open(path, '_blank');
        })
        .catch((err) => {
          console.log('Cannot fetch last version', err.response);
        });
    };
  }

  if (target === 'version') {
    return () => {
      const path = `/studio/draft/${id}`;
      if (replace) history.replace(path);
      else window.open(path, '_blank');
    };
  }

  return () => {};
};

export const useGotoDocumentView = ({ documentId, projectId }) => {
  const history = useHistory();

  if (!documentId || !projectId) {
    console.warn('useGotoDocumentView requires documentId and projectId');
    return;
  }

  return () => history.push('/project/' + projectId + '/document/' + documentId + '/overview');
};

export const useGotoVersionView = ({ versionId, documentId, projectId }) => {
  const history = useHistory();

  if (!documentId || !projectId) {
    if (versionId) {
      return () => {
        history.push('/versions/' + versionId);
      };
    }
    console.warn('useGotoVersionView requires documentId and projectId');
    return;
  }

  if (!versionId) {
    return () => {
      api
        .get('/documents/' + documentId + '/versions?last&fields=id')
        .then((result) => {
          history.push(
            '/project/' + projectId + '/document/' + documentId + '/version/' + result.data.id + '/overview'
          );
        })
        .catch((err) => {
          console.log('Cannot fetch last version', err.response);
        });
    };
  }
  return () => {
    history.push('/project/' + projectId + '/document/' + documentId + '/version/' + versionId + '/overview');
  };
};

export const useLatestDocumentVersion = (documentId, options = {}) => {
  const { fields } = options;
  const [latestVersion, setLatestVersion] = useState(null);

  useEffect(() => {
    if (!documentId) return;
    let url = '/documents/' + documentId + '/versions?last';
    if (fields) url += '&fields=' + fields;
    api
      .get(url)
      .then((result) => {
        setLatestVersion(result.data);
      })
      .catch((err) => {
        console.log('Cannot fetch last version', err.response);
      });
  }, [documentId, fields]);
  return latestVersion;
};
