import React, { memo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { ConfigProvider, Modal } from 'antd';
import { IntlProvider } from 'react-intl';
import OverlaySignIn from './OverlaySignIn';
import { ProjectContext, ModalContext } from 'contexts/contexts';
import AppLocale from 'lngProvider';
import MainApp from './MainApp';

import SignIn from '../SignIn';
import SignUp from '../SignUp';

import Studio from '../../routes/studio';

import { setInitUrl, setTasks, updateClient, setClient } from 'appRedux/actions';

import VerifyUser from 'routes/users/VerifyUser';
import ForgotPassword from 'routes/users/ForgotPassword';
import ResetPassword from 'routes/users/ResetPassword';
import Landing from 'routes/landing/';

import CustomModal from 'components/CustomModal/index';
import CustomAlert from 'components/CustomAlert/index';

import api from 'utils/api';

const RestrictedRoute = ({ component: Component, hasUser, location, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        hasUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
            }}
          />
        )
      }
    />
  );
};

const App = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const locale = useSelector((state) => state.settings.locale);
  const { user, initURL } = auth;

  const location = useLocation();
  const history = useHistory();

  const hasUser = user && !!user.id;

  ConfigProvider.config({
    theme: {
      successColor: '#00211f',
    },
  });

  useEffect(() => {
    if (user && user.clientId && !user.isEntityUser) dispatch(updateClient(user.clientId));
    else dispatch(setClient(null));
  }, [dispatch, user]);

  useEffect(() => {
    if (!auth.user || location.search === '?passthrough') return;
    api
      .get('/tasks/my')
      .then((res) => {
        if (!res.data) return;
        dispatch(setTasks(res.data));
      })
      .catch((err) => {
        console.log('Err setting tasks ', err);
      });
  }, [dispatch]);

  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(history.location.pathname));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const goto = useCallback(
    (path) => {
      history.push(path);
    },
    [history]
  );

  useEffect(() => {
    if (location.pathname === '/') {
      if (!hasUser) {
        goto('/landing', initURL, 1, location.pathname);
      } else if (initURL === '' || initURL === '/' || initURL === '/signin' || initURL === '/verify') {
        // console.log('Shall change to main')
        goto('/main', initURL, 2, location.pathname);
      } else {
        goto(initURL, initURL, 3, location.pathname);
      }
    } else if (location.pathname === '/signin') {
      if (hasUser) {
        if (initURL && initURL !== '/') {
          if (initURL === '/verify' || initURL === '/resetpwd' || initURL === '/landing') {
            goto('/main', '/main', 6, location.pathname);
          } else {
            goto(initURL, initURL, 4, location.pathname);
          }
          dispatch(setInitUrl('/main'));
        } else goto('/main', initURL, 5, location.pathname);
      }
    } else if (location.pathname === '/signup') {
      if (hasUser) {
        goto('/main', '/main', 6, location.pathname);
      }
    } else if (location.pathname === '/forgotpwd') {
      if (hasUser) {
        goto('/main', '/main', 6, location.pathname);
      }
    } else if (!hasUser && location.pathname !== '/landing') {
      goto('/landing', initURL, 1, location.pathname);
    }
  }, [hasUser, initURL, location, history, goto, dispatch]);

  const currentAppLocale = AppLocale[locale.locale];

  const showReAuth = auth.authOverlay;

  return (
    <ConfigProvider locale={currentAppLocale.antd} theme={{ primaryColor: '#00211f' }}>
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        <ModalContext.Provider initialValue={null}>
          <CustomModal />
          <CustomAlert />
          <ProjectContext.Provider initialValue={null}>
            <Switch>
              <Route path={`/landing`} render={() => <Landing />} />
              <Route exact path="/signin" component={SignIn} />
              <Route exact path="/signup" component={SignUp} />
              <Route exact path="/verify" component={VerifyUser} />
              <Route exact path="/forgotpwd" component={ForgotPassword} />
              <Route exact path="/resetpwd" component={ResetPassword} />
              {showReAuth && (
                <Modal title={null} visible={showReAuth} closable={false} footer={null}>
                  <OverlaySignIn />
                </Modal>
              )}
              <RestrictedRoute path="/studio/:subject/:id" hasUser={hasUser} component={Studio} />
              <RestrictedRoute path={`/`} hasUser={hasUser} component={MainApp} />
            </Switch>
          </ProjectContext.Provider>
        </ModalContext.Provider>
      </IntlProvider>
    </ConfigProvider>
  );
};

export default memo(App);
