import React from 'react';
import { Row, Col } from 'antd';
import IntlMessages from 'util/IntlMessages';
import img1 from 'assets/images/page1_img1.png';
import img2 from 'assets/images/page1_img2.png';
import img3 from 'assets/images/page1_img3.png';

const images = [img1, img2, img3];

const items = 3;

export default function Page3() {
  const children = Array(items)
    .fill(0)
    .map((d, i) => (
      <Col key={i} className="col" md={8} sm={24}>
        <div key="image" className="image">
          <img src={images[i]} alt="feature" />
        </div>
        <h3 key="h3">
          <IntlMessages id={`bb5.landing.section3.item${(i + 1).toString()}.title`} />
        </h3>
        <p key="p">
          <IntlMessages id={`bb5.landing.section3.item${(i + 1).toString()}.text`} />
        </p>
      </Col>
    ));
  return (
    <div className="landing-section section-3">
      <h2>
        <IntlMessages id="bb5.landing.section3.title" />
      </h2>
      <i className="line" />
      <div style={{ margin: '0 40px' }}>
        <Row gutter={150}>{children}</Row>
      </div>
    </div>
  );
}

/* <QueueAnim
            key="content"
            component={Row}
            type="bottom"
            componentProps={{ gutter: 171 }}
          >
            {children}
          </QueueAnim> */

/* 
<OverPack className="home-layout" location="home-func" playScale={0.4}>
        <QueueAnim className="home-func" type="bottom" key="home-func" ease="easeOutQuart" leaveReverse>
        
           <QueueAnim
            key="content"
            component={Row}
            type="bottom"
            componentProps={{ gutter: 171 }}
          >
            {children}
          </QueueAnim>
          </QueueAnim>
          </OverPack>

          */
