import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useContract, setContract } from 'hooks';
import { updateInput } from 'appRedux/actions';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { Button, Form, Input, Select, Checkbox, Row, Col, Tooltip } from 'antd';
import InputSelect from './InputTypes/Select';
import InputCheckbox from './InputTypes/Checkbox';
import InputTextbox from './InputTypes/Text';
import InputNumeric from './InputTypes/Numeric';
import InputEntity from './InputTypes/Entity';
import { ConditionActions, PrintCondition } from 'components/Rules/InputCondition';
import { getKeyByValue, generateUID } from 'core/utils/general';
const { Option } = Select;

export default function AddEditInput({ selected, onFinish, type = 'input', setupState, setSetupState }) {
  const contract = useContract();
  const dispatch = useDispatch();
  const formatMessage = useIntlMessage();

  function getSelectedInput(id) {
    if (!id) return null;
    if (type === 'input') {
      return contract.data.ui.inputs[selected.input] || null;
    } else if (type === 'setup') {
      return setupState.find((si) => si.id === selected.input) || null;
    }
  }

  const optionsPropertyName = type === 'setup' ? 'options' : 'content';

  const currentInput = getSelectedInput(selected.input);
  const currentInputId = currentInput && selected.input;
  const contractLanguauge = contract.data.settings.language;
  const colWidths = {
    25: 6,
    33: 8,
    50: 12,
    66: 16,
    75: 18,
    100: 24,
  };

  const getColValueByWidth = () => {
    if (!currentInput?.cols?.lg) return '100';
    const width = getKeyByValue(colWidths, parseInt(currentInput.cols.lg, 10) * 2);
    return width ? width.toString() : '100';
  };

  const [inputType, setInputType] = useState(currentInput ? currentInput.type : '-1'); // Ugly to use -1, what else? 'choose' ?
  const [hasMultiple, setHasMultiple] = useState(currentInput ? currentInput.multiple : false);
  const [inputId, setInputId] = useState(currentInput ? currentInputId : '');
  const [inputIdError, setInputIdError] = useState(null);
  const [hasInputInfo, setHasInputInfo] = useState(
    currentInput && currentInput.info ? currentInput.info[contractLanguauge] : false
  );
  const [hasInputHelper, setHasInputHelper] = useState(
    currentInput && currentInput.helper ? currentInput.helper[contractLanguauge] : false
  );
  const [isRequired, setIsRequired] = useState(
    currentInput && currentInput.hasOwnProperty('required') ? currentInput.required : false
  );
  const [acp, setAcp] = useState(currentInput?.acp);
  const [inputInfo, setInputInfo] = useState('');
  const [inputHelper, setInputHelper] = useState('');
  const [inputLabel, setInputLabel] = useState(
    currentInput && currentInput.label ? currentInput.label[contractLanguauge] : ''
  );
  const [inputWidth, setInputWidth] = useState(getColValueByWidth());
  const [inputLabelError, setInputLabelError] = useState(null);
  const [childInvalidError, setChildInvalidError] = useState(null);
  const [childData, setChildData] = useState(null);
  const [useCustomId, setUseCustomId] = useState(false);

  const currentCard = type === 'input' && contract.data.ui.cards[selected.card];
  let defaultValue = '';

  const handleInputIdChange = (value) => {
    setInputId(value);
    if (getSelectedInput(value)) {
      setInputIdError(formatMessage('studio.template.inputs.errors.idExists') + value);
    } else if (inputIdError) {
      setInputIdError('');
    }
  };

  const generateInputId = (label) => {
    while (true) {
      const baseName = type === 'input' ? `${currentCard.id}/` : '';
      const id = `${baseName}/${label
        .replace(' ', '_')
        .replace(/[^a-zA-Z_]/g, '')
        .toLowerCase()
        .slice(0, 6)}_${generateUID(4)}`;
      if (!getSelectedInput(id)) {
        return id;
      }
    }
  };

  const handleInputLabelChange = (evt) => {
    const { value } = evt.target;
    setInputLabel(value);
    if (!currentInput && !useCustomId) {
      handleInputIdChange(generateInputId(value));
    }
  };

  useEffect(() => {
    if (!currentInput && !useCustomId) {
      handleInputIdChange(generateInputId(inputLabel || 'input'));
    }
    // Only on mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTypeChange = (value) => {
    setInputType(value);
    setChildInvalidError(null);
  };

  const handleRequiredToggle = () => {
    setIsRequired(!isRequired);
  };

  const onChangeAcp = (id) => {
    if (acp === id) {
      return console.log('Same.');
    }
    setAcp(id);
  };

  const handleInfoToggle = () => {
    setHasInputInfo(!hasInputInfo);
  };
  const handleHelperToggle = () => {
    setHasInputHelper(!hasInputHelper);
  };

  const handleInfoChange = (evt) => {
    setInputInfo(evt.target.value);
  };
  const handleHelperChange = (evt) => {
    setInputHelper(evt.target.value);
  };

  const onChildUpdate = useCallback((data) => {
    setChildData(data);
    setChildInvalidError(null);
  }, []);

  if (type === 'input' && !currentCard) return 'Error';

  // We go through the data from the child and validate it and set extra properties
  const getChildData = () => {
    if (['select', 'radio'].includes(inputType) || (inputType === 'checkbox' && hasMultiple)) {
      if (!childData) return setChildInvalidError('Unexpected error');
      const { options, defaultValue, ...rest } = childData;

      if (Object.keys(options).length === 0)
        return setChildInvalidError(formatMessage('studio.template.inputs.errors.selectOptionMissing'));
      // TODO: Should this be in the child component?
      const obj = {
        [optionsPropertyName]: Object.entries(options).map(([id, { label, value }]) => {
          const tmp = {
            id,
            label: {
              [contractLanguauge]: label,
            },
          };
          if (value) {
            tmp.values = { [contractLanguauge]: value };
          }
          return tmp;
        }),
      };
      if (defaultValue) obj.value = defaultValue;

      return { ...obj, ...rest };
    } else if (['text', 'checkbox', 'numeric', 'numeric-steps'].includes(inputType)) {
      return {
        value: childData && childData.defaultValue,
      };
    } else if (inputType === 'date') {
      return {
        value: '',
      };
    } else if (inputType === 'QA') {
      return {
        value: {},
      };
    } else if (['entity', 'party'].includes(inputType)) {
      const { dynamicValue, defaultValue, target } = childData || {};
      if (dynamicValue) return { target, dynamicValue };
      if (defaultValue) return { target, value: defaultValue };
      return { target };
    }
    return {};
  };

  function newInputFieldName() {
    if (type === 'setup') return inputId;

    // We remove the cardid when we set the name
    return inputId.replace(`${currentCard.id}/`, '');
  }

  const addInput = () => {
    if (!inputId) return setInputIdError(formatMessage('studio.template.inputs.errors.idMissing'));
    if (!inputLabel) return setInputLabelError(formatMessage('studio.template.inputs.errors.labelMissing'));
    if (type !== 'setup' && !currentCard) {
      return setInputIdError(formatMessage('studio.template.inputs.errors.cardMissing'));
    }

    // Treat the data depending on the child
    const extraProps = getChildData();

    const name = newInputFieldName();

    const newInput = {
      type: inputType,
      label: {
        // en: inputLabel, // To be commented out?
        [contractLanguauge]: inputLabel,
      },
      required: isRequired,
      ...extraProps,
    };

    if (hasMultiple) newInput.multiple = true;

    if (hasInputInfo && inputInfo) {
      newInput.info = {
        [contractLanguauge]: inputInfo,
      };
    }

    if (hasInputHelper && inputHelper) {
      newInput.helper = {
        [contractLanguauge]: inputHelper,
      };
    }

    if (type === 'setup') {
      newInput.id = name;

      let newSetup;
      if (currentInput) {
        newSetup = setupState.map((s) => {
          if (s.id === currentInputId) {
            return newInput;
          }
          return s;
        });
      } else {
        newSetup = [...setupState, newInput];
      }
      console.log('new setup is ', newSetup);
      setSetupState(newSetup);
    } else if (type === 'input') {
      newInput.name = name;

      newInput.inputs_order = [];

      if (acp) {
        newInput.acp = acp;
      }

      // Two different width systems. Have to divide with 2 for the contract data.
      const width = (colWidths[inputWidth] / 2).toString();
      newInput.cols = { lg: width, md: width, sm: width };

      if (extraProps.value) defaultValue = extraProps.value;

      currentCard.values[inputId] = defaultValue;
      // Only push to card if its a new input
      if (!currentInput) {
        currentCard.inputs_order.push(inputId);
      }

      contract.data.ui.inputs[inputId] = newInput;

      setContract({
        ...contract,
      });
      setInputId('');
      setInputLabel('');
      setInputIdError(null);
      setInputType('-1');
      setHasInputInfo(false);
      setHasInputHelper(false);
      setInputInfo('');
      setInputHelper('');
      setChildData(null);
      setChildInvalidError(null);

      // Add/update the input in the state
      let statePath = `input.${currentCard.id}`;
      if (currentCard.isRepeatable) {
        // Get the path to the card in the state input. Do this for the first occurence only?
        const cardStateKeys = Object.keys(contract.data.input[currentCard.id]);
        if (cardStateKeys.length > 0) {
          statePath = `${statePath}.${cardStateKeys[0]}`;
        } else {
          console.log('No input in state found for repeatable');
          statePath = null;
        }
      }

      if (statePath) {
        if (inputType === 'QA') {
          console.log('Add state for QA');
          dispatch(updateInput(`${statePath}.${inputId}`, {}));
        } else dispatch(updateInput(`${statePath}.${inputId}`, defaultValue));
      }
    }
    onFinish();
  };

  const renderWidthOptions = () => {
    return Object.keys(colWidths).map((val) => {
      return <Option key={val} value={val}>{`${val} %`}</Option>;
    });
  };

  const getColProps = () => {
    const width = colWidths[inputWidth];
    return {
      xl: width,
      lg: width,
      md: width,
      sm: width,
      xs: width,
    };
  };

  const inputTypes = [
    'text',
    'party',
    'select',
    'checkbox',
    'radio',
    'numeric',
    'numeric-steps',
    'date',
    'QA',
  ];

  return (
    <div>
      <Form layout="vertical">
        <Row>
          <Col lg={6} md={12} sm={24}>
            <Form.Item
              label={<IntlMessages id="studio.template.inputs.label.label" />}
              extra={
                <div>
                  <small>
                    <IntlMessages id="studio.template.inputs.label.info" />
                  </small>
                  {inputLabelError && <div className="text-warning">{inputLabelError}</div>}
                </div>
              }
            >
              <Input
                type="text"
                value={inputLabel}
                onChange={handleInputLabelChange}
                placeholder={formatMessage('studio.template.inputs.label.placeholder')}
              />
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item
              label={<IntlMessages id="studio.template.inputs.type.label" />}
              extra={
                <div>
                  <small>
                    <IntlMessages id="studio.template.inputs.type.info" />
                  </small>
                </div>
              }
            >
              <Select onChange={handleTypeChange} className="w-100" value={inputType}>
                <Option value="-1">{`-- ${formatMessage(
                  'studio.template.inputs.types.pickType'
                )} --`}</Option>
                {inputTypes.map((inputType) => (
                  <Option key={inputType} value={inputType}>
                    <IntlMessages id={`studio.template.inputs.types.${inputType}`} />
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {!currentInput && (
            <Col lg={6} md={12} sm={24}>
              <Form.Item
                label={<IntlMessages id="studio.template.inputs.customId.label" />}
                // style={{ width: '10%' }}
                extra={
                  <div>
                    <small>
                      <IntlMessages id="studio.template.inputs.customId.info" />
                    </small>
                  </div>
                }
              >
                <Checkbox checked={useCustomId} onChange={() => setUseCustomId(!useCustomId)} />
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item
              label={<IntlMessages id="studio.template.inputs.id.label" />}
              extra={
                <div>
                  <small>
                    <IntlMessages id="studio.template.inputs.id.info" />
                  </small>
                  {inputIdError && <div className="text-warning">{inputIdError}</div>}
                </div>
              }
            >
              <Input
                type="text"
                value={inputId}
                onChange={(evt) => handleInputIdChange(evt.target.value)}
                disabled={!!currentInput || !useCustomId}
                placeholder={formatMessage('studio.template.inputs.id.placeholder')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={12} sm={24}>
            <Form.Item
              label={<IntlMessages id="studio.template.inputs.isRequired.label" />}
              style={{ width: '33%' }}
              className="mr-2"
              extra={
                <div>
                  <small>
                    <IntlMessages id="studio.template.inputs.isRequired.info" />
                  </small>
                </div>
              }
            >
              <Checkbox checked={isRequired} onChange={handleRequiredToggle} />
            </Form.Item>
          </Col>
          {type !== 'setup' && (
            <Col lg={6} md={12} sm={24}>
              <Form.Item
                label={<IntlMessages id="studio.template.inputs.size.label" />}
                className="ml-2"
                extra={
                  <div>
                    <small>
                      <IntlMessages id="studio.template.inputs.size.info" />
                    </small>
                  </div>
                }
              >
                <Col {...getColProps()} className="audi-col" style={{ padding: 0 }}>
                  <Select onChange={(value) => setInputWidth(value)} value={inputWidth} placeholder="">
                    {renderWidthOptions()}
                  </Select>
                </Col>
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item label={'Condition'} className="mt-0 mb-0">
              <div className="mb-2">
                {acp ? (
                  <div>
                    <PrintCondition acp={acp} />
                  </div>
                ) : (
                  <div>Input has no condition. Always visible.</div>
                )}
              </div>
              <div>
                <ConditionActions acp={acp} onChange={onChangeAcp} linksInline className="xd-flex" />
              </div>
              {acp && (
                <div>
                  <span className="link" onClick={() => setAcp(null)}>
                    Remove condition
                  </span>
                </div>
              )}
            </Form.Item>
          </Col>
          {/* <Col lg={6} md={12} sm={24}></Col> */}
        </Row>
        <Row>
          <Col lg={6} md={12} sm={24}>
            <Form.Item
              label={<IntlMessages id="studio.template.inputs.hasInfo.label" />}
              extra={
                <div>
                  <small>
                    <IntlMessages id="studio.template.inputs.hasInfo.info" />
                  </small>
                </div>
              }
            >
              <Checkbox checked={hasInputInfo} onChange={handleInfoToggle} />
            </Form.Item>
          </Col>
          {hasInputInfo && (
            <Col lg={6} md={12} sm={24}>
              <Form.Item
                label={<IntlMessages id="studio.template.inputs.infoText.label" />}
                extra={
                  <div>
                    <small>
                      <IntlMessages id="studio.template.inputs.infoText.info" />
                    </small>
                  </div>
                }
              >
                <Input
                  type="text"
                  value={inputInfo}
                  onChange={handleInfoChange}
                  placeholder={formatMessage('studio.template.inputs.infoText.placeholder')}
                />
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item
              label={
                <>
                  <IntlMessages id="studio.template.inputs.hasHelper.label" />
                  <Tooltip title={<IntlMessages id="studio.template.inputs.hasHelper.info" />}>
                    <i className="ml-1 mdi mdi-help-circle" />
                  </Tooltip>
                </>
              }
              className="pl-2"
            >
              <Checkbox checked={hasInputHelper} onChange={handleHelperToggle} />
            </Form.Item>
          </Col>
          {hasInputHelper && (
            <Col lg={6} md={12} sm={24}>
              <Form.Item
                label={
                  <>
                    <IntlMessages id="studio.template.inputs.helperText.label" />
                  </>
                }
                extra={
                  <div>
                    <small>
                      <IntlMessages id="studio.template.inputs.helperText.info" />
                    </small>
                  </div>
                }
              >
                <Input
                  type="text"
                  value={inputHelper}
                  onChange={handleHelperChange}
                  placeholder={formatMessage('studio.template.inputs.helperText.placeholder')}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        {inputType === 'checkbox' && (
          <Row>
            <Col lg={24} md={24} sm={24}>
              <Form.Item label={<>Has multiple options</>} className="pl-2">
                <Checkbox checked={hasMultiple} onChange={() => setHasMultiple(!hasMultiple)} />
              </Form.Item>
            </Col>
          </Row>
        )}
        {inputType !== '-1' && (
          <Row>
            <Col lg={24} md={24} sm={24}>
              <>
                {/* <h4>
              <IntlMessages id="studio.template.inputs.additionalProps" />
            </h4> */}
                {['select', 'radio'].includes(inputType) || (inputType === 'checkbox' && hasMultiple) ? (
                  <InputSelect
                    inputType={inputType}
                    onChildUpdate={onChildUpdate}
                    currentInput={currentInput}
                    contractLanguauge={contractLanguauge}
                    propertyName={optionsPropertyName}
                  />
                ) : (
                  inputType === 'checkbox' && (
                    <InputCheckbox onChildUpdate={onChildUpdate} currentInput={currentInput} />
                  )
                )}
                {/* {inputType === 'checkbox' && (
                  <InputCheckbox onChildUpdate={onChildUpdate} currentInput={currentInput} />
                )} */}
                {['text'].includes(inputType) && (
                  <InputTextbox onChildUpdate={onChildUpdate} currentInput={currentInput} />
                )}
                {['numeric', 'numeric-steps'].includes(inputType) && (
                  <InputNumeric onChildUpdate={onChildUpdate} currentInput={currentInput} />
                )}
                {['entity', 'party'].includes(inputType) && (
                  <InputEntity
                    onChildUpdate={onChildUpdate}
                    currentInput={currentInput}
                    inputs={contract.data.ui.inputs}
                  />
                )}
              </>
            </Col>
          </Row>
        )}
      </Form>

      <Button
        type="primary"
        block
        onClick={addInput}
        disabled={
          inputType === '-1' || inputIdError || childInvalidError || inputId === '' || inputLabel === ''
        }
      >
        {currentInput
          ? formatMessage('studio.template.inputs.updateButton')
          : formatMessage('studio.template.inputs.addButton')}
      </Button>
      {childInvalidError && <div className="text-warning">{childInvalidError}</div>}
    </div>
  );
}
