import {
  AUTH_OVERLAY,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  SET_USER,
  AUTH_SET_CLIENT,
} from 'constants/ActionTypes';
import api from 'utils/api';

export function authOverlay(payload = true) {
  return { type: AUTH_OVERLAY, payload };
}

export const setClient = (client) => {
  return {
    type: AUTH_SET_CLIENT,
    payload: client,
  };
};

export const updateClient = (clientId) => {
  return async (dispatch) => {
    const include = [{ model: 'ClientSetting', as: 'clientSettings' }];
    const includeQuery = encodeURI(JSON.stringify(include));
    try {
      const client = await api.get(`/clients/${clientId}?include=${includeQuery}`);
      if (client && client.data) dispatch(setClient(client.data));
    } catch (err) {
      console.log('Error getting client');
    }
    /* const url = COUNT_URLS[id];
    if (!url) return;
    try {
      const result = await api.get(url);
      if (result.data && result.data.hasOwnProperty('count')) dispatch(setNumber(id, result.data.count));
      else dispatch(setNumber(id, 0));
    } catch (err) {
      dispatch(setNumber(id, 0));
    } */
  };
};

export const userSignUp = (data) => {
  return {
    type: SIGNUP_USER,
    payload: data,
  };
};
export const userSignIn = (user) => {
  return {
    type: SIGNIN_USER,
    payload: user,
  };
};
export const setUser = (user) => {
  return {
    type: SET_USER,
    payload: user,
  };
};
export const userSignOut = () => {
  return {
    type: SIGNOUT_USER,
  };
};
export const userSignUpSuccess = (user) => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: user,
  };
};

export const userSignInSuccess = (user) => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: user,
  };
};
export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};
