import { useEntitySearch } from "hooks"
import { AutoComplete, Button, Select, Tag } from "antd"
import { createRef, useCallback, useEffect, useMemo, useState } from "react"
import api, { generateDebouncer } from 'utils/api'
import { Entity } from "core/interfaces"
import EditEntity from "components/entity/EditEntity"
import { WarningOutlined } from "@ant-design/icons"
import IntlMessages from "util/IntlMessages"
const debounce = generateDebouncer()

const LIMIT = 10
const CREATE_DEFAULTS = {
  jurisdiction: 'se',
}

export const parseOptionProps = (entity, hidden) => {
  if (!entity) return null
  const key = `${entity.id ?? 'new'}-${entity.identificationNumber}`
  return {
    key,
    id: entity.identificationNumber,
    value: key,
    entity: entity,
    hidden: hidden === true,
  }
}

export default function({
  multiple,
  suggestions = [],
  selected = multiple ? [] : undefined,
  onSelect,
  onChange,
  onEdited,
  onSearch,
  referringType,
  referringEntity,
  form,
  editRequiredFields,
  editPrioritizedFields,
  visibleOptionData = ['name', 'identificationNumber'],
  dropdownMatchSelectWidth = 500,
  getPopupContainer,
  noPopups,
  name,
  placeholder,
  notFoundContent,
  isCombobox = false,
  defaultSearch = '',
  valueProp = 'id',
  entityType,
  disabled,
  createDefaults = CREATE_DEFAULTS,
  autoFocus,
  ...inheritedProps
}) {
  const [networkSearch, setNetworkSearch] = useState('')
  const [search, setSearch] = useState(defaultSearch)
  const [lastSearch, setLastSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [suggestedEntities, setSuggestedEntities] = useState(suggestions)
  const [oldEntities, setOldEntities] = useState([])
  const [newEntities, setNewEntities] = useState([])
  const [results, searchError] = useEntitySearch(
    { search: networkSearch, type: entityType },
    { minSearch: 1, limit: LIMIT+1, forceType: entityType })
  const [searches, setSearches] = useState([])
  const [inEdit, setInEdit] = useState(null)
  const [hoveringOnEdit, setHoveringOnEdit] = useState(false)
  const createButtonRef = createRef()

  if (multiple && isCombobox) throw new Error('Not yet supported, combobox and multiple. Use mode="tags"?')

  const SelectComponent = isCombobox ? AutoComplete : Select
  const { Option } = SelectComponent;

  useEffect(() => {
    const hasNewSuggestion = suggestions.some(entity => !suggestedEntities.some(({id}) => id === entity.id))
    if (hasNewSuggestion)
      setSuggestedEntities(suggestions)
  }, [suggestions])

  useEffect(() => {
    const entities = [
      ...oldEntities,
      ...(results || []).filter(entity => !oldEntities.some(({id}) => id === entity.id))
    ]
    setOldEntities(entities)
  }, [results])

  useEffect(() => {
    if (!selected) return
    const entities = (multiple ? selected : [selected]).map(({entity}) => entity)
    setOldEntities([...oldEntities.filter(uniqueEntityFilter(entities)), ...entities])
  }, [selected])
  
  useEffect(() => {
    setSearches([])
    setOldEntities([])
  }, [entityType])

  useEffect(() => {
    if (networkSearch.length > 2 && entityType !== 'RealPerson')
      remoteSearch()
    else
      setLoading(false)
  }, [networkSearch])

  const onInputKeyDown = (e) => {
    const lastSearch = e.key.length === 1 ? `${e.target.value}${e.key}`
      : e.target.value.slice(0, e.key === 'Backspace' ? -1 : undefined)
    setLastSearch(lastSearch)
  }

  const onSearchChange = (value) => {
    if (onSearch) onSearch(value)
    setSearch(value)
    debounce(() => {
      if (searches.includes(value)) return setLoading(false)
      setSearches([...searches, value])
      setNetworkSearch(value)
    })
    if (value)
      setLoading(true)
  }

  const onSearchBlur = (e) => {
    if (createButtonRef.current && createButtonRef.current === e.relatedTarget)
      setInEdit({ firstName: lastSearch, ...createDefaults })
    if (!isCombobox)
      setSearch('')
  }

  const onIntenalChange = (values, options, ...rest) => {
    if (isCombobox) return onChange(options?.entity?.[valueProp] ?? values, options, ...rest)
    const createdOptions = multiple ? options.filter(({entity}) => entity.id) : (!options || options.entity.id) && options
    const createdValues = getOptionValues(createdOptions)
    onChange(createdValues, createdOptions, ...rest)
  }

  const onInternalSelect = async (value, option) => {
    const search = isCombobox ? option?.entity?.[valueProp] : ''
    setSearch(search)
    setLastSearch(search)
    const shouldCreate = !option.entity.id
    if (shouldCreate)
      [value, option] = await addNewEntity(option)
    if (onSelect)
      onSelect(value, option, shouldCreate)
  }

  const onEditEntityFinish = ({entityCollection, entity}) => {
    setInEdit(null)
    if (onEdited)
      onEdited(entityCollection)
    setSuggestedEntities(suggestedEntities.map(previous => entityCollection.find(({id}) => id === previous.id) ?? previous))
    setOldEntities([...oldEntities.filter(uniqueEntityFilter(entityCollection)), ...entityCollection])
    const options = multiple ? [
      ...selected.filter(uniqueOptionFilter([entity])).filter(option => !inEdit.id || inEdit.id === entity.id || inEdit.id !== option.entity.id),
      parseOptionProps(entity)
    ] : parseOptionProps(entity)
    const values = getOptionValues(options)
    onChange(values, options)
  }

  const onEditEntityChange = (entityCollection) => {
    if (onEdited)
      onEdited(entityCollection)
    setSuggestedEntities(suggestedEntities.map(previous => entityCollection.find(({id}) => id === previous.id) ?? previous))
    setOldEntities([...oldEntities.filter(uniqueEntityFilter(entityCollection)), ...entityCollection])
    const options = multiple ? selected.map((option) => {
      const newEntity = entityCollection.find(({id}) => option.entity.id === id)
      if (newEntity)
        return parseOptionProps(newEntity)
      return option
    }) : parseOptionProps(entityCollection.find(({id}) => selected?.id === id)) || selected
    const values = getOptionValues(options)
    onChange(values, options)
  }

  const onEditRowClick = e => {
    const id = getRowId(e)
    const { entity } = selected.find(({entity}) => entity.id === id)
    setInEdit(entity)
  }

  const onRemoveRowClick = e => {
    const id = getRowId(e)
    const options = selected.filter(({entity}) => entity.id !== id)
    const values = getOptionValues(options)
    onChange(values, options)
  }

  const getOptionValues = (options) => {
    if (isCombobox)
      return multiple ? options.map((option) => option.entity[valueProp]) : options.entity[valueProp]
    return options
  }

  const getRowId = e => {
    const optionElement = e.target.parentNode.parentNode.previousSibling.firstChild
    return optionElement.attributes['data-id'].value
  }

  const remoteSearch = async () => {
    const searchKey = Number(networkSearch.replace('-', '')) ? 'identificationNumber' : 'firstName'
    const body = { [searchKey]: networkSearch, limit: LIMIT+1 }
    const result = await api.post('/entities/autocomplete', body)
    setLoading(false)
    if (result.status === 200) {
      const entities = [
        ...newEntities,
        ...result.data.filter(uniqueEntityFilter(newEntities))
      ]
      setNewEntities(entities)
    }
  }

  const uniqueEntityFilter = (...entitiesArrays) => {
    return (entity) => uniqueFilter(entity, entitiesArrays)
  }

  const uniqueOptionFilter = (...entitiesArrays) => {
    return ({entity}) => uniqueFilter(entity, entitiesArrays)
  }

  const uniqueFilter = (entity, entitiesArrays) => {
    return entity && !entitiesArrays.flat().some(({id, identificationNumber}) =>
      id && entity.id ? id === entity.id : identificationNumber?.replace('-', '') === entity.identificationNumber?.replace('-', ''))
  }

  const addNewEntity = async (option) => {
    setLoading(true)
    const result = await createNewEntity(option.entity)
    setLoading(false)
    const entity = result.data.newEntities[0]
    if (entity) {
      const newOption = {option, ...parseOptionProps(entity)}
      setOldEntities([...oldEntities, entity])
      const options = multiple ? [...selected, newOption] : newOption
      const values = getOptionValues(options)
      onChange(values, options)
      return [getOptionValues(newOption), newOption]
    }
  }

  const createNewEntity = (entity) => {
    return api.post('/entities/make', {
      addAddress: false,
      addEmails: false,
      fetchGroup: true,
      form: 'AB',
      jurisdiction: 'se',
      type: 'LegalPerson',
      ...entity
    })
  }

  const parseOption = useCallback((entity, i, arr, hidden) => {
    const props = parseOptionProps(entity, hidden)
    const name = Entity.name(entity)
    const email = Entity.firstEmail(entity)
    const principal = referringType === 'Principal' ? entity : referringEntity
    const agent = referringType === 'Principal' ? referringEntity : entity
    const relation = Entity.getRelation(principal, agent, true)
    const signatory = relation?.capacities?.includes('signatory')
    return (
      <Option {...props}>
        <div className="d-flex option-data" data-id={entity.id}>
          <div className="name-data">{name}</div>
          {visibleOptionData.includes('identificationNumber') && entity.identificationNumber && (
            <div className="identification-data">
              &nbsp;({entity.identificationNumber})
            </div>
          )}
          {visibleOptionData.includes('email') && email && <Tag className="mr-0 ml-1 email-data">{email}</Tag>}
          {visibleOptionData.includes('signatory') && (
            <Tag
              className="mr-0 ml-1 signatory-data"
              color="purple"
              style={{ visibility: signatory ? undefined : 'hidden' }}
            >
              <IntlMessages id="app.persons.capacities.signatory" />
            </Tag>
          )}
          <OptionWarning {...{entity, email, editRequiredFields}} />
        </div>
      </Option>
    )
  }, [referringType, referringEntity, visibleOptionData, editRequiredFields])

  const parseHiddenOption = (entity, i, arr) => {
    return parseOption(entity, i, arr, true)
  }

  const searchSort = (a, b) => {
    return +(!a.tags?.includes('isTopCo')) - +(!b.tags?.includes('isTopCo'))
  }

  const searchFilter = (value) => {
    if (!search) return true
    const email = visibleOptionData.includes('email') ? Entity.firstEmail(value) : ''
    const chunks = search
      .split(/[ -]/)
      .map((chunk) => `(?=.*${escapeRegExp(chunk)})`)
      .join('')
    const searchValues = `${value.firstName} ${value.lastName} ${value.identificationNumber} ${email}`
    return !!searchValues.match(new RegExp(chunks, 'i'))
  }

  const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

  const options = useMemo(() => {
    const oldEntityOptions = oldEntities.filter(searchFilter).sort(searchSort)
    .filter(uniqueEntityFilter(suggestedEntities))
    const newEntityOptions = newEntities.filter(searchFilter).sort(searchSort)
      .filter(uniqueEntityFilter(oldEntities, suggestedEntities))
    const restEntityOptions = (multiple ? selected : [selected]).map((option) => option?.entity)
      .filter(uniqueEntityFilter(suggestedEntities, oldEntityOptions.slice(0, LIMIT), newEntityOptions.slice(0, LIMIT)))

    const options = []
    if (suggestedEntities.length)
      options.push(...[
        <Option key="1" className="group" disabled><IntlMessages id="app.general.entity.suggestions" /></Option>,
        ...suggestedEntities.filter(searchFilter).map(parseOption),
      ])
    if (!isCombobox || search.length > 2) {
      if (oldEntityOptions.length)
        options.push(...[
          <Option key="2" className="group" disabled><IntlMessages id="app.general.entity.previouslyUsed" /></Option>,
          ...oldEntityOptions.filter((e, i) => i < LIMIT).map(parseOption),
          oldEntityOptions.length >= LIMIT && <Option key="2-..." disabled>...</Option>,
        ])
      if (!isCombobox || newEntityOptions.length)
        options.push([
          <Option key="3" className="group" disabled><IntlMessages id="app.general.entity.createNew" /></Option>,
          ...newEntityOptions.filter((e, i) => i < LIMIT).map(parseOption),
          <Option key="3-..." disabled>
            <IntlMessages id={noPopups ? "app.general.entity.trySearch" : "app.general.entity.trySearchOrCreate"} />
          </Option>,
        ])
    }
    options.push(...restEntityOptions.map(parseHiddenOption))
    return options
  }, [suggestedEntities, oldEntities, newEntities, selected, parseOption])

  const hasEdit = multiple ? !!selected.length : !!selected && !search

  return (
    <div
      className={`d-flex selection${
        multiple || isCombobox ? ' select-rows' : ' select-row'
      }${
        isCombobox ? ' combobox' : ''
      }${
        selected?.length || !Array.isArray(selected) ? ' has-items' : ''
      }${
        hoveringOnEdit ? ' block-hover' : ''
      }`}
    >
      <SelectComponent
        dropdownClassName={`selection-groups select-rows`}
        mode={multiple ? 'multiple' : undefined}
        showSearch
        showArrow
        allowClear={!multiple && !isCombobox}
        clearIcon={noPopups ? null : <ClearIcon onRemoveRowClick={onRemoveRowClick} />}
        removeIcon={noPopups ? null : <SuffixIcons {...{onRemoveRowClick, onEditRowClick, setHoveringOnEdit}} />}
        filterOption={(value, option) => !option.hidden}
        notFoundContent={notFoundContent}
        value={selected}
        searchValue={search}
        onInputKeyDown={onInputKeyDown}
        onSearch={onSearchChange}
        onBlur={onSearchBlur}
        onSelect={onInternalSelect}
        onChange={onIntenalChange}
        disabled={disabled}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        defaultActiveFirstOption
        autoFocus={autoFocus}
        placeholder={placeholder}
        loading={loading}
        getPopupContainer={getPopupContainer ? getPopupContainer : () => {
          const modals = document.getElementsByClassName('ant-modal-wrap')
          const inputForm = document.getElementsByClassName('draft-input-forms-scroll')[0]
          return modals.length ? modals[modals.length-1] : inputForm
        }}
        style={{ width: multiple || noPopups ? '100%' : 'calc(100% - 32px)' }}
        {...inheritedProps}
      >
        {options}
      </SelectComponent>
      {multiple && (
        <span
          style={{
            width: 32,
            margin: '0 -31px 31px -32px',
            border: '1px solid',
            borderTopColor: hoveringOnEdit ? 'var(--hover-color)' : '#0000',
            borderRightColor: hoveringOnEdit ? 'var(--hover-color)' : '#0000',
            borderBottomColor: hoveringOnEdit ? 'var(--hover-color)' : '#0000',
            borderLeftColor: hoveringOnEdit ? 'var(--hover-color)' : 'var(--input-color)',
            borderRadius: 2,
            zIndex: 1,
            pointerEvents: 'none',
          }}
        ></span>
      )}
      <div className="d-flex flex-column justify-content-end" style={{ minHeight: multiple ? 63 : undefined }}>
        {(hasEdit && !multiple) && !noPopups && (
          <Button
            style={{ padding: '2px 0' }}
            onClick={() => setInEdit(selected.entity)}
            icon={<i className="mdi mdi-pencil" />}
            disabled={disabled || !hasEdit}
          />
        )}
        {(!hasEdit || multiple) && !noPopups && (
          <Button
            ref={createButtonRef}
            onClick={() => setInEdit({ firstName: lastSearch, ...createDefaults })}
            icon={<i className="mdi mdi-plus" />}
            style={{ padding: '2px 0' }}
            disabled={disabled}
          />
        )}
      </div>
      {inEdit && (
        <EditEntity
          entity={inEdit?.id && inEdit}
          defaultNewValues={inEdit}
          relationType={referringType}
          referringEntity={referringEntity ?? form?.getFieldsValue(true)}
          requires={editRequiredFields}
          additionalPrioritizedFields={editPrioritizedFields}
          onCancel={() => {
            setInEdit(null)
          }}
          onFinishCallback={onEditEntityFinish}
          onEdited={onEditEntityChange}
          redux={true}
        />
      )}
    </div>
  )
}

const SuffixIcons = ({onRemoveRowClick, onEditRowClick, setHoveringOnEdit}) => (
  <div
    className="select-action d-flex"
    onClick={e => e.stopPropagation()}
    style={{cursor: 'default'}}
  >
    <ClearIcon onRemoveRowClick={onRemoveRowClick} />
    <i
      className="mdi mdi-pencil"
      onClick={onEditRowClick}
      style={{cursor: 'pointer'}}
      onMouseOver={() => setHoveringOnEdit(true)}
      onMouseOut={() => setHoveringOnEdit(false)}
    />
  </div>
)

const ClearIcon = ({ onRemoveRowClick }) => (
  <i
    className="mdi mdi-close"
    onClick={onRemoveRowClick}
    style={{cursor: 'pointer'}}
  />
)

const OptionWarning = ({entity, email, editRequiredFields = []}) => {
  const errors = editRequiredFields.filter(field => !entity[field] && (field !== 'emails' || !email))
  if (!errors.length) return null
  return <WarningOutlined className="mt-1 text-danger" style={{marginRight: -54, marginLeft: 40}} />
}
