import moment from 'moment';

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const getFullIdNumber = (idNumber) => {
  if (idNumber.length === 12) {
    return idNumber;
  }
  if (idNumber.length !== 10) {
    throw new Error('Invalid id number length');
  }

  const thisYear = Number(new Date().getFullYear().toString().substring(2, 4));
  const personYear = Number(idNumber.substring(0, 2));
  const century = personYear > thisYear ? '19' : '20';
  return century + idNumber;
};

const calculateLuhn = (id) =>
  id.split('').reduce((sum, cur, i) => {
    let digit = parseInt(cur, 10);
    if (i % 2 === 0) {
      digit *= 2;
    }
    if (digit > 9) {
      digit -= 9;
    }
    return sum + digit;
  }, 0);

const NORMALIZERS = {
  se: (idRaw, type = 'RealPerson') => {
    const id = idRaw.replace(/ |-/g, '');
    const numdigits = id.length;

    const forceNumberLength = type === 'RealPerson' ? [10, 12] : [10];

    if (numdigits !== 10 && numdigits !== 12) {
      throw new Error('Wrong length of id number');
    }
    if (!forceNumberLength.includes(numdigits)) {
      throw new Error('Wrong length of id number 2');
    }

    let dateString;
    let format;

    if (numdigits === 12) {
      dateString = id.substring(0, 8).replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
      format = 'YYYY-MM-DD';
    } else {
      dateString = id.substring(0, 6).replace(/(\d{2})(\d{2})(\d{2})/, '$1-$2-$3');
      format = 'YY-MM-DD';
    }

    if (type === 'RealPerson') {
      const date = moment(dateString, format);

      // Is the date valid or not?
      if (!date.isValid()) return false;
    }

    // Check with luhn
    const idTrimmed = numdigits === 12 ? id.substring(2) : id;

    const valid = calculateLuhn(idTrimmed) % 10 === 0;
    if (!valid) throw new Error('Not valid according to Luhn algorithm');

    if (type === 'LegalPerson') return id;

    if (numdigits === 12) return id;
    return getFullIdNumber(id);
  },
};

export const normalizeAndValidate = (idRaw, type = 'RealPerson', country) => {
  if (!idRaw) throw new Error('Empty id number');
  if (!NORMALIZERS[country]) return idRaw;
  return NORMALIZERS[country](idRaw, type);
};

window.getFullIdNumber = getFullIdNumber;
window.normalizeAndValidate = normalizeAndValidate;
