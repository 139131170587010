import React, { memo } from 'react';
import { YjsEditorContext } from 'contexts/contexts';
import { useConnectedEditor } from './useConnectedEditor';
import './connectedEditor.css';

function Loading({ children }) {
  return (
    <div className="m-4 pt-4 d-flex justify-content-center">
      <div>{children}</div>
    </div>
  );
}

const ConnectedEditor = memo(function ConnectedEditorX({
  editor,
  onlineMode = true,
  setValue,
  versionId,
  children,
}) {
  const {
    provider,
    connected,
    onlineLoaded,
    toggleConnection,
    providerAuthStatus,
    setUserData,
    setAwarenessItem,
    initialSnapshot,
  } = useConnectedEditor({
    editor,
    onlineMode,
    setValue,
    versionId,
  });

  if (onlineMode && !providerAuthStatus) return <Loading>Authenticating . . .</Loading>;

  return (
    <YjsEditorContext.Provider
      value={{
        provider,
        connected,
        onlineMode,
        onlineLoaded,
        toggleConnection,
        providerAuthStatus,
        setUserData,
        setAwarenessItem,
        initialSnapshot,
      }}
    >
      {children}
    </YjsEditorContext.Provider>
  );
});

export default ConnectedEditor;
