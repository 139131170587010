import React from 'react';
import { useHistory } from 'react-router-dom';
import { Layout, Button } from 'antd';
import IntlMessages from 'util/IntlMessages';
import { useUser } from 'hooks';
import logo from 'assets/images/Logo_white_bb5.svg';

const { Header } = Layout;

export default function HeaderX(props) {
  const history = useHistory();
  const user = useUser();

  return (
    <Header {...props} className="prim-bg">
      <div className="header-content">
        <h1>
          <a>
            <img src={logo} alt="bb5 logo" className="logo" />
          </a>
        </h1>
        {user ? (
          <div>
            <Button
              type="secondary"
              className="header-login-btn"
              onClick={() => {
                history.push('/main');
              }}
            >
              <IntlMessages id="bb5.landing.explore" cap />
            </Button>
          </div>
        ) : (
          <div className="sign-btns">
            <Button
              type="secondary"
              className="header-login-btn"
              onClick={() => {
                history.push('/signup');
              }}
            >
              <IntlMessages id="bb5.landing.signup" cap />
            </Button>
            <Button
              type="primary"
              className="header-login-btn"
              onClick={() => {
                history.push('/signin');
              }}
            >
              <IntlMessages id="bb5.landing.signin" cap />
            </Button>
          </div>
        )}
      </div>
    </Header>
  );
}
