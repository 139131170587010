import { useState } from 'react';
import { Tooltip, Button, Switch, notification } from 'antd';
import IntlMessages from 'util/IntlMessages';
import { AutoModal } from 'components/ui';
import { useLatestDocumentVersion, useMountFetch } from 'hooks';
import { frontendServer } from 'config';

import config from 'config/config';
import api from 'utils/api';

const { applicationResourceIds } = config;
const { permissionAccessorId, permissionAccessRequestGroupName } = applicationResourceIds;

function getStudioLink(id, passthroughCode) {
  const url = `/studio/draft/${id}?onlyInput=true&hideOneSection=true&hideFinishActions=true&passthrough=true&authCode=${passthroughCode}`;
  console.log('url is ', url);
  return url;
}

const EditOfferContent = ({ doc }) => {
  const contract = useLatestDocumentVersion(doc.id, { fields: 'id' });
  const [passthroughCode] = useMountFetch('/users/passthroughCode');

  if (!passthroughCode || !contract || !contract.id) return <div>Loading . . .</div>;

  return (
    <div>
      <iframe
        src={getStudioLink(doc.id, passthroughCode)}
        title={'Edit'}
        style={{
          border: 0,
          width: '100%',
          height: '75vh',
        }}
      />
    </div>
  );
};

export const OfferPublishSwitch = ({ doc }) => {
  const [loadingPublish, setLoadingPublish] = useState(false);
  const [documentPermissionAccessors, setDocumentPermissionAccessors] = useState(null);
  useMountFetch(
    `/permissions/resourceAccess?resourceType=Document&resourceId=${doc.id}&accessorType=PermissionAccessor&accessorId=${permissionAccessorId}`,
    setDocumentPermissionAccessors
  );

  const isPublic = Array.isArray(documentPermissionAccessors) && documentPermissionAccessors.length;

  const togglePublic = async () => {
    if (isPublic) {
      setLoadingPublish(true);
      try {
        await api.post('/permissions/revoke', {
          accessorType: 'PermissionAccessor',
          accessorId: permissionAccessorId,
          resourceType: 'Document',
          resourceId: doc.id,
          groupName: permissionAccessRequestGroupName,
        });
        setDocumentPermissionAccessors([]);
      } catch (err) {
        notification.error({
          message: 'Cannot change status',
        });
      }
      setLoadingPublish(false);
    } else {
      setLoadingPublish(true);
      try {
        const result = await api.post('/permissions/grant', {
          accessorType: 'PermissionAccessor',
          accessorId: permissionAccessorId,
          resourceType: 'Document',
          resourceId: doc.id,
          groupName: permissionAccessRequestGroupName,
        });
        setDocumentPermissionAccessors([result.data]);
      } catch (err) {
        notification.error({
          message: 'Cannot change status',
        });
      }
      setLoadingPublish(false);
    }
  };

  return (
    <Tooltip key="publ" title={<IntlMessages id={`general.to${isPublic ? 'Un' : ''}Publish`} />}>
      <Switch
        checked={isPublic}
        onChange={togglePublic}
        loading={loadingPublish}
        checkedChildren="Published"
        unCheckedChildren="Not published"
        className="mr-3"
      />
    </Tooltip>
  );
};

export const EditOfferButton = ({ doc, trigger, defaultOpen }) => {
  const [loadingPublish, setLoadingPublish] = useState(false);
  const [documentPermissionAccessors, setDocumentPermissionAccessors] = useState(null);
  useMountFetch(
    `/permissions/resourceAccess?resourceType=Document&resourceId=${doc.id}&accessorType=PermissionAccessor&accessorId=${permissionAccessorId}`,
    setDocumentPermissionAccessors
  );

  const isPublic = Array.isArray(documentPermissionAccessors) && documentPermissionAccessors.length;

  const togglePublic = async () => {
    if (isPublic) {
      setLoadingPublish(true);
      try {
        await api.post('/permissions/revoke', {
          accessorType: 'PermissionAccessor',
          accessorId: permissionAccessorId,
          resourceType: 'Document',
          resourceId: doc.id,
          groupName: permissionAccessRequestGroupName,
        });
        setDocumentPermissionAccessors([]);
      } catch (err) {
        notification.error({
          message: 'Cannot change status',
        });
      }
      setLoadingPublish(false);
    } else {
      setLoadingPublish(true);
      try {
        const result = await api.post('/permissions/grant', {
          accessorType: 'PermissionAccessor',
          accessorId: permissionAccessorId,
          resourceType: 'Document',
          resourceId: doc.id,
          groupName: permissionAccessRequestGroupName,
        });
        setDocumentPermissionAccessors([result.data]);
      } catch (err) {
        notification.error({
          message: 'Cannot change status',
        });
      }
      setLoadingPublish(false);
    }
  };

  let triggerComponent = trigger || (
    <Button type="primary" className="mb-1">
      <IntlMessages id={'general.edit'} />
    </Button>
  );

  return (
    <AutoModal
      defaultOpen={defaultOpen}
      trigger={triggerComponent}
      title={
        <>
          <IntlMessages id={'general.edit'} className="mr-1" />{' '}
          <IntlMessages id="general.financeRequest" cap />
        </>
      }
      footerBtns={[<OfferPublishSwitch key="publ-switch" doc={doc} />]}
      className="modal-full"
    >
      <EditOfferContent doc={doc} />
    </AutoModal>
  );
};
