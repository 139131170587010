import { Row, Col, Button, Skeleton, notification } from 'antd';
import { useLatestDocumentVersion, useFetch, useMountFetch, useLocale, useIsReferringClient } from 'hooks';
import { smallGutter } from 'config';
import api from 'utils/api';
import config from 'config/config';
import {
  Header,
  SummaryItem,
  PropertyTotalFloorArea,
  EnvironmentalCertificate,
  DebtInterest,
} from './SummaryRenderers';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { useEffect } from 'react';

const { applicationResourceIds } = config;
const { referringUserEntityId } = applicationResourceIds;

function getStateUrl(id, suppliedContractState) {
  if (!id) return '';
  if (!!suppliedContractState) return '';
  return `/versions/${id}/state`;
}

function getUsageFieldId(type) {
  if (type === 'commercial') return 'property/commercial_usage';
  if (type === 'residential') return 'property/residential_usage';
  return null;
}

const ProposalSummary = ({
  doc,
  template,
  isLender,
  contractState: suppliedContractState,
  isBorrower,
  reportSeen,
  closeModalFnRef,
  hasRequestedAccess,
}) => {
  const isReferringClient = useIsReferringClient();
  const contract = useLatestDocumentVersion(suppliedContractState ? null : doc.id);
  const [fetchedContractState] = useFetch(getStateUrl(contract?.id, suppliedContractState));
  const locale = useLocale();
  const formater = useIntlMessage();

  useEffect(() => typeof reportSeen === 'function' && reportSeen(doc.id));

  const contractState = fetchedContractState || suppliedContractState;

  if (!contractState) return <Skeleton active paragraph={{ rows: 5 }} />;

  const { input } = contractState;

  if (!input) {
    return (
      <div>
        <IntlMessages id="general.noData" />
      </div>
    );
  }

  const submitInterest = async () => {
    try {
      const addedTask = await api.post('/tasks', {
        name: 'Financing interest',
        description: 'Interst in ' + doc.name,
        type: 'resource-access',
        data: {},
        info: {
          message: 'Hmm.',
        },
        resourceType: 'Project',
        resourceId: doc.projectId,
        assignedEntityType: 'Entity',
        assignedEntityId: referringUserEntityId,
        status: 'pending',
        outcomeStatus: 'pending',
      });
      console.log('Added task ', addedTask);

      notification.success({
        message: formater('general.interestSubmitted'),
        description: formater('general.interestSubmittedNotification'),
      });

      if (closeModalFnRef && typeof closeModalFnRef.current === 'function') closeModalFnRef.current();
    } catch (err) {
      console.log('Error adding interest task ', err.response);
    }
  };

  const isDevelopmentProject =
    Array.isArray(input.property['property/project_type']) &&
    input.property['property/project_type'].includes('development');

  const envCertValue = input.property['property/environment_cert'];
  const showEnvCert = envCertValue === 'yes' || envCertValue === 'coming';

  const financingType = input.general['general/financing_type'];
  const isPropertyFinancing = financingType === 'real_estate' || !financingType;
  const isCorporateFinancing = financingType === 'corporate';

  const usageFieldId = getUsageFieldId(input.property['property/type']);

  return (
    <>
      {isReferringClient && (
        <div className="mb-4">
          <ReferringClientExtraInfo doc={doc} />
        </div>
      )}

      <Row gutter={smallGutter}>
        <Col span={24}>
          <Header type="large" text={<IntlMessages id="general.generalInfo" />} />
        </Col>
      </Row>

      <Row gutter={smallGutter}>
        <SummaryItem
          card="general"
          id="general/financing_type"
          data={input}
          template={template}
          language={locale}
        />
        <SummaryItem
          card="general"
          id="general/currency"
          data={input}
          template={template}
          language={locale}
        />
      </Row>
      {isPropertyFinancing && (
        <>
          <Row gutter={smallGutter}>
            <Col span={24}>
              <Header type="large" text={<IntlMessages id="general.propInfo" />} />
            </Col>
          </Row>
          <Row gutter={smallGutter}>
            <SummaryItem
              card="property"
              id="property/type"
              data={input}
              template={template}
              language={locale}
            />
            <SummaryItem
              card="property"
              id={usageFieldId}
              data={input}
              hideIfEmpty
              template={template}
              language={locale}
              color="gold"
            />
            <SummaryItem
              card="property"
              id="property/project_type"
              data={input}
              template={template}
              language={locale}
              color="cyan"
            />
            <SummaryItem
              card="property"
              id="property/country"
              data={input}
              template={template}
              language={locale}
            />
            <SummaryItem
              card="property"
              id="property/area"
              data={input}
              template={template}
              language={locale}
              color="blue"
            />
          </Row>
          {showEnvCert && (
            <Row gutter={smallGutter}>
              <EnvironmentalCertificate data={input} template={template} language={locale} />
            </Row>
          )}
          <Row gutter={smallGutter}>
            <SummaryItem
              card="property"
              id="property/commercial_floor_area"
              data={input}
              template={template}
              language={locale}
              size="sm"
            />
            <SummaryItem
              card="property"
              id="property/residential_floor_area"
              data={input}
              template={template}
              language={locale}
              size="sm"
            />
            <PropertyTotalFloorArea data={input} template={template} language={locale} />
          </Row>
          <Row gutter={smallGutter}>
            <SummaryItem
              card="property"
              id="property/vacancy_disbursement"
              data={input}
              template={template}
              language={locale}
              size="sm"
            />
            <SummaryItem
              card="property"
              id="property/vacancy_completion"
              data={input}
              template={template}
              language={locale}
              size="sm"
            />
            <SummaryItem
              card="property"
              id="property/yield"
              data={input}
              template={template}
              language={locale}
              size="sm"
            />
            <SummaryItem
              card="property"
              id="property/valuation"
              data={input}
              template={template}
              language={locale}
              size="sm"
            />
            {isDevelopmentProject && (
              <SummaryItem
                card="property"
                id="property/profit"
                data={input}
                template={template}
                language={locale}
                size="sm"
              />
            )}
            {isDevelopmentProject && (
              <SummaryItem
                card="property"
                id="property/total_project_costs"
                data={input}
                template={template}
                language={locale}
                size="sm"
              />
            )}
          </Row>
        </>
      )}
      {isCorporateFinancing && (
        <>
          <Row gutter={smallGutter} className="mt-3">
            <Col span={24}>
              <Header type="large" text={<IntlMessages id="general.corporateInfo" />} />
            </Col>
          </Row>
          <Row gutter={smallGutter}>
            <SummaryItem
              card="corporate"
              id="corporate/total_assets"
              data={input}
              template={template}
              language={locale}
              size="sm"
            />
            <SummaryItem
              card="corporate"
              id="corporate/total_undrawn_investor_commitments"
              data={input}
              template={template}
              language={locale}
              size="sm"
            />
            <SummaryItem
              card="corporate"
              id="corporate/total_equity"
              data={input}
              template={template}
              language={locale}
              size="sm"
            />
          </Row>
        </>
      )}
      <Row gutter={smallGutter} className="mt-3">
        <Col span={24}>
          <Header type="large" text={<IntlMessages id="general.debtInfo" />} />
        </Col>
      </Row>
      <Row gutter={smallGutter}>
        {isDevelopmentProject && (
          <SummaryItem
            card="debt"
            id="debt/land_facility"
            data={input}
            template={template}
            language={locale}
            size="sm"
          />
        )}
        {isDevelopmentProject && (
          <SummaryItem
            card="debt"
            id="debt/construction_facility"
            data={input}
            template={template}
            language={locale}
            size="sm"
          />
        )}
        <SummaryItem
          card="debt"
          id="debt/term_loan_facility"
          data={input}
          template={template}
          language={locale}
          size="sm"
        />
        <SummaryItem
          card="debt"
          id="debt/junior_facility"
          data={input}
          template={template}
          language={locale}
          size="sm"
        />
        <SummaryItem
          card="debt"
          id="debt/overdraft_facility"
          data={input}
          template={template}
          language={locale}
          size="sm"
        />
        <SummaryItem
          card="debt"
          id="debt/tenors"
          data={input}
          template={template}
          language={locale}
          size="lg"
        />
        <SummaryItem
          card="debt"
          id="debt/amortisation"
          data={input}
          template={template}
          language={locale}
          size="sm"
        />
        {/* <SummaryItem
          card="debt"
          id="debt/interest"
          data={input}
          template={template}
          language={locale}
          size="sm"
        /> */}
        <DebtInterest data={input} template={template} language={locale} />
        <SummaryItem
          card="debt"
          id="debt/security_guarantees"
          data={input}
          template={template}
          language={locale}
          size="sm"
        />
        <SummaryItem
          card="debt"
          id="debt/loan_to_cost_covenant"
          subId="debt/loan_to_cost_ratio"
          data={input}
          template={template}
          language={locale}
          size="sm"
        />
        <SummaryItem
          card="debt"
          id="debt/loan_to_value_covenant"
          subId="debt/loan_to_value_ratio"
          data={input}
          template={template}
          language={locale}
          size="sm"
        />
        <SummaryItem
          card="debt"
          id="debt/equity_to_assets_covenant"
          subId="debt/equity_to_assets_ratio"
          data={input}
          template={template}
          language={locale}
          size="sm"
        />
        <SummaryItem
          card="debt"
          id="debt/interest_cover_covenant"
          subId="debt/interest_cover_ratio"
          data={input}
          template={template}
          language={locale}
          size="sm"
        />
        <SummaryItem
          card="debt"
          id="debt/equity_capital"
          data={input}
          template={template}
          language={locale}
          size="sm"
        />
        <SummaryItem
          card="debt"
          id="debt/shareholder_loans"
          data={input}
          template={template}
          language={locale}
          size="sm"
        />
        <SummaryItem
          card="debt"
          id="debt/negative_pledge"
          data={input}
          template={template}
          language={locale}
          size="sm"
        />
      </Row>
      {isLender && (
        <>
          {hasRequestedAccess ? (
            <div>You have submitted interest in this</div>
          ) : (
            <Button block type="primary" className="mt-4" onClick={submitInterest}>
              <IntlMessages id="general.submitOfferInterest" />
            </Button>
          )}
        </>
      )}
    </>
  );
};

function getExtraProjectUrl(doc, project) {
  if (project) return '';
  if (!doc?.projectId) return '';
  return `/projects/${doc.projectId}?fields=name`;
}
function getExtraClientUrl(resource) {
  if (!resource?.clientId) return '';
  return `/clients/${resource.clientId}?fields=name`;
}
function getExtraUserUrl(resource) {
  if (!resource?.userId) return '';
  return `/users/${resource.userId}?fields=firstName,lastName`;
}

function userName(user) {
  if (!user || !user.firstName) return null;
  return `${user.firstName} ${user.lastName ? user.lastName : ''}`;
}

export function ReferringClientExtraInfo({ doc, project }) {
  const [fetchedProject] = useMountFetch(getExtraProjectUrl(doc, project));
  const effectiveProject = fetchedProject || project;
  const [client] = useMountFetch(getExtraClientUrl(doc || effectiveProject));
  const [user] = useMountFetch(getExtraUserUrl(doc || effectiveProject));

  return (
    <div className="m-2 p-2 border">
      <div>
        <small>Org:</small> {client?.name || '. . .'}
      </div>
      <div>
        <small>Project:</small> {effectiveProject?.name || '. . .'}
      </div>
      <div>
        <small>User:</small> {userName(user) || '. . .'}
      </div>
    </div>
  );
}

export default ProposalSummary;
