import { useEffect, useRef } from 'react';
import { Form, Input } from 'antd';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { Label, AutoCompleteEntity } from '.';

export const EntityEditFieldFirstname = ({
  entityType,
  autoSelectName,
  disabled,
  fieldName = 'firstName',
  required = true,
  onSearchSelect = () => {},
  searchData = {},
  ignoreLabel,
  ignoreAutocomplete,
  placeholder,
  ...rest
}) => {
  const formatMessage = useIntlMessage();
  const firstNameRef = useRef();

  useEffect(() => {
    if (autoSelectName && firstNameRef.current) {
      setTimeout(() => {
        firstNameRef.current.focus();
        firstNameRef.current.select();
      }, 10);
    }
  }, [autoSelectName, firstNameRef]);

  const labelText =
    entityType === 'RealPerson' ? formatMessage('app.general.FirstName') : formatMessage('app.general.Name');

  const autocompleteWrapper = (item) => {
    if (ignoreAutocomplete) return item;
    return (
      <AutoCompleteEntity
        field="name"
        inactive={entityType !== 'LegalPerson'}
        onSearchSelect={onSearchSelect}
        disabled={disabled}
      >
        {item}
      </AutoCompleteEntity>
    );
  };

  return (
    <Form.Item
      {...rest}
      label={ignoreLabel ? null : <Label>{labelText}</Label>}
      name={fieldName}
      rules={[
        {
          required,
          whitespace: true,
          message: formatMessage('app.persons.validation.firstName'),
        },
      ]}
      placeholder={placeholder ? labelText : null}
    >
      {autocompleteWrapper(<Input ref={firstNameRef} placeholder={placeholder ? labelText : null} />)}
      {/* <AutoCompleteEntity
        field="name"
        inactive={entityType !== 'LegalPerson'}
        onSearchSelect={onSearchSelect}
        disabled={disabled}
      >
        <Input ref={firstNameRef} />
      </AutoCompleteEntity> */}
    </Form.Item>
  );
};
