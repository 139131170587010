/******* HELPER FUNCTIONS ******/
import { useSelector } from 'react-redux';
import { Entity } from 'core/interfaces';
import { splitNumber, combineNumber, getByPath } from 'core/utils/general';
import { useInputData } from '../../../utils/hooks';

export function usePrefixSuffix(field) {
  const prefixInstruction = field.prefix;
  const suffixInstruction = field.suffix;
  const dataPrefix = useInputData((typeof prefixInstruction === 'object' && prefixInstruction.path) || null);
  const dataSuffix = useInputData((typeof suffixInstruction === 'object' && suffixInstruction.path) || null);

  const prefixValue = typeof prefixInstruction === 'string' ? prefixInstruction : dataPrefix || '';
  const suffixValue = typeof suffixInstruction === 'string' ? suffixInstruction : dataSuffix || '';

  return [prefixValue, suffixValue];
}

export function useSiblingValues(card, cardUid) {
  const path = `input.${card.id}${cardUid ? `.${cardUid}` : ''}`;
  const siblingValues = useSelector((state) => getByPath(state, path));
  return siblingValues;
}

export function useSiblingEntity(card, cardUid, fieldName) {
  const siblingValues = useSiblingValues(card, cardUid);
  const entities = useSelector(({ entities }) => entities);
  if (!entities) return null;
  if (!siblingValues) return null;
  const value = siblingValues[fieldName];
  if (!value || !value.id) return null;
  const entity = Entity.getById(entities, value.id);
  if (!entity) return null;
  return entity;
}

export function getValue(props = {}, defaultValue) {
  if (props.field && props.value && props.language && props.field.format === 'amount')
    return splitNumber(combineNumber(props.value, props.language), props.language);
  else if (
    props.value &&
    props.field &&
    (props.field.type === 'numeric' || props.field.type === 'numeric-steps') &&
    typeof props.value.toString === 'function'
  ) {
    return props.value.toString();
  } else if (
    (props.field.type === 'numeric' || props.field.type === 'numeric-steps' || props.field.type === 'text') &&
    props.value === 0 &&
    typeof props.value.toString === 'function'
  ) {
    return props.value.toString();
  }

  return props.value || defaultValue;
}
