import React, { memo } from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import CustomScrollbars from 'util/CustomScrollbars';
import SidebarLogo from './SidebarLogo';
import UserProfile from './UserProfile';
import IntlMessages from '../../util/IntlMessages';
import { useSelector } from 'react-redux';
import { useIsSidebarMini } from 'hooks';

import MainActions from 'containers/App/MainActions';

const SIDEBAR_CONTENT_ORDER = ['main', 'offers', 'tasks', 'notifications']; // List of nameIds

const entryData = {
  main: {
    key: 'main',
    path: '/main',
    nameId: 'main',
    icon: 'mdi mdi-home-outline',
    activePathCatch: ['/main'],
  },
  offers: {
    path: '/offers',
    nameId: 'offers',
    icon: 'mdi mdi-cube-outline',
    activePathCatch: ['/offer'],
    numberKey: 'offers',
  },
  tasks: {
    path: '/tasks',
    nameId: 'tasks',
    activePathCatch: ['/tasks'],
    icon: 'mdi mdi-checkbox-marked-circle-outline',
    /* numberKey: 'pendingTasks',
    numberHighlight: true, */
  },
  notifications: {
    path: '/notifications',
    nameId: 'notifications',
    icon: 'mdi mdi-bell',
    numberKey: 'newNotifications',
    numberHighlight: true,
    activePathCatch: ['/notifications'],
  },
};

const SidebarContent = () => {
  const numbers = useSelector(({ common }) => common.numbers);
  const user = useSelector(({ auth }) => auth.user);
  const { pathname } = useLocation();

  const currentMainPath = '/' + pathname.split('/')[1];
  const selectedKey = SIDEBAR_CONTENT_ORDER.find((id) => {
    const entry = entryData[id];
    if (entry.path && entry.path === currentMainPath) return true;
    if (entry.activePathCatch && entry.activePathCatch.includes(currentMainPath)) return true;
    return false;
  });

  return (
    <>
      <SidebarLogo />
      <div className="sidebar-content dark-container">
        <UserProfile />
        <CustomScrollbars className="layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={['main']}
            selectedKeys={[selectedKey ? selectedKey.nameId : '']}
            theme={'lite'}
            mode="inline"
          >
            {SIDEBAR_CONTENT_ORDER.map((id) => {
              const entry = entryData[id];
              if (!entry) {
                console.warn('Cannot find menu entry for id: ', id);
                return null;
              }
              if (entry.requireAdmin && !user.isAdmin) return null;
              return (
                <Menu.Item key={entry.nameId} className={entry.space ? 'mt-3 pt-3' : ''}>
                  <Link to={entry.path} className="d-flex align-items-center">
                    {entry.icon ? (
                      <i className={'icon ' + entry.icon} />
                    ) : (
                      <div className="sidebar-icon-number">
                        <span
                          className={
                            'sidebar-number ' +
                            (numbers[entry.numberKey] && entry.numberHighlight ? 'highlighted' : '')
                          }
                        >
                          {numbers[entry.numberKey]}
                        </span>
                      </div>
                    )}
                    <span>
                      <IntlMessages id={'sidebar.' + entry.nameId} />
                    </span>
                  </Link>
                </Menu.Item>
              );
            })}
          </Menu>
        </CustomScrollbars>
        <MainActions />
      </div>
    </>
  );
};

export default memo(SidebarContent);
