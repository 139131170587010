import React, { useMemo, memo } from 'react';
import URLSearchParams from 'url-search-params';
import { Layout } from 'antd';
import { useContract, useIsTemplateStudio } from 'hooks';
import { useSelector } from 'react-redux';
import StudioTopbar from '../Topbar/';
import TemplateInputSectionsMenu from '../TemplateInputSections/';
import applyLogic from 'core/utils/logic/applyLogic';
import { Contract } from 'core/interfaces';
import StudioContainer from '../StudioContainer/';

const { Content } = Layout;

function useActivePagePaths(subject, flatUiPages) {
  const contract = useContract();
  const state = useSelector((state) => state);

  const activePaths2 = useMemo(() => {
    return !contract
      ? null
      : getActivePathsForPage(state, subject, flatUiPages, {
          create: { setup: Contract.getSetup(contract) },
          contract: contract,
        });
  }, [contract, flatUiPages, state, subject]);

  // Do this exercise in order to only have child components update
  // if there has been an actual change to the activePaths. Otherwise,
  // it will always become a new object every time the state updates.
  let activePathsString = JSON.stringify(activePaths2);
  const usedActivePaths = useMemo(() => {
    return JSON.parse(activePathsString);
  }, [activePathsString]);
  return usedActivePaths;
}

const Wrapper = memo(function ({ subject, search }) {
  const searchData = new URLSearchParams(search);
  const onlyInput = searchData.get('onlyInput') === 'true';
  const hideOneSection = searchData.get('hideOneSection') === 'true';
  const hideFinishActions = searchData.get('hideFinishActions') === 'true';
  const contract = useContract();
  const isTemplate = useIsTemplateStudio();
  const studio_draftMode = useSelector(({ draft }) => draft.studio_draftMode);
  const documentPageSize = Contract.getPageSize(contract);
  const documentPageOrientation = Contract.getPageOrientation(contract);
  const documentColumnSettings = Contract.getDocumentColumnSettings(contract);
  const flatUiPages = useMemo(() => getFlatPages(Contract.getUi(contract)), [contract]);
  const usedActivePagePaths = useActivePagePaths(subject, flatUiPages);
  const activeFlatUiPages = useMemo(
    () => getActivePages(flatUiPages, usedActivePagePaths),
    [flatUiPages, usedActivePagePaths]
  );

  return (
    <Layout
      id="studio-container"
      className={`app-layout studio studio-subject-${subject} create-design-v2 doc-draft-mode-${studio_draftMode}
      only-input-${onlyInput}
      `}
    >
      {!onlyInput && <StudioTopbar activePagePaths={usedActivePagePaths} />}
      <Layout>
        {/* <StudioSidebar activePagePaths={usedActivePagePaths} /> */}
        <Content className={`layout-content studio-inner-content`}>
          {isTemplate && <TemplateInputSectionsMenu />}
          <StudioContainer
            onlyInput={onlyInput}
            hideOneSection={hideOneSection}
            hideFinishActions={hideFinishActions}
            flatUiPages={flatUiPages}
            activeFlatUiPages={activeFlatUiPages}
            activePagePaths={usedActivePagePaths}
            studio_draftMode={studio_draftMode}
            documentPageSize={documentPageSize}
            documentPageOrientation={documentPageOrientation}
            documentColumnSettings={documentColumnSettings}
          />
        </Content>
      </Layout>
      {/* <StudioFooter /> */}
    </Layout>
  );
});

function getFlatPages(page, parents = []) {
  const result = [];

  const { pages } = page;
  let { pages_order } = page;
  if (!pages) pages_order = Object.keys(pages);

  for (const pageId of pages_order) {
    const page = pages[pageId];
    if (!page) continue;
    result.push(getFlatPage(page, pageId, parents));
  }
  return result.flat();
}
function getFlatPage(page, id, parents) {
  const result = [page];
  if (page.pages) {
    result.push(getFlatPages(page, [...parents, id]).flat());
  }
  return result.flat();
}

function getActivePathsForPage(state, studioSubject, flatUiPages, options = {}) {
  return flatUiPages.reduce((acc, page) => {
    acc[page.paths.toString()] = studioSubject === 'template' ? true : checkRouteActive(page, state, options);
    return acc;
  }, {});
}
function getActivePages(flatUiPages, activePagePaths) {
  return flatUiPages.filter((page) => activePagePaths[page.paths.toString()]);
}

const checkRouteActive = (route, state, options) => {
  if (!route) return false;
  if (!route.acp) return true;
  return applyLogic(route.acp, state, { _meta: options });
};

export default Wrapper;
