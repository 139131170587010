import React, { useState, useEffect, memo } from 'react';
import { Spin, Alert } from 'antd';
import api from 'utils/api';
import IntlMessages from 'util/IntlMessages';
import { useIsReferringClient, useIsLender, useIsBorrower } from 'hooks';
import config from 'config/config';
import AdminView from './AdminView/AdminView';
import BorrowerView from './BorrowerView/BorrowerView';
import LenderView from './LenderView/LenderView';

const { applicationResourceIds } = config;
const { permissionAccessorId } = applicationResourceIds;

function NoSystemAccess() {
  return (
    <div>
      <Alert
        type="error"
        message={<IntlMessages id="general.notApprovedMsg" />}
        description={<IntlMessages id="general.NotApprovedDesc" />}
      />
    </div>
  );
}

const Main = memo(() => {
  const isBorrower = useIsBorrower();
  const isLender = useIsLender();
  const isReferringClient = useIsReferringClient();
  const [hasSystemAccess, setHasSystemAccess] = useState(null);

  useEffect(() => {
    (async () => {
      const result = await api.get(
        `/permissions/hasAccess?resourceType=PermissionAccessor&resourceId=${permissionAccessorId}&action=read`
      );
      setHasSystemAccess(result.data);
    })();
  }, []);

  let content;
  if (isReferringClient)
    content = (
      <div>
        <AdminView />
        <LenderView isReferringClient />
      </div>
    );
  else if (hasSystemAccess === null) content = <Spin className="loader-container" />;
  else if (!hasSystemAccess) content = <NoSystemAccess />;
  else if (isLender) content = <LenderView isLender />;
  else if (isBorrower) content = <BorrowerView />;
  else content = <div>No access</div>;

  return <div className="padded-page">{content}</div>;
});

export default Main;
